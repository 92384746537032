body {
  font-family: "Proxima Nova Rg", sans-serif;
  font-size: 14px;
  color: #262626;
  height: 100%;
  font-weight: 500;
}

.main-wrapper {
  position: relative;
  min-height: calc(100vh - 122px);
  padding-bottom: 130px;
}

a {
  color: initial;
}

h3 {
  font-size: 32px;
  font-weight: 900;
  text-transform: uppercase;
}

h3.main-title {
  text-align: center;
  color: #575b60;
}

section {
  padding-top: 75px;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: underline;
  color: #623079;
}

a.btn {
  text-decoration: none;
  min-width: 120px;
}

label {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #575b60;
  line-height: 1.2;
  opacity: 0.8;
  text-transform: capitalize;
}

.btn-primary:focus,
.btn-primary:hover {
  background-color: #ff8686 !important;
  border-color: #ff8686 !important;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}

.btn-disabled:focus,
.btn-disabled:hover {
  background-color: #ff5f5f !important;
  border-color: #ff5f5f !important;
}

.radius-20 {
  border-radius: 20px;
}

.btn-primary,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #ff5f5f;
  border-color: #ff5f5f;
}

.btn-outline {
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #ff5f5f;
  border-color: #ff5f5f;
}

.btn-add {
  background-color: #359ed8;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 20px;
}

.btn-add:hover {
  color: #fff;
  background-color: #2d99d3;
}

.search-wrapper h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}

.search-wrapper .form-control {
  transition: none;
  height: 60px;
  padding-left: 64px;
  font-family: "Proxima Nova Rg", sans-serif !important;
  border: none;
}

.autocomplete-dropdown-container {
  text-align: left;
  font-size: 16px;
  line-height: 32px;
  padding-left: 10px;
  box-shadow: 0 7px 12px 2px rgba(0, 0, 0, 0.1);
}

.taste-header .logo-red {
  display: none;
}

.taste-header .dropdown-toggle::after {
  content: none;
}

.taste-header.scrolled .logo-red {
  display: block;
}

.taste-header.scrolled-white .logo-red {
  display: block;
}

.taste-header .logo-white {
  display: block;
}

.taste-header.scrolled .logo-white {
  display: none;
}

.taste-header.scrolled-white .logo-white {
  display: none;
}

.navbar-expand-lg .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand-lg .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.scrolled .bg-transparent {
  background-color: #fff !important;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
}

.scrolled-white .bg-transparent {
  background-color: #fff !important;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
}

.taste-header li {
  align-items: center;
  display: flex;
}

.taste-header li a {
  text-decoration: none;
  font-size: 16px;
  color: #000000;
  font-weight: 600;
}

.taste-header .navbar-nav .dropdown-menu {
  position: absolute;
  left: -138px;
  top: 40px;
}

.taste-header.scrolled .navbar-light .navbar-nav .nav-link {
  color: #000000;
}

.taste-header.scrolled-white .navbar-light .navbar-nav .nav-link {
  color: #000000;
}

.taste-header.scrolled .btn-outline {
  border: 1px solid #000;
}

.taste-header.scrolled-white .btn-outline {
  border: 1px solid #000;
}

.taste-header.scrolled .navbar-nav .nav-link.btn-primary {
  color: #fff;
}

.taste-header.scrolled-white .navbar-nav .nav-link.btn-primary {
  color: #fff;
}

.recommended-box {
  display: flex;
  justify-content: space-between;
}

.recommended-box .box-right {
  position: relative;
  margin-left: 25px;
}

.recommended-box span.location,
.sobber-living span.location {
  align-items: center;
  color: #7b7f84;
  font-size: 14px;
  font-weight: 300;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  max-width: 168px;
}

.recommended-box .box-right .value {
  display: block;
  font-weight: 700;
}

.recommended-box .box-right .lbl {
  font-size: 15px;
  font-weight: 300;
  color: #7b7f84;
}

.recommended-box .box-right::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: #eaeaea;
  left: -20px;
  top: 0;
}

/* Mobile menu */
.mobile-menu {
  display: none;
}

.hero {
  background: url("images/vu-anh-TiVPTYCG_3E-unsplash.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 435px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 35, 48, 0.6);
  /* z-index: 9; */
}

.search-wrapper {
  position: relative;
  width: 622px;
  margin: 0 auto;
}

.search-icon {
  position: absolute;
  left: 16px;
  top: 18px;
}

.taste-header .navbar-light .navbar-nav .nav-link {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  padding: 5px 20px;
}

.card {
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.1);
  margin: 15px;
  border-radius: 4px;
  outline: 0 none;
  border: 0;
}

.slick-slide {
  outline: 0 none;
}

.slick-next:before {
  background: url(images/arrow-left.svg);
  position: absolute;
  top: 0;
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  content: "";
  left: -10px;
}

.slick-prev:before {
  background: url(images/arrow-left.svg);
  position: absolute;
  top: 0;
  width: 22px;
  height: 22px;
  content: "";
  background-repeat: no-repeat;
}

.slick-prev:before,
.slick-next:before {
  color: #000;
}

.card-img {
  border-radius: 8px 8px 0 0;
  height: 224px;
  position: relative;
}

.card-img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.card-heading {
  font-size: 14px;
  font-weight: 900;
  justify-content: space-between;
  color: #575b60;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 21px;
}

span.editable {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  z-index: 1;
}

span.rating {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
}

span.rating-recommended {
  color: #7b7f84;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
}

span.rating-recommended img {
  margin-right: 6px;
}

.card-img .rating img {
  width: 25px;
  height: 25px;
  display: initial;
}

span.location {
  align-items: center;
  color: #7b7f84;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 21px;
}

.property-wrapper {
  width: calc(100% - 75px);
  border-right: 1px solid #eaeaea;
  padding-right: 10px;
}

span.location img {
  margin-right: 6px;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 2px;
}

footer .copyright {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d7d7db;
  padding: 50px;
}

.list-group-item-warning ul li {
  color: red;
  font-weight: 700;
}

.form-control:disabled {
  background-color: transparent;
  opacity: 0.3;
}

button.select-tours {
  color: #000;
  background: transparent;
  border: 2px solid #000;
  padding: 7px 21px;
  border-radius: 26px;
}

.authentication .modal-header {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 0;
  padding: 0;
}

.authentication .modal-header p {
  color: #999da2;
}

.authentication .modal-dialog {
  max-width: 375px;
}

.authentication .modal-content {
  border-radius: 37px;
  padding: 26px;
}

.authentication .modal-title {
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  color: #575b60;
}

.authentication .form-control {
  background: #f5f5f5;
  border-radius: 28px;
  height: 54px;
  border: 0;
  padding: 15px 25px;
}
.authentication .form-control .select__control {
  box-shadow: none !important;
  border: 0 !important;
  background: transparent;
  min-height: unset;
  outline: 0 !important;
}
.authentication .form-control .select__control .select__value-container {
  padding: 0 8px 0 0;
}
.authentication
  .form-control
  .select__control
  .select__value-container
  .select__input-container {
  margin: 0;
  padding: 0;
}
.authentication .form-control .select__control .select__indicator {
  padding: 0 8px;
}
.authentication .form-control .select__control .select__indicator-separator {
  width: 0;
}
.select__menu {
  left: 0;
  right: 0;
  margin: auto;
}
.forgot-pass {
  display: flex;
  justify-content: flex-end;
}

.forgot-pass a {
  color: #b1b1b1;
  text-decoration: none;
  font-weight: 300;
}

.action-btn {
  margin: 55px 0 30px 0;
}

.action-btn .btn {
  border-radius: 28px;
  display: block;
  font-size: 18px;
  padding: 15px;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
}

.extra-link {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #999da2;
  font-weight: 300;
}

.extra-link a {
  color: #ff5f5f;
  font-weight: 500;
}

.modal-backdrop {
  background-color: rgba(35, 35, 48, 0.75);
  z-index: 999;
}

.spinner-taste.modal {
  z-index: 9999999 !important;
  width: 100% !important;
  background: rgb(35 35 48 / 75%);
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #575b60;
  background-color: #575b60;
}

.terms {
  margin: 75px 0;
}

.signup-choose-wrapper label.form-control {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  text-transform: none !important;
}

.property-wrp {
  display: flex;
  justify-content: space-between;
}

.property-top-left h3 {
  display: flex;
  align-items: center;
}

.property-top-left h3 span img {
  width: 30px;
  margin-left: 10px;
  margin-top: -10px;
}

.loc-n-status {
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
}

.rate {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.property-top-right .btn {
  padding: 15px 50px;
  border-radius: 29px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.property-top-right .btn img {
  margin-right: 0px;
}

.property-top-right {
  display: flex;
  align-items: center;
}

.property-top {
  border-bottom: 1px solid #d7d7db;
  padding: 30px 0;
}

ul.feature-list li {
  width: 48%;
  display: inline-flex;
}

ul.feature-list li:nth-child(odd) {
  margin-right: 25px;
}

.curfew-wrp {
  display: flex;
  align-items: baseline;
}

h4.sub-title {
  color: #232330;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.about p {
  font-size: 14px;
  color: #575b60;
  font-weight: 300;
}

.available-opt-icons {
  display: flex;
  align-items: center;
  flex-flow: wrap;
}

.available-opt-icons img {
  padding-right: 10px;
  width: 80px;
  height: 80px;
  padding-bottom: 10px;
}

ul.feature-list li {
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  padding-bottom: 17px;
}

ul.feature-list li img {
  margin-right: 22px;
}

span.feature-name {
  color: #575b60;
  margin-right: 8px;
  font-size: 16px;
}

span.feature-value {
  font-size: 16px;
  color: #232330;
}

.location-wrp {
  border-bottom: 1px solid #d7d7db;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.curfew-wrp ul {
  width: 100%;
}

.curfew-wrp .curfew-ico {
  margin-right: 20px;
}

.curfew-wrp ul li {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  padding-top: 10px;
}

.inner-hero {
  position: relative;
  min-height: 470px;
}

.inner-hero .slick-next:before {
  background: url(images/arrow-left-white.svg);
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  content: "";
  border-radius: 50%;
  background-position: 15px 13px;
}

.inner-hero .slick-prev:before {
  background: url(images/arrow-left-white.svg);
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  content: "";
  border-radius: 50%;
  background-position: 15px 13px;
}

.inner-hero .slick-prev:before,
.inner-hero .slick-next:before {
  color: #fff;
  background-color: red;
}

.inner-hero .slick-prev {
  z-index: 999;
  left: 70px;
}

.inner-hero .slick-next {
  z-index: 999;
  right: 70px;
}

.property-img {
  height: 470px;
}

.inner-hero .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slick-slide img,
.map-view img {
  width: 100%;
}

ul.feature-list {
  border-bottom: 1px solid #d7d7db;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

ul.feature-list img {
  height: 22px;
  width: 22px;
}

.review-wrapper {
  display: block;
  border-bottom: 1px solid #d7d7db;
  padding-bottom: 30px;
  margin-bottom: 30px;
  vertical-align: top;
}

.review-wrapper .user-img {
  margin-right: 20px;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
}

.review-content {
  width: calc(100% - 65px);
  display: inline-block;
}

.review-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review-heading h4 {
  font-size: 16px;
  color: #575b60;
  margin-bottom: 2px;
}

span.date {
  color: #a6a6a6;
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
}

.react-rater-star {
  font-size: 20px !important;
}

.property-btns .btn {
  align-items: center;
  display: inline-flex;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
}

.property-btns {
  display: flex;
  row-gap: 8px;
  justify-content: end;
}

.review-content p {
  color: #999da2;
  font-size: 12px;
  margin-bottom: 20px;
}

a.reply {
  display: flex;
  align-items: flex-end;
  color: #232330;
  text-decoration: none;
  font-size: 12px;
}

a.reply img {
  margin-right: 5px;
}

.rate-here {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.review-comment {
  max-width: 400px;
}

.review-comment textarea {
  max-width: 471px;
  border-radius: 12px;
  margin-top: 8px;
  border: 1px solid #d7d7db;
  height: 90px;
  resize: none;
}

.listing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.listing-header h3 {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.amount-wrapper span span {
  font-weight: 500;
}

.amount-wrapper {
  font-weight: 700;
  font-size: 15px;
  display: block;
  width: 75px;
  min-height: 70px;
  padding-left: 10px;
  color: #575b60;
}

.filter-options ul li a img {
  margin-right: 8px;
}

.filter-options ul {
  display: flex;
  box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.filter-options ul li {
  padding: 8px 0;
}

.filter-options ul li a {
  padding: 10px 30px;
  font-size: 16px;
  color: #454550;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  min-width: 120px;
  border-right: 1px solid #eaeaea;
  text-align: center;
  justify-content: center;
}

.filter-options ul li:last-child a {
  border: 0;
}

.filter p {
  color: #999da2;
  font-size: 12px;
}

section.back {
  padding-top: 90px;
}

.filter {
  position: fixed;
  right: 0;
  top: 56px;
  background: #fff;
  width: 375px;
  z-index: 999;
  box-shadow: 0px 2px 10px 4px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 57px);
  padding: 20px;
  overflow: auto;
  transform: translateX(400px);
  overflow-y: auto;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.filter.active {
  right: 400px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.close-filter {
  cursor: pointer;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 25px 0;
}

.filter-top {
  display: flex;
}

.filter-top-left {
  padding-right: 50px;
}

.get-alert {
  display: flex;
}

.filter-header .title {
  font-size: 16px;
  font-weight: bold;
}

.filter-header .apply {
  color: #ff5f5f;
  font-size: 18px;
  font-weight: 600;
}

.filter-top-right {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  color: #999da2;
}

a.btn.btn-default {
  border-radius: 3px;
  background: #fafbfc;
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  justify-content: center;
  color: #ff6c6c;
  height: 44px;
}

.group-primary {
  color: #636c7e;
  background-color: #fafbfc;
  border-color: #dedede;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
}

.group-primary.active {
  background-color: #ff6c6c;
  color: #fff;
}

.group-primary .btn {
  padding: 10px;
}

.filter label {
  color: #a3a3a3;
  font-size: 14px;
  margin-bottom: 10px;
}

/*
  /* Let's get this party started 
  ::-webkit-scrollbar {
  width: 2px;
  }
  /* Track 
  .filter::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  }
  /* Handle 
  .filter::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 0, 0, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  .filter::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
  }
  */
.taste-recovery-form .form-control {
  border-bottom: 1px solid #ededed;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  padding: 5px 0 5px 5px;
  border-radius: 0;
  outline: 0 none;
  box-shadow: none;
  font-family: "Proxima Nova Rg", sans-serif;
  margin-bottom: 0;
  line-height: 30px;
}

.edit-profile-view.taste-recovery-form .form-control {
  padding-left: 0;
  margin-top: 0 !important;
  font-size: 16px;
  margin-bottom: 30px;
  margin-left: 0;
  margin-right: 0;
}

.edit-profile-view.taste-recovery-form label {
  font-weight: 700;
}

/* .taste-recovery-form select.form-control {
  appearance: none;
  } */
.add-property-from {
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.taste-recovery-form .form-group {
  margin-bottom: 25px;
}

.taste-recovery-form .form-control:focus {
  border-bottom: 1px solid #ededed;
  outline: 0 none;
  box-shadow: none;
}

.taste-recovery-form label {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #575b60;
  padding-right: 20px;
  line-height: 1.2;
  opacity: 0.8;
}

span.optional-span {
  display: block;
  font-size: 16px;
}

.added-photo-listing {
  display: inline-block;
  width: 100%;
}

.photo-wrp {
  width: 95px;
  height: 95px;
  border-radius: 5px;
  margin-right: 20px;
  position: relative;
  display: inline-block;
  margin-bottom: 40px;
  vertical-align: bottom;
}

.added-photo-listing .photo-wrp:nth-child(3)::after {
  content: "Main";
  font-size: 15px;
  color: #000;
  padding: 4px 10px;
  border-radius: 4px;
  margin-top: 5px;
  opacity: 0.5;
  display: block;
  text-align: center;
}

span.delete-pic {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

span.delete-pic img {
  width: 10px;
  cursor: pointer;
}

.photo-wrp > img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 5px;
}

.photo-wrp.placeholder {
  background: #f7f7f7;
  justify-content: center;
  align-items: center;
}

span.add-pic {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.available-options {
  display: flex;
}

.available-options img {
  margin-right: 20px;
}

.dflex-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  padding-bottom: 36px;
}

.img-cover {
  object-fit: cover;
}

.btn-min-width {
  min-width: 300px !important;
}

span.step-badge {
  color: #b9b9b9;
  font-size: 20px;
  margin-left: 30px;
}

span.extra-note {
  position: absolute;
  bottom: -25px;
  font-size: 12px;
}

span.mendatory {
  color: red;
  font-size: 12px;
}

/*Setting Sidebar */
.sidebar-settings .main-title,
.settings-content .main-title {
  text-align: left;
}

.sidebar-wrapper ul li a {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: #575b60;
  align-items: center;
  border-bottom: 1px solid #ededed;
}

.sidebar-wrapper ul li a.active {
  color: #ff5f5f;
}

.sidebar-wrapper ul li a span {
  display: flex;
  align-items: center;
}

.sidebar-wrapper ul li a span img {
  margin-right: 20px;
  width: 12px;
}

.alert-wrapper {
  position: relative;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

span.alert-title {
  color: #232330;
  font-size: 12px;
  margin-bottom: 15px;
}

.alert-wrapper p {
  color: #999da2;
  font-size: 12px;
  margin: 0;
}

span.delete-alert {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.review-header {
  display: flex;
}
@media screen and (max-width: 767px) {
  .review-header {
    flex-wrap: wrap;
    row-gap: 12px;
  }
}

.name-avtar {
  background: rgb(249, 227, 234);
  background: -moz-linear-gradient(
    189deg,
    rgba(249, 227, 234, 1) 0%,
    rgba(225, 237, 244, 1) 50%,
    rgba(207, 243, 251, 1) 100%
  );
  background: -webkit-linear-gradient(
    189deg,
    rgba(249, 227, 234, 1) 0%,
    rgba(225, 237, 244, 1) 50%,
    rgba(207, 243, 251, 1) 100%
  );
  background: linear-gradient(
    189deg,
    rgba(249, 227, 234, 1) 0%,
    rgba(225, 237, 244, 1) 50%,
    rgba(207, 243, 251, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f9e3ea", endColorstr="#cff3fb", GradientType=1);
  width: 43px;
  height: 43px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.name-avtar img {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background-color: #f1f2f3;
}

.name-avtar span {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.property-review-box {
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
}

.property-head span {
  font-size: 16px;
  font-weight: bold;
  color: #575b60;
}

.property-head span.date {
  color: #a6a6a6;
  font-size: 12px;
  margin-bottom: 20px;
  display: block;
}

.transaction-detail {
  position: relative;
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.transaction-detail span {
  display: block;
  font-size: 14px;
}

.transaction-detail p {
  color: #a6a6a6;
}

.transaction-detail .status {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16px;
  font-weight: bold;
}

.failed {
  color: #ff5f5f;
}

.success {
  color: #1fc490;
}

.card-view {
  background: #232330;
  border-radius: 3px;
  padding: 37px;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
}

.card-top {
  margin-bottom: 50px;
  max-height: 18px;
}

.card-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.card-bottom span {
  display: block;
  color: #fff;
}

.card-bottom span.ttl {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}

.card-bottom span.val {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.card-number {
  color: #fff;
  display: flex;
  justify-content: space-between;
}

span.dots {
  font-size: 70px;
  line-height: 0px;
  word-spacing: -18px;
}

span.num {
  font-size: 21px;
}

.cms-wrp span.heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

.cms-wrp p {
  font-size: 16px;
}

.edit-profile .form-group label {
  font-weight: bold;
  font-size: 16px;
}

.edit-profile .form-group span {
  display: block;
  font-size: 15px;
}

.back-property {
  position: absolute;
  top: 16%;
  z-index: 999;
  left: 70px;
  background: rgba(0, 0, 0, 0.5);
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.back-property a {
  display: flex;
}

/* Chat CSS */
.chat-wrapper {
  display: flex;
  border-top: 1px solid #d7d7db;
  height: calc(100vh - 350px);
  border-bottom: 1px solid #d7d7db;
}

.chat-search .form-control {
  padding-left: 45px;
}

.chat-search .search-icon {
  position: absolute;
  left: 10px;
  top: 8px;
}

.conversation-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  padding: 0 32px;
}

.user-photo {
  width: 40px;
  height: 40px;
  border-radius: 3px;
  margin-right: 10px;
  min-width: 40px;
}

.user-photo img {
  width: 100%;
  object-fit: cover;
}

.user-info {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.user-info .name {
  font-size: 15px;
  font-weight: bold;
}

.timetotal {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-items: center;
}

span.total {
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
}

span.time {
  font-size: 14px;
  color: #b9bec4;
  margin-bottom: 3px;
}

span.short-conversation {
  font-size: 15px;
  color: #999da2;
}

.chat-leftbar {
  min-width: 340px;
  padding: 20px 20px 0 0;
  height: 100%;
  overflow-y: auto;
}

/* ul.conversation-list li {
  margin-top: 30px;
  } */
.convesation-wrapper li {
  margin: 20px 0;
}

.chat-rightbar {
  width: 100%;
  border-left: 1px solid #d7d7db;
  max-height: 60vh;
  overflow: hidden;
  position: relative;
}

.chat-rightbar {
  width: 100%;
  border-left: 1px solid #d7d7db;
  overflow: hidden !important;
  position: relative;
  max-height: 100% !important;
}

.conv-detail-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-bottom: 1px solid #d7d7db;
}

.conv-detail-header span.name {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.conversation-content {
  padding: 20px;
  overflow-y: auto;
  height: calc(100% - 130px);
}

.conversation-date {
  display: flex;
  justify-content: center;
  align-items: center;
}

.conversation-date span.date {
  color: #fff;
  font-size: 12px;
  background: #ff5f5f;
  border-radius: 10px;
  padding: 4px 14px;
  font-weight: 700;
  line-height: 13px;
  margin-bottom: 10px;
}

.single-conversation.right {
  max-width: 60%;
  margin-left: auto;
  background: #f1f2f3;
  margin-bottom: 30px;
  word-break: break-all;
}

.single-conversation {
  font-size: 14px;
  color: #575b60;
  border-radius: 18px;
  padding: 12px;
  position: relative;
}

.single-conversation.right span.time {
  position: absolute;
  bottom: -30px;
  right: 25px;
}

.single-conversation.left span.time {
  position: absolute;
  bottom: -15px;
  left: 86px;
}

.single-conversation.left {
  max-width: 45%;
  display: flex;
}

.left span.conv-box {
  border-radius: 18px;
  border: 1px solid #f2f2f2;
  padding: 10px;
  word-break: break-all;
}

.start-conversation {
  border-top: 1px solid #d7d7db;
  position: relative;
}

.start-conversation input {
  border: 0;
  width: 100%;
  outline: 0;
  box-shadow: none !important;
  height: 50px;
  padding: 7px 40px 7px 15px;
  font-size: 15px;
  margin-bottom: 20px;
  margin-top: 0;
  font-family: "Proxima Nova Rg", sans-serif;
}

a.send-mes-btn {
  position: absolute;
  right: 0;
  top: 12px;
}

/* calendar */
.nice-dates-navigation,
.nice-dates-week-header {
  background: #232330;
  color: #fff !important;
}

.nice-dates-day:before {
  background-color: #1fc490 !important;
}

.nice-dates-day:before,
.nice-dates-day:after {
  border-radius: 0 !important;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
}

span.time-slot {
  margin: 10px;
  padding: 10px;
  border: 1px solid #e1e2e3;
  border-radius: 2px;
}

.time-slot-wrap {
  display: flex;
  padding: 20px;
  flex-flow: wrap;
}

span.time-slot.active {
  background: #232330;
  color: #fff;
}

.no-tour-error {
  padding: 15px 20px;
  color: red;
  font-size: 15px;
}

.pl-100 {
  padding-left: 100px;
}

.spinner-taste .modal-content {
  background: #fff;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 2rem;
  font-size: 11px;
  line-height: 23px;
  max-width: 60%;
  margin: 0 auto;
}

.spinner-border {
  width: 4rem;
  height: 4rem;
}

.spinner-taste .loading-msg {
  font-size: 15px;
  color: #313131;
  margin-top: 2rem;
  line-height: 20px;
}

/* calendar */
ul.cause-complaint li {
  display: flex;
  justify-content: space-between;
  /* border-top: 1px solid #999999; */
  border-bottom: 1px solid #dea2e6;
  padding: 20px;
}

.cause-ttl {
  display: flex;
  align-items: center;
}

.cause-ttl img {
  margin-right: 10px;
}

ul.cause-complaint li span {
  font-size: 18px;
  color: #232330;
}

span.promoted-badge {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
  background: #ff5f5f;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
}

.budget-selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.budget-amount .btn {
  padding: 13px 20px;
  font-size: 24px;
  color: #a6a6a6;
  margin-right: 10px;
  border-radius: 4px !important;
}

.budget-amount .group-primary.active {
  color: #fff;
  box-shadow: 0px 2px 10px 4px rgba(0, 0, 0, 0.1);
}

.dya-average {
  display: flex;
  margin: 20px 0 10px 0;
  align-items: center;
}

span.big-font {
  font-size: 34px;
  font-weight: bold;
  color: #575b60;
  margin-right: 3px;
}

ul.ads-features li {
  list-style: disc;
  margin-left: 15px;
  padding: 10px 0;
}

.text-bold {
  font-weight: bold;
}

.or {
  font-size: 14px;
  font-weight: bold;
}

.setting-back {
  display: none;
}

.setting-pages {
  margin-top: 30px;
}

footer {
  margin-top: 30px;
  border-top: none;
  padding-top: 3rem;
  padding-bottom: 2rem;
  background: rgba(249, 173, 173, 0.09);
}

.complaint-modal .modal-body {
  padding: 0;
}

.complaint-modal ul.cause-complaint li {
  display: flex;
  justify-content: space-between;
  border-top: none;
  border-bottom: 1px solid #ededed;
  padding: 20px 15px;
}

.complaint-modal .custom-control-label:after,
.complaint-modal .custom-control-label:before {
  position: absolute;
  top: 0.25rem;
  left: -1rem !important;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  content: "";
}

.complaint-modal .modal-footer {
  border-top: none;
}

.complaint-modal .modal-footer button[type="button"] {
  color: #414141 !important;
  font-size: 16px;
  margin-right: 2rem;
  padding-bottom: 0;
}

.complaint-modal .form-group {
  width: 90%;
  margin: 0 auto;
  vertical-align: top;
}

.complaint-modal .form-group input {
  height: 50px;
  text-align: left;
  vertical-align: top;
}

ul.footer-menu li a {
  font-size: 16px;
  text-decoration: none !important;
  padding-bottom: 5px;
  display: block;
  color: initial;
}

ul.footer-menu {
  text-align: center;
}

ul.footer-social-nav {
  text-align: center;
}

ul.footer-social-nav li {
  display: inline-block;
  margin-right: 1rem;
}

ul.footer-social-nav li a {
  background: #949494;
  color: #fff;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  font-size: 20px;
}

.app-social-media-section {
  text-align: center;
  padding-bottom: 0;
}

.app-social-media-section .social-links {
  display: flex;
  justify-content: center;
}

.app-social-media-section .social-links li {
  margin-right: 5px;
  float: left;
  list-style: none;
}

.app-social-media-section .social-links li a:hover,
.app-social-media-section .social-links li:first-child a {
  background-color: #fb7575;
  color: #fff;
}

.app-social-media-section .social-links li:last-child {
  margin-right: 0;
}

.app-social-media-section .social-links li a {
  width: 62px;
  height: 62px;
  line-height: 62px;
  font-size: 30px;
  font-size: 1.875rem;
  color: #c0c0c0;
  display: block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
}

p.selected-amenities {
  opacity: 0.5;
}

p.secondary-label {
  opacity: 0.8;
  font-size: 13px;
}

p.checkbox-sub-label {
  font-size: 13px;
  opacity: 0.8;
}

p.map-label {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #575b60;
}

.mandatory {
  color: red;
  font-size: 14px;
  padding-left: 2px;
}

.form-control {
  font-family: "Proxima Nova Rg", sans-serif !important;
}

.main-download-wrapper {
  background: #f5f5fa;
  border-radius: 10px;
  margin-top: 3rem;
  padding: 2rem;
}

.download-text-wrapper svg {
  background: #ff5f5f;
  width: 96px;
  height: 96px;
  border-radius: 25px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
}

.download-text-wrapper svg path {
  fill: #fff;
}

.download-text-wrapper h2 {
  font-size: 32px;
  display: inline-block;
  margin-bottom: 0;
  line-height: 25px;
  font-weight: 900;
  text-transform: uppercase;
  margin-left: 2rem;
}

.download-action-wrapper a:first-child {
  margin-right: 1rem;
}

.signup-choose-wrapper .custom-control-label:after,
.signup-choose-wrapper .custom-control-label:before {
  width: 1rem !important;
  height: 1rem !important;
  left: -1.5rem !important;
}

.terms-of-condition .savehome-wrp {
  margin: 0 !important;
  padding: 15px !important;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px) !important;
  width: calc(1rem - 4px) !important;
  height: calc(1rem - 4px) !important;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem !important;
  width: 1.75rem !important;
}

.property-btns .dropdown-menu {
  background: #fff;
  border: 1px solid #ddd;
  width: 100%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
}

.city-dropdown {
  position: absolute;
  z-index: 999999;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  width: 90%;
}

.message-alert {
  position: absolute !important;
  top: 10% !important;
  transform: translate(-50%, -50%);
  text-align: center;
  word-break: break-word;
  left: 50%;
}

div.no-records {
  text-align: center;
  font-weight: bold;
  padding: 6rem 0;
}

div.no-records h2,
.review-no-records h2 {
  font-size: 22px;
  font-weight: 600;
}

div.no-records p,
.review-no-records p {
  opacity: 0.4;
  font-weight: 500;
  font-size: 16px;
  margin-top: 0;
}

div.no-records {
  text-align: center;
  font-weight: 700;
  padding: 20px 30px !important;
  /* position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 50px; */
}

.no-records.with-bio {
  position: relative;
  transform: none;
  left: auto;
  right: auto;
  top: auto;
}

.no-records.position-relative {
  top: auto;
  transform: none;
  left: auto;
}

.saved-home-wrappper,
.settings-content {
  min-height: calc(100vh - 210px);
}

span.card-actions {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
}

span.card-actions a,
span.card-actions button {
  margin-left: 1rem;
  float: left;
}

span.card-actions a svg path.fill-change,
span.card-actions button svg path.fill-change {
  fill: #fff;
}

span.card-actions a:last-child {
  margin-left: 0.5rem;
}

.download-action-wrapper a:first-child {
  margin-right: 0.3rem;
}

.download-action-wrapper img {
  height: 46px;
}

.slick-dots {
  bottom: 75px;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: #fff;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:before {
  font-family: "slick", sans-serif;
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.5;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #fff;
  font-size: 12px;
}

h3.main-title.title-decoration::after {
  content: "";
  width: 30px;
  height: 3px;
  background: #ff5f5f;
  display: block;
  margin: 1.5rem auto 1rem;
}

.settings-content h3.main-title.title-decoration::after {
  margin-left: 0;
}

.available-opt-icons {
  width: 100%;
  height: auto;
  overflow-x: auto;
  white-space: nowrap;
}

/*----------Contact Page-----------*/

form.contact-form input,
form.contact-form textarea {
  background: #eef1f5;
  border: none;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  font-size: 16px;
  line-height: 35px;
  outline: none !important;
}

form.contact-form textarea {
  height: auto;
}

.contacts-img img {
  width: 24px;
  margin-right: 2rem;
  filter: invert(1) grayscale(1);
}

.contacts-item {
  margin-bottom: 2rem;
  align-items: flex-start !important;
}

.contact-details p {
  color: #a08b8b;
  font-size: 16px;
  margin-bottom: 0;
}

.contact-details p a {
  color: #a08b8b;
  font-size: 16px;
  margin-bottom: 0;
  text-decoration: none !important;
}

/* Mobile CSS */
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
  .download-text-wrapper h2 {
    font-size: 24px;
  }
  .property-btns {
    max-width: 400px;
  }
  .livingReport {
    margin-top: 16px !important;
  }
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-toggler {
    border: 0;
  }

  .taste-header .navbar-light .navbar-nav .nav-link {
    padding: 5px 10px;
  }

  .single-conversation.right,
  .single-conversation.left {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .budget-selection {
    flex-direction: column;
  }

  .or {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .container.download-app-wrapper {
    display: none;
  }
}

@media (max-width: 767.98px) {
  /* Mobile menu */
  .mobile-menu {
    position: fixed;
    bottom: 0;
    height: 56px;
    z-index: 99999;
    background: #fff;
    border-top: 1px solid #d7d7db;
    width: 100%;
    left: 0;
    display: block;
  }

  .mobile-menu ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }

  .nav-link.active path.fill-change {
    fill: #ff5f5f;
    stroke: none;
  }

  .nav-link.active g.fill-change {
    stroke: #ff5f5f;
  }

  .taste-header {
    display: none;
  }

  .search-wrapper h3 {
    color: #575b60;
    font-size: 22px;
  }

  .search-wrapper .form-control {
    padding-left: 45px;
    box-shadow: 0px 7px 12px 2px rgba(0, 0, 0, 0.1);
    border: 0;
  }

  .search-wrapper {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    min-width: 100px !important;
  }

  .living .slick-slide {
    min-width: 235px !important;
    min-height: 273px;
  }

  .living .slick-slide > div,
  .living .slick-slide > div > div {
    min-height: 273px;
  }
  .living .slick-slide > div > div .card {
    min-height: 263px;
  }

  .recommended .card {
    margin-bottom: 15px;
  }

  .card-img {
    height: 100px;
  }

  .listing .card-img {
    height: 180px;
  }

  .card-body {
    padding: 1rem;
  }

  .recommended .card-img {
    height: 180px;
  }

  .recommended,
  .reply-review {
    padding-bottom: 70px;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .card {
    margin: 5px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  }

  .hero {
    background: none;
    min-height: auto;
  }

  .overlay {
    display: none;
  }

  h3.main-title {
    text-align: left;
    font-size: 18px;
    display: block;
  }

  span.step-badge {
    text-align: left;
    display: block;
  }

  h3 {
    font-size: 16px;
  }

  .savehome-wrp h3 {
    font-size: 22px;
  }

  .sidebar-wrapper ul li a span img {
    margin-right: 20px;
    width: 15px;
  }

  section {
    padding-top: 32px;
  }

  nav.navbar.navbar-expand-lg.navbar-light.bg-transparent.fixed-top {
    position: relative;
  }

  .top-city .container,
  .sobber-living .container {
    padding-right: 0;
  }

  footer {
    display: none;
  }

  .property-top-right .btn {
    padding: 8px 15px;
  }

  .property-top {
    margin-top: -50px;
    position: relative;
    background: #fff;
    border-bottom: 0;
    padding-bottom: 20px;
    border-radius: 50px 50px 0 0;
  }

  .property-wrp {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .property-top-left h3 {
    display: block;
    color: #575b60;
    font-size: 20px;
    margin-bottom: 1rem;
    width: 100%;
    position: relative;
    padding-right: 40px;
    padding-left: 0px;
    padding-top: 5px;
  }

  .property-top-left h3 span {
    position: absolute;
    right: 0;
    top: 5px;
  }

  .rate {
    justify-content: center;
  }

  ul.feature-list li {
    width: 100%;
  }

  ul.feature-list li:nth-child(odd) {
    margin-right: 0;
  }

  .curfew-wrp {
    margin-bottom: 20px;
  }

  a.info-ico {
    position: fixed;
    top: 25px;
    right: 25px;
  }

  .contact-home-btn {
    position: fixed;
    bottom: 10px;
    text-align: center;
    z-index: 99;
    padding: 10px;
    left: 0;
    right: 0;
    max-width: 300px;
    margin: 0 auto;
  }

  .property-top-right .btn {
    width: calc(50% - 6px);
    text-align: center;
    display: block;
    padding: 8px !important;
    column-gap: 8px !important;
  }

  .property-top-right .btn img {
    width: 16px;
  }

  section.back {
    padding-top: 15px;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
    padding-bottom: 15px;
  }

  section.back img {
    width: 20px;
  }

  .listing-header {
    justify-content: flex-start;
    margin-bottom: 20px;
    padding-top: 40px;
  }

  .filter {
    position: fixed;
    right: 0;
    top: 0;
    background: #fff;
    width: 100%;
    z-index: 999;
    box-shadow: 0px 2px 10px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
    padding: 20px;
    overflow: auto;
    transform: translateX(100%);
    overflow-y: auto;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  .filter.active {
    right: 100%;
  }

  .filter-options {
    position: fixed;
    bottom: 75px;
    z-index: 999;
    width: 100%;
    background: #fff;
    max-width: 300px;
    left: 50%;
    transform: translate(-50%, 10px);
    border-radius: 6px;
  }

  .filter-options ul li {
    padding: 8px 0;
    width: 100%;
  }

  .filter-options ul li a {
    display: flex !important;
    text-align: center;
    justify-content: center;
  }

  .listing {
    padding-bottom: 100px;
  }

  .authentication.modal {
    padding: 0 !important;
    z-index: 999999;
  }

  .authentication .modal-dialog-centered {
    align-items: flex-end;
    min-height: 100%;
  }

  .authentication .modal-content {
    border-radius: 37px 37px 0 0;
    border: 0;
  }

  .authentication .modal-dialog {
    margin: 0 auto;
  }

  .available-options,
  .added-photo-listing {
    display: flex;
    flex-flow: wrap;
  }

  .photo-wrp {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    margin-right: 10px;
    position: relative;
    margin-bottom: 10px;
  }

  .available-options img {
    width: 65px;
    height: 65px;
    border-radius: 5px;
    margin-right: 10px;
    position: relative;
    margin-bottom: 10px;
  }

  .taste-recovery-form {
    box-shadow: none;
    padding: 15px;
  }

  .taste-recovery-form label {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 700;
  }

  .dflex-form {
    padding-bottom: 10px;
  }

  .added-photo-listing {
    flex-flow: nowrap;
    overflow: auto;
    width: 350px;
    display: -webkit-inline-box;
  }

  .listing-header h3 {
    flex-direction: column;
  }

  span.step-badge {
    color: #b9b9b9;
    font-size: 16px;
    margin-left: 0;
    margin-top: 10px;
    font-weight: 300;
  }

  span.delete-pic {
    top: 0;
    right: 0;
  }

  .sidebar-wrapper {
    position: fixed;
    background: white;
    width: 100%;
    z-index: 9;
    height: calc(100vh - 100px);
    top: 63px;
    overflow: auto;
    left: 0;
  }

  .sidebar-settings ul li a {
    padding-left: 1rem !important;
  }

  .section-break-div {
    display: block;
    height: 35px;
    background: #ededed;
    width: 100%;
  }

  .property-btns .btn {
    justify-content: center;
    margin: 0 !important;
  }

  /* .property-btns .btn img {
    display: none;
  } */

  .property-btns .btn.contact-home-btn img {
    display: block;
  }

  .property-btns .btn.contact-home-btn {
    margin: 0 auto !important;
  }

  .property-btns {
    display: flex;
    column-gap: 12px;
  }

  .back-property {
    top: 4%;
    left: 20px;
    /* position: fixed; */
  }

  a.info-ico.fixed-mobile {
    display: none;
  }

  .chat-leftbar {
    min-width: 100%;
    padding: 0 0;
    width: 100%;
  }

  .chat-rightbar {
    display: none;
  }

  .setting-back {
    display: block;
  }

  .back.setting-back {
    margin-top: 0;
    margin-bottom: 0;
    position: fixed;
    background: #fff;
    padding: 12px 15px;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
  }

  .back.setting-back img {
    width: 25px;
  }

  .card-view {
    margin-bottom: 20px;
  }

  .profile-add-btn {
    text-align: center;
  }

  .edit-profile-view img {
    display: none;
  }

  .settings-content {
    padding-bottom: 0px;
    padding-top: 50px;
    min-height: calc(100vh - 85px);
  }

  .cards-wrapper button {
    position: relative;
    bottom: unset;
  }

  .edit-profile-button {
    position: absolute;
    bottom: 1rem;
    width: 90%;
    left: auto;
    right: auto;
    display: block;
  }

  .cards-wrapper button.btn-secondary {
    right: 0;
  }

  .setting-pages {
    margin-top: 0;
  }

  #settingContent {
    display: none;
  }
  .chat-wrapper,
  .chat-rightbar {
    border: 0;
  }
  .chat-wrapper {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 0 5px;
    height: calc(100vh - 126px);
    margin-top: 70px;
  }

  .start-conversation {
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0;
  }

  .start-conversation input {
    margin-bottom: 0;
  }

  a.send-mes-btn {
    right: 10px;
  }

  div.no-records {
    text-align: center;
    font-weight: bold;
    padding: 6rem 0;
  }

  div.no-records h2,
  .review-no-records h2 {
    font-size: 22px;
    font-weight: 600;
  }

  .profile-add-btn button {
    min-width: 250px;
    padding: 0.6rem 2rem;
  }

  div.no-records p,
  .review-no-records p {
    opacity: 0.4;
    font-weight: 500;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }

  div.no-records {
    text-align: center;
    font-weight: 700;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 473px);
  }

  .chat-rightbar {
    max-height: 70vh;
    overflow: hidden;
    position: relative;
  }

  .conversation-content {
    padding: 0px;
    overflow-y: auto;
    height: 100%;
  }

  .left span.conv-box {
    width: calc(100% - 40px);
  }

  .single-conversation.right,
  .single-conversation.left {
    width: 80%;
    max-width: 100%;
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
  }
  .single-conversation.right span.conv-box.msg-block_left {
    background: #f1f2f3;
    width: 100%;
    display: block;
    padding: 12px;
    border-radius: 18px;
  }
  .sobber-living .card-img,
  .recommended-living .card-img {
    height: 180px;
  }

  section.listing .card {
    box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.1);
  }

  .filter-top {
    display: flex;
    border-bottom: 1px solid #ddd;
  }

  .filter-header .apply {
    padding-left: 0;
    padding-right: 0;
  }

  .property-top .loc-n-status {
    display: block;
  }

  .property-top .loc-n-status span.status {
    color: #7b7f84;
  }

  .property-top span.location {
    align-items: center;
    color: #000;
    font-size: 14px;
    font-weight: 300;
    line-height: 15px;
    margin-bottom: 10px;
    justify-content: center;
    display: none;
  }

  .property-top span.rating-recommended {
    margin-right: 1rem;
  }

  .about,
  .abilable-options,
  .map-view,
  .curfew-wrp {
    padding-bottom: 15px;
  }

  .available-opt-icons img {
    padding-right: 10px;
    width: 80px;
    height: 80px;
    padding-bottom: 10px;
  }

  .custom-control-label:after,
  .custom-control-label:before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    content: "";
  }

  .add-property-from {
    box-shadow: none !important;
    padding: 0 !important;
    display: block;
    justify-content: center;
    align-items: center;
  }

  .chat-search .form-control {
    padding-left: 45px;
    height: 50px;
    /*box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.1);
  border: none;*/
    font-family: "Proxima Nova Rg", sans-serif;
  }

  .chat-search .search-icon {
    position: absolute;
    left: 15px;
    top: 13px;
  }

  .custom-control-label:after,
  .custom-control-label:before {
    width: 1.5rem !important;
    height: 1.5rem !important;
    left: 0 !important;
  }

  div.views {
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
    margin: 0 0px 40px !important;
  }

  .listing-header.ad-activity-wrapper {
    display: block;
    text-align: left;
    align-items: start;
  }

  .listing-header.ad-activity-wrapper h3.main-title {
    text-align: left;
    font-size: 22px;
    display: block;
  }

  .filter select.form-control {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ced4da;
    padding-left: 0;
  }

  .available-options img,
  .photo-wrp {
    margin-bottom: 40px;
  }

  /* .amount-wrapper {
  display: none;
  } */
  .property-wrapper {
    padding-right: 10px;
    width: calc(100% - 75px);
  }

  .app-social-media-section {
    display: none;
  }

  .custom-switch .custom-control-label::after {
    top: calc(0.45rem + 2px);
    left: calc(-2.05rem + 2px) !important;
    width: calc(1rem - 4px) !important;
    height: calc(1rem - 4px) !important;
  }

  .custom-switch .custom-control-label::before {
    left: -2.25rem !important;
    width: 2.75rem !important;
    border-radius: 0.7rem;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(1.35rem) !important;
  }

  .contact-home-btn {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .location-wrp {
    padding-left: 15px;
    border-bottom: 0;
    margin-bottom: 0;
  }

  .details-map {
    height: 200px !important;
    width: 100%;
  }

  .download-app-wrapper {
    display: none;
  }

  .map-view-wrp {
    display: flex;
    flex-direction: column-reverse;
  }

  .saved-home-wrappper {
    min-height: auto;
  }

  ul.feature-list {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  section.sobber-living .slider-wrapper .property-wrapper {
    border-right: none;
    width: 100%;
  }

  section.sobber-living .slider-wrapper .amount-wrapper {
    display: none;
  }

  section.sobber-living .slider-wrapper span.location {
    max-width: 100%;
  }

  section.sobber-living .slider-wrapper .card-body {
    padding: 10px;
  }

  /* section.sobber-living .slider-wrapper .card-img {
    height: 130px;
  } */

  .about h4.sub-title,
  .abilable-options h4.sub-title,
  .location-wrp h4.sub-title {
    text-transform: capitalize;
    font-weight: 500;
  }

  .available-opt-icons {
    width: 100%;
    height: 95px;
    overflow-x: auto;
    white-space: nowrap;
    display: block;
  }

  .available-opt-icons img {
    display: inline-block;
  }

  .property-top .loc-n-status span.status.available::before {
    content: "";
    width: 10px;
    height: 10px;
    background: #87bf87;
    display: inline-block;
    border-radius: 50%;
  }

  .property-top .loc-n-status span.status.booked::before {
    content: "";
    width: 10px;
    height: 10px;
    background: #dc3545;
    display: inline-block;
    border-radius: 50%;
  }

  .rating-recommended svg path#Path-2 {
    fill: #7b7f84;
  }

  .rate-here {
    background: #fff;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 15px 15px;
  }

  /* .react-rater-star {
    font-size: 16px !important;
  } */

  .add-card-button {
    position: absolute;
    right: 15px;
    top: 2.5rem;
  }

  h3.main-title.title-decoration::after {
    display: none;
  }

  .edit-profile-view.taste-recovery-form .form-control {
    font-size: 13px;
  }
}

.subscription-msg {
  font-size: 14px;
  line-height: 16.9px;
  padding: 6px 16px;
  background: #1bc675;
  border-radius: 4px 0px 0px 4px;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 21px;
  height: 28px;
  color: #fff;
}

.subscription-msg.unsubscribed {
  background: #ff5f5f;
}

.active-plan {
  background: #ff5f5f;
  padding: 25px 0 22px;
}

.plan-wrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plan-content span {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  margin-bottom: 8px;
  display: inline-block;
}

.plan-content h3 {
  margin: 0;
  font-size: 28px;
  line-height: 32px;
  color: #fff;
  display: flex;
  align-items: center;
  column-gap: 7px;
}

.plan-content h3 span {
  font-weight: normal;
  text-transform: capitalize;
  padding-left: 7px;
  margin-bottom: 0;
  height: 18px;
  line-height: 18px;
  position: relative;
}

.plan-content h3 span:before {
  content: "";
  height: 18px;
  border-left: 1px solid #fff;
  position: absolute;
  left: -2.5px;
  opacity: 0.45;
  transform: rotate(15deg);
}

.btn-white {
  background-color: #fff;
  color: #ff5f5f !important;
  border-color: #ff5f5f;
}

.subscription-title h3 {
  font-size: 20px;
  line-height: 22px;
  /* margin-bottom: 38px; */
  text-align: center;
}

.subscription-title span {
  text-align: center;
  /* margin-bottom: 44px; */
  font-size: 16px;
  line-height: 20px;
}

.addingform {
  margin-bottom: 44px;
}

.plan-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 25px;
}

.plan-card {
  background: #fff;
  box-shadow: 0px 3px 6px #00000012;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 35px 30px;
  min-width: 250px;
}

.plan-card h3 {
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: #ff5f5f;
}

.plan-info {
  display: flex;
  column-gap: 45px;
}

.plan-info ul {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.plan-info ul li {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  padding-left: 20px;
}

.plan-info ul li:before {
  content: "";
  width: 12px;
  height: 12px;
  background-image: url(images/tick.svg);
  position: absolute;
  left: 0px;
  top: 4px;
}

.subscription-title {
  display: flex;
  flex-direction: column;
}

.subscription-title form {
  width: max-content;
}

.subscription-title form .form-group {
  margin-bottom: 23px;
  padding-bottom: 0;
  column-gap: 15px;
}

.subscription-title form .form-group label {
  margin-bottom: 0;
  font-size: 14px !important;
  line-height: 27px;
  color: #000;
  opacity: 1;
}

.subscription-title .custom-switch .custom-control-label::before {
  width: 54px !important;
  height: 26px;
  border-radius: 13px;
  top: 0;
}

.subscription-title .custom-switch .custom-control-label::after {
  top: 3px;
  left: calc(-2.25rem + 3px) !important;
  height: 20px !important;
  width: 20px !important;
  border-radius: 10px;
}

.subscription-title
  .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(1.75rem);
}

.subscription-title
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #00d64f;
  background-color: #00d64f;
}

.subscription-title .custom-control-input {
  z-index: 1;
  width: 54px;
  height: 26px;
  margin: 0;
}

.upgrade-plan .modal-content {
  border: 1px solid #707070;
  border-radius: 12px;
}

.upgrade-plan-content {
  padding: 35px;
  text-align: center;
}

.upgrade-plan-content h3 {
  font-size: 24px;
  line-height: 27px;
  margin-bottom: 23px;
  text-transform: capitalize;
}

.upgrade-plan-content .loading-msg {
  font-size: 16px;
  line-height: 1.5;
}

.upgrade-plan .modal-footer {
  justify-content: center;
  border-top: 1px solid #70707029;
  padding: 17px 30px;
}

.upgrade-plan .modal-footer .modal-btn {
  flex: 1;
  background: transparent;
  border: 0;
  outline: 0;
  font-size: 16px;
  line-height: 24px;
  color: #575b60;
  font-weight: bold;
  padding: 0;
  margin: 0;
  border-right: 1px solid #70707038;
  text-transform: uppercase;
}

.upgrade-plan .modal-footer .modal-btn.confirm-btn {
  border-right: 0;
  color: #f74a4a;
}

.pro-plan-modal .modal-dialog {
  max-width: 500px;
  margin: 0 0 0 auto;
  height: 100%;
}

.pro-plan-modal.upgrade-plan .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
  overflow-y: auto;
}

.pro-plan-content.upgrade-plan-content {
  padding: 0;
  text-align: left;
}

.pro-plan-content .modal-header {
  padding: 25px 40px;
  align-items: center;
  border-bottom: 1px solid #70707029;
}

.pro-plan-content .modal-header h3,
.pro-plan-content .modal-header img {
  margin: 0;
}

.pro-plan-content .plan-content {
  padding: 32px 40px;
  height: calc(100vh - 153px);
  overflow: auto;
}

.pro-plan-content .plan-info {
  padding-bottom: 32px;
  border-bottom: 1px solid #70707029;
}

.pro-plan-content.upgrade-plan-content .loading-msg {
  margin-top: 45px;
  margin-bottom: 30px;
  color: #333333;
}

.plan-price-content {
  background: #ff5f5f;
  padding: 18px 22px;
  border-radius: 8px;
}

.pro-plan-modal .upgrade-plan-content .plan-price-content h3 {
  margin-bottom: 0;
  font-size: 32px;
  line-height: 38px;
  column-gap: 12px;
}

.pro-plan-modal .upgrade-plan-content .plan-price-content h3 span {
  margin-bottom: 0;
}

.pro-plan-modal .plan-content span {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 18px;
  display: inline-block;
}

.pro-plan-modal.upgrade-plan .modal-footer .btn-outline {
  border: 2px solid #ff5f5f;
  color: #ff5f5f !important;
  margin: 0;
  text-transform: capitalize;
}

.pro-plan-modal.upgrade-plan .modal-footer .btn-outline:hover {
  color: #fff !important;
  background-color: #ff8686 !important;
  border-color: #ff8686 !important;
}

.pro-plan-modal.upgrade-plan .modal-footer .btn-primary {
  border: 2px solid #ff5f5f;
  margin: 0;
  text-transform: capitalize;
  min-width: 150px;
  padding: 6px 25px;
}

.pro-plan-modal.upgrade-plan .modal-footer {
  column-gap: 16px;
}

.pro-plan-modal form {
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin-top: 35px;
}

.pro-plan-modal {
  padding-right: 0 !important;
  background: #232330bf;
}

.pro-plan-modal .form-group {
  margin-bottom: 0;
}

.pro-plan-modal input {
  width: 22px;
  height: 22px;
  box-shadow: none !important;
  margin: 0;
}

.subscribePlan {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.pro-plan-modal input[type="radio"]:before {
  content: "";
  width: 22px;
  height: 22px;
  border: 1px solid #ff5f5f;
  position: absolute;
  left: 0;
  border-radius: 12px;
  background: #fff;
}

.pro-plan-modal input[type="radio"]:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #ff5f5f;
  position: absolute;
  left: 5px;
  top: 5px;
  border-radius: 12px;
  opacity: 0;
  visibility: hidden;
}

.pro-plan-modal input[type="radio"]:checked:after {
  opacity: 1;
  visibility: visible;
}

.pro-plan-modal label {
  position: relative;
  line-height: 22px;
  opacity: 1;
  color: #000;
  margin: 0;
}

/* .pro-plan-modal label:before {
  content: '';
  width: 22px;
  height: 22px;
  border: 1px solid #FF5F5F;
  position: absolute;
  left: 0;
  border-radius: 12px;
}
.pro-plan-modal label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #FF5F5F;
  position: absolute;
  left: 5px;
  top: 5px;
  border-radius: 12px;
  opacity: 0;
  visibility: hidden;
}
.pro-plan-modal input:checked ~ label:after {
  opacity: 1;
  visibility: visible;
} */
.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: visible;
  width: 280px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000012;
  border: 1px solid #dddddd;
  color: #e44646;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  border-radius: 8px;
  padding: 13.5px 17.5px;
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 10px);
  left: 0;
}

.tooltip .tooltiptext:after,
.tooltip .tooltiptext:before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
  border-style: solid;
}

.tooltip .tooltiptext:after {
  left: 16px;
  border-color: #fff transparent transparent transparent;
  border-left-width: 11px;
  border-right-width: 11px;
  border-top-width: 14px;
}

.tooltip .tooltiptext:before {
  left: 15px;
  border-color: #dddddd transparent transparent transparent;
  border-left-width: 12px;
  border-right-width: 12px;
  border-top-width: 16px;
}

.toast.subscription-toast {
  background: #ff5f5f;
  border-radius: 0;
  border: 0;
  padding: 14px 70px;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  margin: 0;
}

.toast.subscription-toast .toast-body {
  padding: 0;
}

.toast.subscription-toast p {
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 22px;
  margin: 0;
}

.toast.subscription-toast p img {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  filter: brightness(0);
}

.toast.subscription-toast .modal-footer {
  display: none;
}

.tick_mark-icon {
  display: none;
}

.pro-plan-content .back-arrow {
  display: none;
}

.pro-plan-content .cancel-button span {
  display: none;
}

.btn {
  min-width: 120px;
}

.back-property img {
  width: 24px;
}

@media (max-width: 1024px) {
  .property-wrp {
    display: block !important;
  }

  .property-top-left.detailpagetitle.seekerpage {
    width: auto !important;
  }
  .property-btns {
    max-width: 100%;
    justify-content: start;
  }
  .property-btns .detailbtn {
    margin-right: 16px !important;
  }
}

@media (max-width: 767.98px) {
  .subscription-msg {
    border-radius: 0px 4px 4px 0px;
    left: 0;
    right: auto;
    bottom: unset;
    top: 16px;
    height: 28px;
  }

  .plan-list {
    row-gap: 40px;
  }

  .plan-card {
    box-shadow: unset;
    border: 0px;
    border-radius: 0px;
    padding: 0;
  }

  .plan-info {
    flex-wrap: wrap;
    row-gap: 16px;
  }

  .plan-info ul {
    row-gap: 16px;
  }

  .subscription-wrp {
    border-bottom: 1px solid #ddd;
    padding-bottom: 32px;
  }

  .plan-content h3 {
    font-size: 24px;
    line-height: 27px;
  }

  .subscription-title h3 {
    font-size: 18px;
    line-height: 33px;
    margin-bottom: 0;
    font-weight: 500;
    color: #232330;
  }

  .plan-card h3 {
    font-size: 16px;
    line-height: 19px;
    color: #575b60;
  }

  .subscription-title .custom-switch .custom-control-label::after {
    left: calc(-3.45rem + 3px) !important;
    height: 25px !important;
    width: 25px !important;
    border-radius: 15px;
    top: 1px;
  }

  .subscription-title .custom-switch .custom-control-label::before {
    width: 51px !important;
    height: 31px !important;
    border-radius: 16px;
    left: -3.45rem !important;
    top: -2px;
  }

  .subscription-title form .form-group {
    margin-bottom: 0;
  }

  .subscription-title form {
    width: 110px;
  }

  .pricing-toggle input[type="radio"]:checked + label.radio-button:before,
  .circle-paragraph::before {
    display: none !important;
  }

  .subscription-title {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 15px;
    margin-bottom: 32px;
  }

  .subscription-title form .form-group label {
    line-height: 31px;
    color: #575b60;
  }

  .plan-info ul li {
    font-size: 14px;
    line-height: 18px;
    color: #999da2;
  }

  .tooltip .tooltiptext {
    top: calc(100% + 64px);
    bottom: unset;
    left: unset;
    right: calc(100% - 80px);
  }

  .tooltip .tooltiptext:after,
  .tooltip .tooltiptext:before {
    top: unset;
    bottom: 100%;
  }

  .tooltip .tooltiptext:after {
    left: unset;
    right: 16px;
    border-color: transparent transparent #fff transparent;
    border-top-width: unset;
    border-bottom-width: 14px;
  }

  .tooltip .tooltiptext:before {
    left: unset;
    right: 15px;
    border-color: transparent transparent #dddddd transparent;
    border-top-width: unset;
    border-bottom-width: 16px;
  }

  .upgrade-plan .modal-dialog {
    /* width: 270px; */
    margin: 0 auto;
  }

  .upgrade-plan-content {
    padding: 25px 22px;
  }

  .upgrade-plan-content h3 {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 17px;
    color: #575b60;
  }

  .upgrade-plan-content .loading-msg {
    font-size: 13px;
    line-height: 16px;
    display: block;
  }

  .upgrade-plan .modal-footer {
    padding: 0;
  }

  .upgrade-plan .modal-footer .modal-btn {
    line-height: 44px;
    font-weight: normal;
    text-transform: capitalize;
  }

  .toast.subscription-toast p img {
    display: none;
  }

  .toast.subscription-toast .modal-footer {
    display: flex;
  }

  .toast.subscription-toast {
    background: #00000080;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toast.subscription-toast .toast-body {
    padding: 0;
    border: 1px solid #707070;
    border-radius: 12px;
    background: #fff;
    min-width: 270px;
  }

  .toast.subscription-toast p {
    color: #575b60;
    font-size: 17px;
    line-height: 22px;
    padding: 30px 16px;
    font-weight: 600;
  }

  .toast.subscription-toast p img.tick_mark-icon {
    display: block;
    position: relative;
    right: unset;
    transform: unset;
    top: unset;
    margin: 0 auto 18px;
    filter: unset;
  }

  .pro-plan-content .back-arrow,
  .pro-plan-content .cancel-button span {
    display: block;
  }

  .pro-plan-content .cancel-button img {
    display: none;
  }

  .pro-plan-modal.upgrade-plan .modal-dialog {
    width: 100%;
    max-width: 100%;
  }

  .pro-plan-content .modal-header {
    padding: 14px 26px;
    border-bottom: 0px;
    flex-wrap: wrap;
  }

  .pro-plan-content .modal-header h3 {
    order: 3;
    width: 100%;
    margin-top: 32px;
    font-size: 21px;
    line-height: 25px;
  }

  .pro-plan-content .plan-content {
    padding: 16px 26px;
    height: calc(100vh - 231px);
  }

  .pro-plan-modal label:before {
    border: 1px solid #a1a1a1;
  }

  .pro-plan-modal label:after {
    width: 22px;
    height: 22px;
    left: 0px;
    top: 0px;
    box-shadow: 0px 2px 6px #00000038;
  }

  .pro-plan-modal .upgrade-plan-content .plan-price-content h3 {
    color: #fff;
  }

  .pro-plan-modal.upgrade-plan .modal-footer {
    border: 0;
    padding: 40px 26px 40px;
  }

  .pro-plan-modal.upgrade-plan .modal-footer .btn-outline {
    display: none;
  }

  .pro-plan-modal.upgrade-plan .modal-footer .btn-primary {
    width: 100%;
    box-shadow: 0px 5px 20px #00000038;
  }

  .pro-plan-content .cancel-button {
    text-decoration: none;
  }

  .pro-plan-content .cancel-button span {
    font-size: 16px;
    line-height: 19px;
    color: #ff5f5f;
    font-weight: bold;
  }

  button.btn.btn-primary.radius-20.btn-primary.text-center.detailbtn.btn-minwidth.resp-btn {
    display: flex !important;
    margin-top: 1rem;
  }

  button.btn.btn-primary.radius-20.btn-primary.text-center.detailbtn.btn-minwidth.web-btn {
    display: none;
  }

  .btn.btn-primary.radius-20.btn-primary.btn-minwidth {
    min-width: unset !important;
    margin-right: 0px !important;
  }
  .property-btns .btn.contact-home-btn.btn-minwidth.radius-20 {
    margin: 0 auto !important;
    min-width: calc(100% - 30px) !important;
  }
  .property-btns {
    justify-content: center;
  }
  .property-top-left h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    padding-right: 0;
  }
  .property-top-left h3 span {
    position: relative;
    top: 0;
  }
  .property-top-left h3 span img {
    margin-left: 0;
    margin-top: 0;
  }
}

button.btn.btn-primary.radius-20.btn-primary.text-center.detailbtn.btn-minwidth.resp-btn {
  display: none;
}

.btn.btn-primary.radius-20.btn-primary.detailbtn {
  min-width: auto;
  display: flex;
  align-items: center;
  padding: 10px;
  column-gap: 10px;
}

.btn.btn-primary.radius-20.btn-primary.btn-minwidth {
  min-width: 105px;
  justify-content: center;
}

.livingReport {
  text-align: end;
  display: block;
  margin-top: 2.6rem;
}

span.rating-recommended svg {
  margin-right: 6px;
}

.circle-paragraph {
  position: relative;
  padding-left: 30px;
  color: #11c90b;
  margin-bottom: 3rem;
}

.circle-paragraph::before {
  content: "\2713";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #11c90b;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 15px;
  background: #11c90b;
  color: white;
}

button.btn.btn-primary.radius-20.mb-20 {
  margin-bottom: 2rem;
}

.plan-card.activecard {
  border: #ff5f5f 1px solid;
}

/* ============toggle switch */
.pricing-toggle {
  background-color: #ff5f5f;
  border-radius: 30px;
  display: inline-block;
  width: 250px;
  padding-left: 20px;
  padding-top: 10px;
}

.pricing-toggle [name="pricing"] {
  display: none;
}

.pricing-toggle input[type="radio"] + label {
  /* background-color: #3FAED7; */
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
}

.pricing-toggle input[type="radio"]:checked + label {
  background-color: white;
  color: black;
}

.inactivate-paragraph {
  position: relative;
  padding-left: 30px;
  color: grey;
  margin-bottom: 3rem;
}

.inactivate-paragraph::before {
  content: "\2713";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid grey;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 15px;
  background: grey;
  color: white;
}

.togglediv {
  text-align: center;
  margin-bottom: 2rem;
}

.pricing-toggle input[type="radio"]:checked + label.radio-button:before {
  content: "\2713";
  display: block;
  position: absolute;
  left: 5px;
  top: 47%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #11c90b;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 15px;
  background: #11c90b;
  color: white;
}

label.radio-button {
  position: relative;
  padding-left: 30px !important;
}

.plans-pricing .subscription-title h3 {
  font-size: 32px;
  line-height: 32px;
  color: #575b60;
  font-weight: bold;
  padding-bottom: 40px;
  margin-bottom: 80px;
  position: relative;
}

/* .plans-pricing .subscription-title h3:after {
  content: '';
  width: 30px;
  height: 3px;
  background-color: #FF5F5F;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
} */

.togglediv {
  margin-bottom: 50px;
  column-gap: 12px;
}

.pricing-toggle {
  border-radius: 24px;
  display: inline-flex;
  height: 48px;
  width: auto;
  padding: 0 5px;
}

.pricing-toggle input[type="radio"] + label {
  margin: 0;
  min-width: 140px;
  height: 40px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 10px !important;
  font-size: 14px !important;
  line-height: 17px;
  color: #fff;
}

.pricing-toggle input[type="radio"]:checked + label.radio-button:before,
.circle-paragraph::before {
  content: "";
  border: 0px;
  background-image: url(../assets/images/checkmark_check_tick.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  position: relative;
  top: unset;
  left: unset;
  transform: unset;
}

.circle-paragraph {
  padding-left: 0;
  column-gap: 10px;
}

.circle-paragraph::before {
  background: url(../assets/images/checkmark_check_tick-green.svg);
  background-color: #11c90b;
  background-size: contain;
  background-repeat: no-repeat;
}

.plan-card {
  max-width: 360px;
  width: 100%;
  border: 1px solid #d7d7db;
  padding: 25px 30px 45px;
  box-shadow: 15px 20px 35px transparent;
  transition: all ease-in-out 0.5s;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.plan-card:hover {
  box-shadow: 15px 20px 35px #0000001a;
}

.plan-card.activecard {
  border: 1px solid #d7d7db;
}

.plan-card h3 {
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 22px;
}

.plan-card h4 {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 22px;
}

.plan-card .btn-primary {
  min-width: 160px;
  height: 40px;
  font-size: 14px;
}

.plan-info ul li {
  color: #000;
}

.offer {
  background: #1bc675;
  border-radius: 8px;
  padding: 6px 10px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
}

.save-para {
  font-size: 18px;
  line-height: 20px;
  height: 20px;
  font-weight: 500;
  color: #0cb364;
  margin-bottom: 20px;
  display: block;
}

.basic-to-pro p {
  font-size: 16px;
  line-height: 24px;
  color: #000;
}

.basic-to-pro .modal-dialog {
  max-width: 491px;
}

.modal-open,
.modal-open .modal {
  padding-right: 0 !important;
}

@media (max-width: 767.98px) {
  .basic-to-pro .modal-dialog {
    width: 95%;
    margin: 0 auto;
  }

  .listing .subscription-title {
    flex-direction: column;
  }

  .plan-card .btn-primary {
    order: 1;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  button.btn.btn-primary.radius-20.mb-20 {
    margin-bottom: 0;
  }

  .plan-card {
    padding: 15px 16px;
  }

  .save-para {
    height: auto;
    margin-bottom: 8px;
    font-size: 16px;
  }

  .save-para:empty {
    margin: 0;
  }

  .plan-card h4 {
    border-bottom: 1px solid #d7d7db;
    margin: 0px -16px 15px;
    padding: 0 16px 15px;
    width: calc(100% + 32px);
  }

  .plan-card h3 {
    margin-bottom: 5px;
    color: #ff5f5f;
  }

  .circle-paragraph {
    margin-bottom: 20px;
  }

  .plan-list {
    row-gap: 16px;
  }

  .subscription-wrp {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .togglediv {
    justify-content: flex-end !important;
    row-gap: 8px;
  }

  .pricing-toggle {
    width: 95%;
    margin: 0 auto;
  }

  .subscription-wrp {
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
  }

  .toggle-button-container {
    width: 50%;
  }

  .pricing-toggle input[type="radio"] + label {
    width: 100%;
    min-width: unset;
  }

  .plans-pricing .subscription-title h3 {
    padding-bottom: 30px;
    margin-bottom: 60px;
  }
}

@media (max-width: 575.98px) {
  .subscription-wrp {
    max-width: 100%;
  }

  .plan-card {
    max-width: 100%;
  }

  .subscription-title {
    margin-bottom: 28px;
  }

  .togglediv {
    margin-bottom: 16px;
  }

  .subscription-title span {
    margin-bottom: 0;
    text-align: left;
    font-size: 14px;
  }

  .subscription-title h3 {
    text-align: left;
  }

  .pricing-toggle {
    width: 100%;
  }

  .plans-pricing .subscription-title h3 {
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-size: 24px;
  }

  .plans-pricing .subscription-title h3:after {
    left: 0;
    transform: unset;
  }
}

button.btn.btn-primary.radius-20.mb-20.pricingbtn {
  cursor: none;
}

button.btn.btn-primary.radius-20.mb-20.pricingbtn:active {
  background: #ff5f5f;
  border: #ff5f5f;
  cursor: none;
}

button.btn.btn-primary.radius-20.mb-20.pricingbtn:focus {
  box-shadow: 0 0 0 0.2rem rgb(255 95 95);
}

.property-top-left.detailpagetitle {
  width: 600px;
}

button.btn.btn-primary.radius-20.mb-20.basicbtn {
  background: white;
  color: #ff5f5f !important;
}

.property-top-left.detailpagetitle.seekerpage {
  width: 436px;
}

h3.detailtitle {
  text-align: left;
}

.recommended .card-body {
  height: 100px;
}
.top-bar + .taste-header .fixed-top {
  margin-top: 44px;
}
/* .top-bar + .taste-header.scrolled .fixed-top {
  margin-top: 0;
} */
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9;
}

/* 30-01-2023 Prachi's changes */
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1120px;
  }
}
@media (min-width: 1270px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1197px;
  }
}
.main-wrapper {
  min-height: calc(100vh - 227px);
  padding-bottom: 20px;
}
.authentication .modal-title {
  font-family: "Proxima Nova Extrabold", sans-serif;
  font-size: 24px;
  line-height: 27px;
  font-weight: unset;
}
.authentication .modal-body {
  margin: 44px 0;
}
.authentication .modal-header p,
.extra-link,
.error-message {
  font-family: "Proxima Nova Regular", sans-serif;
}
.extra-link a {
  font-family: "Proxima Nova Semibold", sans-serif;
  color: #ff5f5f !important;
  font-weight: unset;
}
.authentication .form-control {
  font-family: "Proxima Nova Regular", sans-serif !important;
  font-weight: unset !important;
}
.authentication .action-btn .btn {
  font-family: "Proxima Nova Bold", sans-serif;
  letter-spacing: 1.29px;
  width: 100%;
}
.saved-home-wrappper .contact-form .col-md-12:last-child .form-group {
  margin-bottom: 0;
}
.sober-living-listing-wrapper .listing-header {
  column-gap: 16px;
  row-gap: 12px;
  flex-wrap: wrap;
}
.sober-living-listing-wrapper .listing-header h3,
.listing-header h3 {
  font-family: "Proxima Nova Extrabold", sans-serif;
  color: #232330;
}
.btn-primary-outline {
  color: #ff5f5f !important;
  background-color: transparent;
  border-color: #ff5f5f;
  text-transform: uppercase;
}
.btn-primary-outline:hover,
.profile-btn-checked {
  color: #fff !important;
  background-color: #ff5f5f;
}
.btn-lg {
  font-family: "Proxima Nova Semibold", sans-serif;
  height: 48px;
  text-transform: unset;
  font-size: 16px;
  line-height: 21px;
  border-radius: 24px;
}
.colgap-12 {
  column-gap: 12px !important;
}
.rowgap-12 {
  row-gap: 12px !important;
}
.new-owner_wrapper {
  min-height: calc(100vh - 473px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.new-owner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 8px #0000001a;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background-color: #fff;
}
.new-owner_content {
  padding: 42px;
}
.new-owner h2 {
  font-family: "Proxima Nova Extrabold", sans-serif;
  font-size: 42px;
  line-height: 48px;
  color: #232330;
  font-weight: unset;
}
.new-owner p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 20px;
  line-height: 25px;
  color: #a6a6a6;
  font-weight: unset;
}
.new-owner_content .dropdown {
  display: inline-block;
}
.new-owner_content .dropdown .btn {
  font-family: "Proxima Nova Semibold", sans-serif;
  height: 48px;
  padding: 15px 20px;
  line-height: 1;
  border-radius: 24px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  box-shadow: unset !important;
}
.new-owner_content .dropdown.show .btn {
  background-color: #ff5f5f;
  border-color: #ff5f5f;
}
.new-owner_content .dropdown .btn::after {
  font-family: "FontAwesome", sans-serif;
  content: "\f106";
  border: 0;
  margin: 0;
  vertical-align: unset;
  font-size: 24px;
  transition: all ease-in-out 0.5s;
}
.new-owner_content .dropdown.show .btn::after {
  content: "\f107";
}
.new-owner_content .dropdown-menu {
  width: 100%;
  margin-top: 10px;
  box-shadow: 0px 4px 10px #00000029;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  padding: 0;
}
.new-owner_content .dropdown-menu .dropdown-item {
  display: block;
  text-decoration: none;
  padding: 16px 25px;
  border-bottom: 1px solid #7070701f;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #575b60;
  font-weight: unset;
  cursor: pointer;
}
.new-owner_content .dropdown-menu li:last-child .dropdown-item {
  border-bottom: 0px;
}
.new-owner_content .dropdown-menu .dropdown-item:hover {
  color: #ff5f5f;
  background-color: transparent;
}
.new-owner_image img {
  max-width: 501px;
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 0 12px 12px 0;
}
.sober-living-listing-wrapper .listing-header h3 {
  line-height: 36px;
}
span.step-badge {
  margin-left: 36px;
  line-height: 22px;
  font-family: "Proxima Nova Bold", sans-serif;
}
.listing-sub-header h4 {
  font-family: "Proxima Nova Extrabold", sans-serif;
  font-size: 28px;
  line-height: 32px;
  font-weight: unset;
  color: #232330;
}
.taste-recovery-form.add-sobar-living .form-group {
  margin-top: 0;
  margin-bottom: 36px;
}
.taste-recovery-form.add-sobar-living label,
.mandatory {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 22px !important;
  line-height: 25px;
  opacity: 1;
}
.taste-recovery-form.add-sobar-living .form-control {
  font-family: "Proxima Nova Regular", sans-serif !important;
  font-size: 14px;
  line-height: 19px;
  padding: 15px 0;
  height: unset;
  margin-top: 0px;
}
.taste-recovery-form.add-sobar-living .form-group span.note {
  font-family: "Proxima Nova Regular", sans-serif;
  display: block;
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
}
.taste-recovery-form .added-photo-listing .photo-wrp:nth-child(3)::after {
  font-family: "Proxima Nova Regular", sans-serif;
}
.taste-recovery-form.add-sobar-living .form-group p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 12px;
  line-height: 16px;
}
.add-sobar-living .dflex-form {
  justify-content: start;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  padding-bottom: 50px;
  align-items: start;
}
.add-sobar-living .dflex-form .custom-checkbox {
  width: calc(25% - 15px);
  padding-left: 0px;
}
.add-sobar-living .dflex-form .custom-checkbox input {
  margin: 0;
  width: 21px;
  height: 21px;
  top: 2px;
}
.add-sobar-living .dflex-form .custom-checkbox label {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-weight: normal !important;
  padding-left: 31px;
  padding-right: 0;
  font-size: 15px !important;
  line-height: 17px;
  min-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.add-sobar-living .custom-control-label::before,
.add-sobar-living .custom-control-label::after {
  top: 2px;
  width: 21px !important;
  height: 21px !important;
  left: 0;
  box-shadow: 0px 2px 6px transparent;
}
.add-sobar-living
  .custom-control-input:checked
  ~ .custom-control-label::before {
  box-shadow: 0px 2px 6px #00000037;
}
.add-sobar-living .dflex-form .custom-radio {
  padding-left: 0px;
}
.add-sobar-living .dflex-form .custom-radio input {
  margin: 0;
  width: 21px;
  height: 21px;
  top: 2px;
}
.add-sobar-living .dflex-form .custom-radio label {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-weight: normal !important;
  padding-left: 31px;
  padding-right: 0;
  font-size: 15px !important;
  line-height: 17px;
  min-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.add-sobar-living .custom-radio .custom-control-label::before {
  top: 2px;
  width: 21px;
  height: 21px;
  left: 0;
  box-shadow: unset;
}
.add-sobar-living .custom-radio .custom-control-label::after {
  top: 7px;
  width: 11px !important;
  height: 11px !important;
  left: 5px;
  background: transparent;
  box-shadow: unset;
  border-radius: 50%;
}
.add-sobar-living
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: transparent;
  border-color: #ff5f5f;
  box-shadow: unset;
}
.add-sobar-living
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background: #ff5f5f;
}
.add-sobar-living .btn {
  font-family: "Proxima Nova Semibold", sans-serif;
  height: 54px;
  font-size: 18px;
  line-height: 21px;
  border-radius: 27px;
  letter-spacing: 1.29px;
}
.other-insurance .btn.btn-default {
  font-family: "Proxima Nova Regular", sans-serif;
  width: 60px;
  border-color: #9f9f9f;
  background: transparent;
  color: #575b60;
  border-radius: 6px;
  min-width: unset;
  font-size: 14px;
  line-height: 16px;
  height: 32px;
}
.other-insurance .btn.btn-default:hover {
  background-color: #9f9f9f;
  color: #fff;
}
.other-insurance .form-group {
  column-gap: 10px;
}
.other-insurance .form-group .btn-icon {
  width: 25px;
  height: 25px;
  min-width: unset;
  border: 1px solid #575b60;
  background-color: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  line-height: 32px;
  color: #575b60;
}
.other-insurance .form-group .btn-icon:hover {
  background-color: #575b60;
  color: #fff;
}

.subscription-title.sobar-living-subscription-title h3 {
  font-family: "Proxima Nova Bold", sans-serif;
  color: #000;
}
.subscription-title.sobar-living-subscription-title span {
  font-family: "Proxima Nova Regular", sans-serif;
  line-height: 22px;
  color: #7b7f84;
}
.subscription-title.sobar-living-subscription-title p {
  font-size: 18px;
  line-height: 21px;
  color: #575b60;
  text-align: center;
  margin-bottom: 48px;
}

.sober-living-plan-list .plan-card {
  box-shadow: 8px 8px 40px #0000001f;
  padding: 30px;
  border: 2px solid transparent;
}
.sober-living-plan-list .plan-card.activecard {
  border-color: #ff5f5f;
}
.sober-living-plan-list .plan-card h3 {
  font-family: "Proxima Nova Bold", sans-serif;
  line-height: 36px;
  margin-bottom: 20px;
}
.sober-living-plan-list .plan-card h3 span {
  display: block;
  font-size: 16px;
  line-height: 18px;
  color: #a6a6a6;
  font-weight: unset;
  margin-top: 4px;
}
.sober-living-plan-list .plan-card h4 {
  font-family: "Proxima Nova Bold", sans-serif;
  margin-bottom: 20px;
  line-height: 22px;
}
.sober-living-plan-list .plan-card .btn-primary {
  font-family: "Proxima Nova Semibold", sans-serif;
  min-width: 106px;
}
.sober-living-plan-list .basicbtn.plan-selected {
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 18px;
  line-height: 21px;
  color: #0cb364;
  font-weight: unset;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.sober-living-plan-list .basicbtn.plan-selected::before {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  background: url("images/checkmark_check_tick-green_bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}
.sober-living-plan-list .plan-info ul li {
  font-family: "Proxima Nova Semibold", sans-serif;
}
.sober-living-plan-list .plan-info ul li.highlight {
  font-family: "Proxima Nova Extrabold", sans-serif;
  font-size: 18px;
  line-height: 22px;
}

.details-map {
  height: 300px;
  margin-top: 24px;
}
.taste-recovery-form.add-sobar-living label.small-label {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 16px !important;
  line-height: 18px;
  margin-top: 20px;
}

.sober-living-slider {
  row-gap: 15px;
}
.sober-living-slider .sober-living-slider_item .card {
  box-shadow: 0px 2px 4px #0000001a;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  min-height: 100%;
  padding: 10px;
  margin: 0;
}
.sober-living-slider .sober-living-slider_item .card-img {
  height: 160px;
  width: 270px;
  border-radius: 8px;
  overflow: hidden;
}
.sober-living-slider .sober-living-slider_item .card-img img {
  object-fit: cover;
}
.sober-living-slider .sober-living-slider_item .card-img .subscription-msg {
  top: 10px;
  bottom: unset;
  min-width: 70px;
  text-align: center;
}
.sober-living-slider .sober-living-slider_item .card-actions {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 10px;
  position: relative;
  right: unset;
  top: unset;
}
.sober-living-slider .sober-living-slider_item .card-actions img {
  height: 16px;
}
.sober-living-slider
  .sober-living-slider_item
  .card-actions
  .owner-card-action-button {
  padding: 0;
  margin: 0;
  float: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: rgb(255 95 95 / 10%);
  position: relative;
}
.sober-living-slider .sober-living-slider_item .card-heading {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 24px;
  line-height: 27px;
  height: 27px;
  margin-bottom: 22px;
  /* text-transform: uppercase; */
}
.sober-living-slider .sober-living-slider_item .location {
  display: flex;
  height: auto;
  font-family: "Proxima Nova Regular", sans-serif;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 17px;
}
.sober-living-slider .sober-living-slider_item .location img {
  margin-right: 8px;
  width: 14px;
  height: 14px;
  margin-left: 0;
}
.sober-living-slider .sober-living-slider_item .card-price {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #575b60;
  font-weight: unset;
}
.sober-living-slider .sober-living-slider_item .card-price span {
  font-family: "Proxima Nova Regular", sans-serif;
  color: #a6a6a6;
  font-weight: unset;
}
.sober-living-slider .sober-living-slider_item .card-btn-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  column-gap: 7px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.sober-living-slider .sober-living-slider_item .card-body {
  padding: 0 0 0 22px;
}
.sober-living-slider .sober-living-slider_item .card-btn-container .btn,
.ad-activity-block .btn {
  font-family: "Proxima Nova Semibold", sans-serif;
  height: 32px;
  text-transform: capitalize;
  font-size: 13px;
  line-height: 16px;
  font-weight: unset;
  min-width: unset;
  padding: 4px 12px;
}
.sober-living-slider .sober-living-slider_item .card-btn-container .btn {
  height: 38px;
  font-size: 14px;
  padding: 8px 24px;
  position: relative;
}
.sober-living-slider
  .sober-living-slider_item
  .card-btn-container
  .btn.btn-primary:disabled {
  color: #fff !important;
  background-color: #ff5f5f;
  border-color: #ff5f5f;
  opacity: 1;
}
.sober-living-slider
  .sober-living-slider_item
  .card-btn-container
  .btn.btn-primary-outline:disabled {
  opacity: 1;
}
.sober-living-slider
  .sober-living-slider_item
  .card-btn-container
  .btn.btn-primary:disabled
  img {
  filter: invert(0) brightness(100);
}
.sober-living-slider
  .sober-living-slider_item
  .card-btn-container
  .btn.disabled {
  border-color: #c6c6c6 !important;
  background-color: #c6c6c6 !important;
  color: #ffffff !important;
  cursor: not-allowed;
  opacity: 1;
}
.btn-icon-only {
  width: 32px;
  height: 32px;
  min-width: unset;
  padding: 0 !important;
  line-height: 10px !important;
}
.btn-icon-only:hover img {
  filter: brightness(0) invert(1);
}
.sober-living-listing-wrapper .listing-header.ad-activity-wrapper {
  row-gap: 0;
}
.ad-activity-main-wrapper .ad-activity-wrapper span {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #7b7f84;
  display: block;
  padding-bottom: 16px;
  border-bottom: 1px solid #f5f5f5;
  max-width: 315px;
  width: 100%;
}
.ad-activity-main-wrapper .ad-activity-wrapper .activity-title b,
.ad-activity-main-wrapper span.chart-header,
.ad-activity-main-wrapper h6 span {
  font-family: "Proxima Nova Semibold", sans-serif;
}
.ad-activity-main-wrapper .ad-activity-wrapper .activity-title span {
  font-family: "Proxima Nova Regular", sans-serif;
}
.ad-activity-main-wrapper .views {
  margin: 0 0 32px;
  justify-content: start !important;
  column-gap: 24px;
}
.ad-activity-main-wrapper .chart-legend-wrapper {
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid #ededed;
}
.ad-activity-main-wrapper .chart-legend {
  font-family: "Proxima Nova Regular", sans-serif;
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-size: 14px;
  line-height: 16px;
}
.ad-activity-main-wrapper .organic-view-box,
.ad-activity-main-wrapper .sponsored-view-box {
  margin-right: 0;
}
.ad-activity-main-wrapper .chart-footer-wrapper {
  margin-top: 18px;
}
.ad-activity-main-wrapper h6 {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0;
  color: #575b60;
}
.ad-activity-block .btn {
  min-width: 168px;
}
section.back {
  top: 0;
}
.upload-documents.show {
  display: block;
}
.upload-documents .modal-dialog {
  max-width: 395px;
}
.upload-documents-content {
  text-align: left;
}
.upload-documents .upload-documents-content h3 {
  font-family: "Proxima Nova Bold", sans-serif;
}
.upload-documents-content .modal-header img {
  position: absolute;
  top: 18px;
  right: 18px;
}
.upload-documents-content label {
  font-family: "Proxima Nova Semibold", sans-serif;
  line-height: 18px;
  color: #000000;
  opacity: 1;
  margin-bottom: 16px;
}
.upload-documents-content label span {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: hwb(0 0% 100% / 0.5);
  margin-top: 6px;
  font-weight: normal;
}
.upload-documents-content .added-photo-listing {
  display: flex;
  align-items: center;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
  width: 100%;
  overflow: unset;
}
.upload-documents-content .photo-wrp {
  width: unset;
  height: unset;
  background-color: transparent;
  border-radius: 0;
  margin-right: 0;
  margin-bottom: 0;
}
.upload-documents-content .photo-wrp.placeholder .add-pic,
.upload-documents-content .photo-wrp > img {
  width: 70px;
  height: 70px;
  background-color: #fff;
  border: 1px dashed #c5c5c5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  position: relative;
  top: unset;
  left: unset;
  transform: unset;
}
.upload-documents-content .added-photo-listing .photo-wrp:nth-child(3)::after {
  display: none;
}
.upload-documents-content .photo-wrp .delete-pic {
  width: 16px;
  height: 16px;
  top: -8px;
  right: -8px;
}
.upload-documents-content .photo-wrp .delete-pic img {
  width: 8px;
}
.upload-documents-content .photo-wrp .label {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 12px;
  line-height: 14px;
  display: block;
  color: #575b60;
  margin-top: 12px;
  text-align: center;
}
.upload-documents-content textarea.form-control {
  font-family: "Proxima Nova Regular", sans-serif;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: 11px 17px;
  height: 120px;
  overflow-y: auto;
}
.upload-documents-content p {
  font-size: 14px;
  line-height: 17px;
  color: #7d7d7d;
}
.upgrade-plan.upload-documents .modal-footer .modal-btn {
  font-family: "Proxima Nova Bold", sans-serif;
}
.sobar-living-upgrade-plan.show {
  display: block;
}
.sobar-living-upgrade-plan .pro-plan-content .modal-header {
  padding: 24px 30px;
}
.sobar-living-upgrade-plan .pro-plan-content .modal-header h3 {
  font-family: "Proxima Nova Bold", sans-serif;
}
.sobar-living-upgrade-plan .pro-plan-content .modal-header .cancel-btn {
  position: absolute;
  top: 18px;
  right: 18px;
}
.sobar-living-upgrade-plan .pro-plan-content .plan-content {
  padding: 24px 30px 30px;
}
.sobar-living-upgrade-plan .pro-plan-content .plan-info p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #575b60;
}
.sobar-living-upgrade-plan .pro-plan-content .plan-content label {
  font-family: "Proxima Nova Semibold", sans-serif;
}
.sobar-living-upgrade-plan .plan-price-content {
  box-shadow: 0px 6px 10px #00000008;
}
.sobar-living-upgrade-plan .pro-plan-content .plan-content span,
.sobar-living-upgrade-plan .pro-plan-content .plan-content h3 {
  font-family: "Proxima Nova Bold", sans-serif;
  font-weight: unset;
}
.sobar-living-upgrade-plan .pro-plan-content .plan-content h3 span {
  font-family: "Proxima Nova Regular", sans-serif;
}
.sobar-living-upgrade-plan .plan-coupon-content .form-group {
  background: #f5f5f5;
  border-radius: 28px;
  height: 54px;
  padding: 16px 25px;
  width: 100%;
}
.sobar-living-upgrade-plan .plan-coupon-content input {
  font-family: "Proxima Nova Regular", sans-serif;
  width: unset;
  height: unset;
  background-color: transparent;
  padding: 0;
  font-size: 18px;
  line-height: 21px;
  color: #575b60;
}
.sobar-living-upgrade-plan .plan-coupon-content .apply-btn {
  font-family: "Proxima Nova Bold", sans-serif;
  width: unset;
  height: unset;
  background-color: transparent;
  padding: 0;
  border: 0;
  font-size: 18px;
  line-height: 21px;
  color: #ff5f5f;
  font-weight: unset;
  text-transform: uppercase;
}
.sobar-living-upgrade-plan .plan-price-details {
  background: #f5f5f5;
  border-radius: 12px;
  padding: 22px 25px;
  width: 100%;
}
.sobar-living-upgrade-plan .plan-price-details p {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #575b60;
  font-weight: unset;
}
.sobar-living-upgrade-plan
  .plan-price-details
  .detail-text:nth-last-child(2)
  p {
  margin-bottom: 0;
}
.sobar-living-upgrade-plan .plan-price-details .to-pay-content {
  border-top: 1px solid #7070702e;
  padding-top: 16px;
  margin-top: 24px;
}
.sobar-living-upgrade-plan .plan-price-details .to-pay-content p {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: #212121;
  font-weight: unset;
  margin-bottom: 0;
}
.sobar-living-upgrade-plan .modal-footer .btn {
  font-family: "Proxima Nova Semibold", sans-serif;
}
.upload-documents-content .photo-wrp.placeholder .add-pic img {
  width: 36px;
  height: 36px;
  object-fit: contain;
  padding: 9px 7px;
  background-color: #ff5f5f24;
  border-radius: 50%;
}

.top-bar {
  background-color: #ff5f5f;
  padding: 7px 0;
}
.top-bar-content {
  column-gap: 24px;
}
.top-bar-content h2 {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 15px;
  line-height: 17px;
  color: #fff;
  margin-bottom: 0;
}
.top-bar-content .btn {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 15px;
  height: 30px;
  padding: 2px 20px;
}
.home-banner {
  background: linear-gradient(#232330f0, #232330f0),
    url("images/vu-anh-TiVPTYCG_3E-unsplash.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 376px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}
.banner-content {
  max-width: 800px;
  width: 100%;
  margin: auto;
}
.banner-content .banner-menu {
  column-gap: 32px;
}
.banner-content .banner-menu .banner-menu-item .banner-menu-link {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  text-decoration: none;
}
.banner-content .banner-menu .banner-menu-item .banner-menu-link.active {
  color: #ff5f5f;
}
.banner-content .search-wrapper .form-control {
  font-family: "Proxima Nova Regular", sans-serif !important;
  height: 66px;
  box-shadow: 0px 4px 10px #ff5f5f59;
  border: 1px solid #232330;
  border-radius: 10px;
  padding: 20px;
  padding-left: 56px;
}
.banner-content .search-wrapper .search-icon {
  left: 20px;
}
.section-title h2 {
  font-family: "Proxima Nova Extrabold", sans-serif;
  text-align: center;
  font-size: 32px;
  line-height: 36px;
  color: #232330;
  font-weight: unset;
  margin-bottom: 50px;
}
.top-city-sec .slider-wrapper {
  margin: 0 -15px;
}
.top-city-sec .slider-wrapper .slick-slide {
  padding: 15px;
}
.top-city-sec .card {
  margin: 0;
  box-shadow: 0px 8px 15px #0000001a;
  border-radius: 8px;
}
.top-city-sec .card .card-body {
  padding: 15px 20px;
}
.top-city-sec .card .card-body h3 {
  font-family: "Proxima Nova Extrabold", sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #575b60;
  margin-bottom: 0;
  text-transform: capitalize;
}
.upload-documents.treatment-facility .modal-dialog {
  max-width: 436px;
}
.upgrade-plan.treatment-facility .modal-content {
  border: 0px;
  background: #ff5f5f;
}
.treatment-facility .upload-documents-content .modal-header img {
  position: relative;
  top: unset;
  right: unset;
  margin: 0 auto 28px;
}
.treatment-facility .upload-documents-content .modal-header h3 {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 24px;
  line-height: 26px;
  color: #fff;
  text-transform: initial;
}
.upgrade-plan.treatment-facility .modal-footer {
  border-top: 1px solid rgb(255 255 255 / 18%);
  padding: 24px 30px;
}
.upgrade-plan.treatment-facility .modal-footer .btn {
  font-family: "Proxima Nova Semibold", sans-serif;
}
.facility-list-banner {
  border-bottom: 1px solid rgb(112 112 112 / 22%);
  padding-bottom: 44px;
  padding-top: 52px;
}
.facility-list-banner
  .banner-content
  .banner-menu
  .banner-menu-item
  .banner-menu-link {
  color: #575b60;
  font-size: 20px;
  line-height: 22px;
  font-family: "Proxima Nova Semibold", sans-serif;
}
.facility-list-banner
  .banner-content
  .banner-menu
  .banner-menu-item
  .banner-menu-link.active {
  color: #ff5f5f;
}
.facility-list-banner .banner-content .search-wrapper .form-control {
  border: 1px solid #ff5f5f33;
  box-shadow: 0px 4px 7px #ff5f5f33;
}
.facility-list-wrapper {
  padding: 0px 0 40px;
}
.facility-filter_section {
  box-shadow: 0px 3px 6px #00000014;
  position: sticky;
  top: 55px;
  background: #fff;
  z-index: 99;
  margin-bottom: 15px;
}
.filter-wrapper {
  column-gap: 28px;
  padding-top: 24px;
}
.tab-slider {
  display: flex;
  align-items: center;
  width: calc(100% - 158px);
}
.tab-slider .slick-arrow img {
  min-width: 30px;
  height: 30px;
  cursor: pointer;
}
.tab-slider .tab-item {
  padding: 0;
}
.tab-slider .tab-item .tab-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 12px;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: unset;
  color: #575b60;
  text-decoration: none;
  cursor: pointer;
  padding: 0px;
  border-bottom: 2px solid transparent;
  outline: none !important;
}
.tab-slider .tab-item.active .tab-link {
  border-color: #ff5f5f;
  color: #ff5f5f;
}
.tab-slider .tab-item .tab-link img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin: 0 auto;
}
.tab-slider .tab-item.active .tab-link .gray-img {
  display: none;
}
.tab-slider .tab-item .tab-link .active-img {
  display: none;
}
.tab-slider .tab-item.active .tab-link .active-img {
  display: block;
}
.filter-wrapper .btn.btn-filter {
  min-width: 130px;
  height: 52px;
  padding: 14px 24px;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: unset;
  color: #232330;
  border: 1px solid #c4c4cb;
  border-radius: 12px;
  grid-column-gap: 10px;
  column-gap: 10px;
}
.filter-wrapper .btn-filter img {
  width: 18px;
}
.facility-list-item {
  padding: 30px 0;
}
.facility-list-item .img-slider {
  border-radius: 12px;
  overflow: hidden;
}
.facility-list-item .img-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 8;
  background: rgb(240, 240, 240);
  opacity: 0.82;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.facility-list-item .img-slider .slick-arrow.prev-arrow {
  left: 15px;
}
.facility-list-item .img-slider .slick-arrow.next-arrow {
  right: 15px;
}
.facility-list-item .img-slider .slick-arrow::before {
  width: 8px;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
  background-size: contain;
  left: 6px;
  top: 4px;
}
.facility-list-item .img-slider .slick-arrow.slick-prev {
  left: 16px;
}
.facility-list-item .img-slider .slick-arrow.slick-next {
  right: 16px;
}
.facility-list-item .img-slider .slick-dots {
  bottom: 12px;
  z-index: 8;
}
.facility-list-item .img-slider .slick-dots li {
  width: auto;
  height: auto;
  margin: 0 4px;
}
.recommend-facilities-slider .slick-dots ul {
  align-items: center;
}
.recommend-facilities-slider .slick-dots li {
  background: transparent;
  padding: 0px;
  line-height: unset;
  font-size: unset;
  display: flex;
  align-items: center;
}
.recommend-facilities-slider .slick-dots li {
  height: 11px;
  width: 11px;
}
.recommend-facilities-slider > .slick-dots li.small,
.recommend-facilities-slider > .slick-dots li.small button {
  height: 9px;
  width: 9px;
}
.recommend-facilities-slider .slick-dots li button {
  padding: 0px;
  width: 11px;
  height: 11px;
}
.facility-list-item .img-slider .slick-dots li button,
.facility-list-item .img-slider .slick-dots li button::before,
.recommend-facilities-slider .slick-dots li button::before {
  padding: 0;
  width: 11px;
  height: 11px;
  color: #fff !important;
}
.recommend-facilities-slider > .slick-dots li.slick-active button::before {
  color: #00558b !important;
}
.recommend-facilities-slider > .slick-dots li button::before {
  color: #333 !important;
  opacity: 0.5;
}
.facility-list-item .img-slider .slick-dots li button::before,
.recommend-facilities-slider > .slick-dots li button::before {
  line-height: 11px !important;
  font-size: 11px !important;
  opacity: 0.6;
}
.facility-list-item .img-slider .slick-dots li.small button::before,
.recommend-facilities-slider > .slick-dots li.small button::before {
  line-height: 9px !important;
  font-size: 9px !important;
  width: 9px;
  height: 9px;
}
.recommend-facilities-slider > .slick-dots li.slick-active button::before {
  opacity: 1;
}
.recommend-facilities-slider .facility-list-item .magic-dots.slick-dots {
  bottom: 20px;
}
.facility-list-item .img-slider .slick-dots li.slick-active button:before {
  opacity: 1;
}
.facility-list-item .img-slider .facility-img {
  height: 260px;
  width: 100%;
}
.facility-list-item .img-slider .subscription-msg {
  font-family: "Proxima Nova Regular", sans-serif;
  z-index: 8;
  top: 20px;
  bottom: unset;
}
.facility-list-item .card-body {
  padding: 15px 0 0;
}
.facility-list-item .card-heading {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
  margin-bottom: 5px;
}
.facility-list-item .card-heading img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.facility-list-item p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #7b7f84;
  margin-bottom: 12px;
}
.facility-list-item .location {
  font-family: "Proxima Nova Regular", sans-serif;
  display: flex;
  align-items: unset;
  column-gap: 8px;
  height: unset;
  margin-bottom: 0px !important;
  font-size: 14px !important;
  line-height: 24px !important;
}
.facility-list-item .card-heading_wraper {
  margin-bottom: 5px;
}
.facility-list-item .card-heading_wraper .card-heading {
  margin-bottom: 0px;
}
.facility-list-item .card-heading_wraper .location {
  line-height: 20px !important;
}
.facility-list-item .location img {
  margin: 0;
  margin-top: 4px !important;
}
.facility-list-wrapper .btn {
  min-width: 160px;
  height: 48px;
  border-radius: 27px;
}
.no-facilities {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
}
.no-facilities p {
  font-family: "Proxima Nova Extrabold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #575b60;
  margin-bottom: 42px;
}
.filter-modal {
  padding: 0 15px;
  background: rgb(35 35 48 / 75%);
}
.filter-modal.show {
  display: block;
}
.filter-modal .modal-dialog {
  max-width: 1170px;
}
.upgrade-plan.filter-modal .modal-content {
  border: 0px;
}
.filter-modal .modal-header {
  border-bottom: 1px solid #70707024;
  padding: 30px;
}
.filter-modal .modal-header h3 {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 28px;
  line-height: 32px;
  color: #232330;
  text-transform: capitalize;
}
.filter-modal .modal-header img {
  position: absolute;
  top: 24px;
  right: 24px;
}
.filter-modal .filter-content {
  padding: 30px 40px 40px;
  margin: 0px;
  height: calc(100vh - 320px);
  overflow-y: auto;
}
.filter-modal .filter-content::-webkit-scrollbar {
  width: 5px;
}
.filter-modal .filter-content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.filter-modal .filter-content::-webkit-scrollbar-thumb {
  background: #c7c7ce;
  border-radius: 20px;
}
.filter-modal .filter-content .taste-recovery-form.add-sobar-living label {
  color: #232330;
  opacity: 1;
}
.filter-modal
  .filter-content
  .taste-recovery-form.add-sobar-living
  label.custom-control-label {
  color: #575b60;
}
.filter-modal .filter-content .taste-recovery-form .dflex-form {
  padding-bottom: 36px;
  margin-bottom: 28px;
  border-bottom: 1px solid #70707024;
}
.filter-modal .filter-content .taste-recovery-form .view-more-btn .btn {
  letter-spacing: 0;
  height: 38px;
  min-width: 120px;
  text-transform: unset;
  font-size: 14px;
  line-height: 16px;
}
.filter-modal .modal-footer {
  border-top: 1px solid #70707024;
  padding: 26px 40px;
  justify-content: space-between;
}
.filter-modal .modal-footer .modal-btn {
  font-family: "Proxima Nova Semibold", sans-serif;
  flex: unset;
  border: 0px;
  text-transform: unset;
}
.filter-modal .modal-footer .btn {
  font-family: "Proxima Nova Regular", sans-serif;
  padding: 12px 28px;
  height: 48px;
  border-radius: 27px;
  text-transform: capitalize;
}
.filter-modal .modal-footer .modal-btn.confirm-btn {
  font-size: 20px;
  line-height: 22px;
}
.form-view-banner {
  height: 470px;
  padding: 28px 0;
  margin-top: 55px;
  position: relative;
}
.form-view-banner .banner-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.form-view-banner .form-view-back {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000009e;
  border-radius: 50%;
}
.form-view-tabs {
  background: #f5f5f5;
  position: sticky;
  top: 55px;
  z-index: 99;
}
.form-view-tabs .tab-list {
  column-gap: 26px;
}
.form-view-tabs .tab-list .tab-link {
  font-family: "Proxima Nova Regular", sans-serif;
  display: block;
  font-size: 18px;
  line-height: 21px;
  color: #575b60;
  text-decoration: none;
  padding: 14px 7px;
  border-bottom: 2px solid transparent;
}
.form-view-tabs .tab-list .active .tab-link {
  color: #ff5f5f;
  border-color: #ff5f5f;
}
.form-view-content .info-icon {
  padding-top: 24px;
  padding-bottom: 8px;
}
.form-view-content .info-icon img {
  width: 21px;
  height: 21px;
  object-fit: contain;
}
.form-view-content .info-icon .btn {
  height: 42px;
  min-width: 182px;
  justify-content: center;
}
.form-view-top {
  overflow: hidden;
}
.form-view-top .about-content::before {
  content: "";
  width: 1920px;
  height: 2px;
  background-color: #000000;
  opacity: 0.1;
  display: block;
  position: absolute;
  top: 20px;
  left: 32.55%;
  transform: translateX(-32.55%);
}
.facility-content .facility-info h2 {
  font-family: "Proxima Nova Extrabold", sans-serif;
  font-size: 32px;
  line-height: 36px;
  font-weight: unset;
  color: #262626;
  text-transform: uppercase;
  margin-bottom: 20px;
  word-break: break-word;
}
.facility-content .facility-info h2 img {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}
.facility-content .location {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 16px;
  line-height: 17px;
  margin-bottom: 20px;
  height: auto;
}
.facility-content h3 {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 24px;
  line-height: 27px;
  color: #232330;
  margin-top: 42px;
  margin-bottom: 20px;
  text-transform: unset;
  column-gap: 10px;
  word-break: break-word;
}
.facility-content h3 i {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.facility-content .about-content {
  padding: 30px 0;
  position: relative;
}
.form-view-detail .facility-content .about-content.border-left-transform {
  display: none;
}
.facility-content .about-content.border-left-transform::before {
  left: 40%;
  transform: translateX(-40%);
}
.facility-content .about-content p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #020202;
  margin-bottom: 16px;
  max-width: 633px;
  text-align: justify;
  word-break: break-word;
}
.facility-content .about-content p:last-child {
  margin-bottom: 0;
}
.facility-content .facility-info {
  width: calc(100% - 465px);
}
.facility-content .sidebar {
  max-width: 456px;
  flex: 0 0 456px;
  width: 100%;
  padding: 10px 26px;
  margin-right: -26px;
  background-color: #fff;
}
.facility-content .sidebar .card {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  padding: 32px 30px;
  box-shadow: unset;
}
.facility-content .sidebar .card h3 {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 22px;
  line-height: 26px;
  color: #232330;
  margin-bottom: 14px;
  margin-top: 0;
}
.facility-content .sidebar .card p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #7b7f84;
}
.facility-content .sidebar .card .card-actions {
  margin-top: 32px;
  row-gap: 20px;
}
.facility-content .sidebar .card .card-actions .btn {
  font-family: "Proxima Nova Semibold", sans-serif !important;
}
.facility-content .sidebar .card .card-actions .btn,
.facility-content .btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  height: 48px;
  border-radius: 24px;
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 18px;
  line-height: 21px;
  padding: 12px 18px;
  text-transform: capitalize !important;
  outline: none;
  box-shadow: unset;
}
.facility-content .btn:disabled {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  opacity: 0.4;
  color: #fff !important;
}
.facility-content .btn:disabled img {
  filter: invert(0) brightness(100);
}
.facility-content .sidebar .card .card-actions .btn span {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.facility-content .sidebar .card .card-actions .btn span img {
  width: 22px;
  height: 22px;
  object-fit: contain;
}
.facility-content .sidebar .card .card-actions .btn .arrow-icon {
  height: 14px;
  transform: rotate(180deg);
}
.facility-content .sidebar .card .card-actions .btn-primary .arrow-icon,
.facility-content .sidebar .card .card-actions .btn:hover .arrow-icon {
  filter: invert(0) brightness(100);
}
.facility-content .sidebar .card .card-actions .btn-primary-outline:hover img {
  filter: invert(0) brightness(100);
}
.facility-content .sidebar .card .or-divider p {
  margin-bottom: 0;
  padding: 0 14px;
  background-color: #fff;
  display: inline-block;
}
.facility-content .sidebar .card .or-divider {
  position: relative;
  z-index: 9;
  text-align: center;
}
.facility-content .sidebar .card .or-divider::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #707070;
  opacity: 0.22;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}
.form-view-detail .facility-content .sidebar {
  display: none;
}
.facility-content .details-map {
  height: 300px;
}
.facility-content ul {
  column-gap: 10px;
  row-gap: 10px;
}
.facility-content ul li {
  width: auto;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 15px;
  line-height: 17px;
  color: #575b60;
  background: #f7f7f7;
  padding: 6.5px 12px;
  border-radius: 6px;
}
.facility-content ul li.more-button-list {
  background: transparent;
  padding: 0;
}
.facility-content ul li.more-button-list .more-button {
  background: #ff5f5f1a;
  padding: 6.5px 12px;
  border: 0;
  border-radius: 6px;
  font-size: 15px;
  line-height: 17px;
  color: #ff5f5f;
}
.facility-content .btn {
  font-size: 16px;
  line-height: 18px;
}
.form-view-reviews {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 36px;
  margin-top: 44px;
}
.review-block {
  column-gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d7d7db;
  margin-bottom: 40px;
}
.review-block.review-reply-block {
  padding-bottom: 0;
  padding-top: 20px;
  border-bottom: 0;
  border-top: 1px solid #d7d7db;
  margin-bottom: 0;
  margin-top: 20px;
}
.review-block .review-image img {
  min-width: 43px;
  width: 43px;
  height: 43px;
  border-radius: 50%;
}
.review-block .top-info {
  column-gap: 16px;
}
.review-block .review-detail h4 {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #575b60;
  word-break: break-word;
}
.review-block .review-detail p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: #a6a6a6;
  margin-bottom: 0px;
}
.review-block .review-detail .star-rating {
  column-gap: 4px;
  min-width: 76px;
}
.review-block .review-detail .star-rating img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.review-block .review-detail .bottom-info {
  margin-top: 20px;
}
.review-block .review-detail .bottom-info p {
  color: #999da2;
  margin-bottom: 10px;
}
.review-block .review-detail .bottom-info .btn-reply {
  background-color: transparent;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: #232330;
  margin-top: 40px;
}
.reply-block h3 {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #232330;
  margin-bottom: 40px;
  min-width: 200px;
}
.reply-block .form-group {
  gap: 8px;
}
.reply-block .form-group label {
  font-family: "Proxima Nova Regular", sans-serif;
  margin-bottom: 0;
  font-size: 12px !important;
  line-height: 14px;
  color: #232330;
  font-weight: normal !important;
  min-width: 70px;
}
.reply-block .star-rating {
  column-gap: 4px;
}
.reply-block .star-rating img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.reply-block .form-group .form-control {
  font-family: "Proxima Nova Regular", sans-serif;
  border: 1px solid #a6a6a6;
  border-radius: 12px;
  font-size: 14px;
  line-height: 19px;
  color: #232330;
  max-width: 471px;
  width: 100%;
  padding: 12px 20px;
  height: auto;
  margin: 0;
  box-shadow: unset;
}
.with-subscription-plan .form-view-top::before {
  display: none;
}
.facility-content .about-content .about-list li {
  background-color: transparent;
  padding: 0;
}
.facility-content .about-content .about-list .about-link {
  display: flex;
  align-items: center;
  margin: 22px 0;
  text-decoration: none;
  column-gap: 8px;
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #7b7f84;
  cursor: pointer;
}
.facility-content .about-content .about-list .about-link:hover {
  color: #020202;
}
.form-view-content .plan-content span {
  font-family: "Proxima Nova Regular", sans-serif;
}
.form-view-content .plan-content h3 {
  font-family: "Proxima Nova Bold", sans-serif;
}
.facility-content .plan-card ul li b {
  font-family: "Proxima Nova Extrabold", sans-serif;
}
.show-all-photos .btn-default {
  column-gap: 10px;
  background: #ffffff;
  color: #232330;
  border-radius: 20px;
  height: 40px;
  padding: 10px 24px;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 14px;
}
.show-all-photos .btn-default:hover {
  background: #ff5f5f;
  color: #ffffff;
}
.show-all-photos .btn-default:hover img {
  filter: invert(1) brightness(100);
}
.facility-content .plan-card {
  box-shadow: 0px 3px 6px #00000012;
  border: 1px solid #dddddd;
  border-radius: 8px;
}
.facility-content .plan-card h3 {
  font-size: 28px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 24px;
}
.facility-content .plan-card .plan-info {
  column-gap: 70px;
}
.facility-content .plan-card ul {
  row-gap: 20px;
  width: 100%;
}
.facility-content .plan-card ul li {
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  background: transparent;
  padding: 0;
  padding-left: 20px;
}
.exploring-facility p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #7b7f84;
  margin-bottom: 20px;
}
.photo-gallery-modal.show {
  display: block;
}
.photo-gallery-modal .slick-dots {
  bottom: -35px;
}
.photo-gallery-modal .modal-dialog {
  margin: 0;
  max-width: 100%;
  min-height: 100%;
  align-items: unset;
}
.photo-gallery-modal .modal-dialog .modal-content {
  background: #000000;
  border-radius: 0;
  padding: 40px 52px;
  justify-content: center;
}
.gallery-content {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.photo-gallery-modal .modal-header {
  margin-bottom: 40px;
  position: relative;
}
.photo-gallery-modal .modal-header .cancel-btn {
  column-gap: 12px;
  color: #fff;
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 20px;
  line-height: 22px;
  text-decoration: none;
  position: relative;
  left: 0;
}
.photo-gallery-modal .modal-header .cancel-btn img {
  filter: invert(1) brightness(100);
}
.photo-gallery-modal .modal-header .pagination {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 22px;
  line-height: 25px;
  color: #fff;
}
.photo-gallery-modal .gallery-slider {
  padding: 0 64px;
  height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.photo-gallery-modal .gallery-slider .slick-list {
  height: 100% !important;
  display: flex;
  align-items: center;
}
.photo-gallery-modal .gallery-slider .slick-track {
  height: 100%;
}
.photo-gallery-modal .gallery-slider .slick-track {
  display: flex;
  align-items: center;
}
.photo-gallery-modal .gallery-slider .slick-track .slick-slide div {
  height: 100%;
}
.photo-gallery-modal .gallery-slider img {
  width: unset;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
}
.photo-gallery-modal .gallery-slider h3 {
  margin-top: 64px;
  margin-bottom: 0;
  text-align: center;
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 22px;
  line-height: 25px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: unset;
}
.photo-gallery-modal .gallery-slider .slick-arrow {
  width: 48px;
  height: 48px;
  border: 2px solid #ffffffdb;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.photo-gallery-modal .gallery-slider .slick-arrow::before {
  filter: invert(1) brightness(100);
  opacity: 1;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  background-position: center;
  width: 14px;
  height: 14px;
}
.photo-gallery-modal .gallery-slider .slick-arrow.slick-next::before {
  transform: translateY(-50%) rotate(180deg);
}
.photo-gallery-modal .gallery-slider .slick-arrow.slick-prev {
  left: -8px;
}
.photo-gallery-modal .gallery-slider .slick-arrow.slick-next {
  right: -8px;
}
.upgrade-plan.show {
  display: block;
}
.cancel-subscription-modal {
  background: #232330bf;
}
.cancel-subscription-modal .modal-dialog {
  max-width: 470px;
}
.cancel-subscription-modal .modal-dialog h3,
.cancel-subscription-modal.upgrade-plan .modal-footer .modal-btn {
  font-family: "Proxima Nova Bold", sans-serif;
}
.cancel-subscription-modal .upgrade-plan-content .loading-msg {
  font-family: "Proxima Nova Regular", sans-serif;
}
@media (max-width: 1440px) {
  .photo-gallery-modal .gallery-slider h3 {
    margin-top: 30px;
  }
  .photo-gallery-modal .modal-header {
    margin-bottom: 30px;
  }
}
@media (max-width: 1269.98px) {
  .sober-living-slider .sober-living-slider_item .card-btn-container {
    width: 45%;
    justify-content: end;
    row-gap: 7px;
  }
}
@media (max-width: 1199.98px) {
  .new-owner h2 {
    font-size: 36px;
    line-height: 42px;
  }
  .new-owner p {
    font-size: 18px;
    line-height: 23px;
  }
  .listing-sub-header h4 {
    font-size: 24px;
    line-height: 28px;
  }
  .new-owner_content {
    padding: 36px;
  }
  .new-owner_image img {
    max-width: 400px;
    height: 350px;
  }
  .new-owner_wrapper {
    min-height: calc(100vh - 505px);
  }
  .sober-living-listing-wrapper .listing-header h3 {
    font-size: 28px;
    line-height: 32px;
  }
  .taste-recovery-form.add-sobar-living label,
  .mandatory {
    font-size: 20px !important;
    line-height: 23px;
  }
  .subscription-title.sobar-living-subscription-title h3 {
    font-size: 18px;
    line-height: 20px;
  }
  .add-sobar-living .dflex-form .custom-checkbox {
    width: calc(33% - 10.5px);
  }
  .top-city-sec .card .card-img {
    height: 176px;
  }
  .top-city-sec .slider-wrapper {
    margin: 0 -10px;
  }
  .top-city-sec .slider-wrapper .slick-slide {
    padding: 15px 10px;
  }
  .sober-living-slider .sober-living-slider_item .card-btn-container {
    width: 47%;
    justify-content: end;
    row-gap: 7px;
  }
  .photo-gallery-modal .gallery-slider {
    padding: 0 56px;
  }
  .photo-gallery-modal .gallery-slider h3 {
    margin-top: 20px;
  }
  .photo-gallery-modal .modal-header {
    margin-bottom: 20px;
  }
}
@media (max-width: 1024px) {
  .top-city-sec .slider-wrapper {
    margin: 0;
  }
  .facility-content .sidebar {
    padding: 10px 16px;
    margin-right: -16px;
  }
}
@media (max-width: 999px) {
  .top-city-sec .slider-wrapper {
    margin: 0 15px;
  }
}
@media (max-width: 991.98px) {
  .new-owner h2 {
    font-size: 30px;
    line-height: 36px;
  }
  .listing-sub-header h4 {
    font-size: 20px;
    line-height: 24px;
  }
  .new-owner p {
    font-size: 16px;
    line-height: 21px;
  }
  .new-owner_content {
    padding: 32px;
  }
  .new-owner_image img {
    max-width: 350px;
    height: 300px;
    border-radius: 0 8px 8px 0;
  }
  .sober-living-listing-wrapper .listing-header h3 {
    font-size: 24px;
    line-height: 28px;
  }
  .taste-recovery-form.add-sobar-living label,
  .mandatory {
    font-size: 18px !important;
    line-height: 21px;
  }
  .add-sobar-living .dflex-form .custom-checkbox {
    width: calc(50% - 10px);
  }
  .sober-living-slider .sober-living-slider_item .card-img {
    height: 132px;
    width: 180px;
    border-radius: 8px;
  }
  .sober-living-slider .sober-living-slider_item .card-heading {
    font-size: 20px;
    line-height: 24px;
    height: 24px;
    margin-bottom: 16px;
  }
  .sober-living-slider .sober-living-slider_item .location {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 16px;
  }
  .sober-living-slider .sober-living-slider_item .card-price {
    font-size: 14px;
    line-height: 18px;
  }
  .sober-living-slider .sober-living-slider_item .card-body {
    padding: 0 0 0 12px;
  }
  .sober-living-slider .sober-living-slider_item .location img {
    width: 13px;
    height: 13px;
  }
  .sober-living-slider .sober-living-slider_item .card-btn-container {
    position: relative;
    width: 100%;
    justify-content: center;
    bottom: unset;
    right: unset;
  }
  .sober-living-slider .sober-living-slider_item .card-btn-container:empty {
    padding: 0 !important;
    border-top: 0;
  }
  .sober-living-slider .sober-living-slider_item .card-btn-container .btn {
    height: 32px;
    font-size: 13px;
    padding: 4px 16px;
  }
  .sober-living-slider .sober-living-slider_item .card-actions {
    column-gap: 6px;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-actions
    .owner-card-action-button {
    width: 28px;
    height: 28px;
  }
  .sober-living-slider .sober-living-slider_item .card-actions img {
    height: 14px;
  }
  .top-city-sec .slider-wrapper::-webkit-scrollbar {
    height: 5px;
  }
  .top-city-sec .slider-wrapper::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  }
  .top-city-sec .slider-wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }
  .top-city-sec .card {
    min-width: 210px;
  }
  .facility-content .facility-info {
    width: calc(100% - 319px);
  }
  .facility-content .sidebar {
    max-width: 319px;
    flex: 0 0 319px;
  }
}
@media (max-width: 767.98px) {
  .modal {
    z-index: 999999;
  }
  .facility-list-banner {
    padding-top: 28px;
  }
  .form-view-content .info-icon .btn {
    min-width: 160px;
  }
  .top-bar ~ div > .facility-list-banner {
    padding-top: 180px;
  }
  .top-bar ~ .home-page-wrapper {
    padding-top: 140px;
  }
  .authentication .modal-body {
    margin: 36px 0;
  }
  .authentication .modal-title {
    font-size: 22px;
    line-height: 25px;
  }
  .new-owner h2 {
    font-size: 24px;
    line-height: 30px;
    margin-top: 20px;
  }
  .taste-recovery-form {
    padding: 0;
  }
  .sober-living-plan-list .pricing-toggle {
    width: 100%;
  }
  .subscription-wrp.sober-living-subscription-wrp {
    max-width: 100%;
    margin-bottom: 10px;
  }
  .listing.sober-living-listing-wrapper {
    padding-bottom: 70px;
  }
  .subscription-title.sobar-living-subscription-title h3 {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 10px;
    text-align: left;
  }
  .subscription-title.sobar-living-subscription-title span {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 22px;
  }
  .subscription-title.sobar-living-subscription-title p {
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    margin-bottom: 0px;
  }
  .plan-list.sober-living-plan-list {
    row-gap: 25px;
  }
  .sober-living-plan-list .plan-card {
    border: 1px solid #d2d2d2;
    box-shadow: unset;
    padding: 16px 20px 30px;
    border-radius: 8px;
  }
  .top-bar ~ div > .facility-list-banner {
    padding-top: 116px;
  }
  .sober-living-plan-list .plan-card h3 {
    margin-bottom: 14px;
  }
  .sober-living-plan-list .plan-card h3 span {
    font-size: 14px;
    line-height: 16px;
  }
  .sober-living-plan-list .plan-card h4 {
    line-height: 22px;
    padding: 0px;
    width: 100%;
    margin: 0px 0px 20px;
    border-bottom: 0px;
  }
  .sober-living-plan-list .plan-card .btn-primary {
    order: unset;
    margin: 0 0 30px !important;
  }
  .sober-living-plan-list .plan-info ul li {
    font-size: 16px;
    line-height: 20px;
  }
  .new-owner {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    box-shadow: unset;
    border: 0px;
    border-radius: 0;
  }
  .new-owner_content {
    padding: 50px 0 90px;
  }
  .sober-living-listing-wrapper .listing-header {
    padding-top: 30px;
  }
  .sober-living-listing-wrapper .listing-header h3 {
    font-size: 22px;
    line-height: 25px;
  }
  span.step-badge {
    margin-left: 0;
    margin-top: 5px;
    font-size: 14px;
    line-height: 17px;
  }
  .new-owner_image img {
    max-width: 270px;
    height: auto;
    border-radius: 0;
  }
  .new-owner_content .dropdown-menu {
    width: 100%;
    box-shadow: unset;
    border: 0;
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0px auto;
    background: #00000085;
    align-items: center;
    justify-content: center;
  }
  .new-owner_content .dropdown-menu.show {
    display: flex;
  }
  .new-owner_content .dropdown-menu ul {
    border: 1px solid #e6e6e6;
    border-radius: 14px;
    max-width: 250px;
    width: 100%;
    background: #fff;
    text-align: center;
    overflow: hidden;
  }
  .new-owner_content .dropdown-menu li.mobile-title {
    font-size: 18px;
    line-height: 21px;
    color: #232330;
    font-weight: unset;
    padding: 16px 25px;
    border-bottom: 1px solid #7070701f;
  }
  .new-owner_content .dropdown-menu .dropdown-item {
    font-size: 14px;
    line-height: 16px;
  }
  .new-owner_content .dropdown-menu li.mobile-action a {
    background-color: #f4f4f4;
    color: #ff5f5f;
  }
  .taste-recovery-form.add-sobar-living label,
  .mandatory {
    font-size: 16px !important;
    line-height: 19px;
  }
  .taste-recovery-form.add-sobar-living .form-control {
    padding: 0 0 10px;
  }
  .add-sobar-living .added-photo-listing {
    width: 100%;
    display: flex;
    align-items: center;
  }
  span.add-pic {
    display: flex;
  }
  .taste-recovery-form.add-sobar-living label {
    margin-bottom: 10px;
  }
  .add-sobar-living .dflex-form .custom-checkbox {
    width: 100%;
  }
  .add-sobar-living .photo-wrp.placeholder {
    min-width: 95px;
    width: 95px;
    height: 95px;
    margin-right: 10px;
  }
  .add-sobar-living .photo-wrp {
    min-width: 115px;
    width: 115px;
    height: 115px;
    margin-right: 0;
    padding: 10px;
    margin-bottom: 28px;
  }
  .added-photo-listing::-webkit-scrollbar {
    height: 5px;
  }
  .added-photo-listing::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  }
  .added-photo-listing::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }
  .taste-recovery-form.add-sobar-living .form-group {
    margin-top: 0;
    margin-bottom: 28px;
  }
  .taste-recovery-form.add-sobar-living label.small-label {
    font-size: 14px !important;
    line-height: 16px;
    margin-top: 12px;
  }
  .listing-sub-header.form-mobile-tab {
    background: #f5f5f5;
    padding: 18px 25px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 !important;
  }
  .listing-sub-header.form-mobile-tab::after {
    content: "";
    border-left: 5px solid #575b60;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
  .form-mobile-tab-content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    background: #fff;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    padding: 16px 28px 70px;
    height: 100vh;
    overflow-y: auto;
  }
  .form-mobile-tab-content::-webkit-scrollbar {
    width: 5px;
  }
  .form-mobile-tab-content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  }
  .form-mobile-tab-content::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }
  .form-mobile-tab-content.show {
    opacity: 1;
    visibility: visible;
    z-index: 999999;
  }
  .form-mobile-tab-content .header-actions .header-actions-left a img {
    width: 20px;
  }
  .form-mobile-tab-content .header-actions .header-actions-right {
    column-gap: 32px;
  }
  .form-mobile-tab-content .header-actions .header-actions-right .btn {
    height: auto;
    width: auto;
    min-width: auto;
    font-size: 16px;
    line-height: 18px;
    color: #6c6c6c;
    padding: 0;
    border-radius: 0;
    font-weight: unset;
  }
  .form-mobile-tab-content
    .header-actions
    .header-actions-right
    .btn.apply-btn {
    color: #ff5f5f;
  }
  .form-mobile-tab-content .header-title h4 {
    font-size: 22px;
    line-height: 25px;
    font-weight: unset;
    color: #575b60;
    text-transform: uppercase;
  }
  .taste-recovery-form.add-sobar-living .form-mobile-tab-content label,
  .form-mobile-tab-content .mandatory {
    font-size: 18px !important;
    line-height: 21px;
  }
  .taste-recovery-form.add-sobar-living
    .form-mobile-tab-content
    label.small-label {
    font-size: 14px !important;
    line-height: 16px;
  }
  .taste-recovery-form.add-sobar-living
    .form-mobile-tab-content
    .form-group
    > label,
  .form-mobile-tab-content .header-title h4 {
    font-family: "Proxima Nova Extrabold", sans-serif;
  }
  .add-sobar-living .form-mobile-tab-content .dflex-form {
    row-gap: 0;
    padding-bottom: 30px;
  }
  /* .photo-gallery-modal .gallery-slider .slick-arrow.slick-prev,
  .photo-gallery-modal .gallery-slider .slick-arrow.slick-next {
    display: none !important;
  } */
  .add-sobar-living
    .form-mobile-tab-content
    .dflex-form
    .custom-checkbox
    label {
    padding: 0px 31px 24px 0;
    font-size: 16px !important;
    line-height: 18px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 24px;
    font-weight: unset !important;
  }
  .add-sobar-living .form-mobile-tab-content .custom-control-label::before,
  .add-sobar-living .form-mobile-tab-content .custom-control-label::after {
    left: unset !important;
    right: 0;
  }
  .add-sobar-living
    .form-mobile-tab-content
    .custom-radio
    .custom-control-label::before {
    left: 0 !important;
  }
  .add-sobar-living
    .form-mobile-tab-content
    .custom-radio
    .custom-control-label::after {
    left: 5px !important;
  }
  .add-sobar-living
    .form-mobile-tab-content
    .dflex-form
    .custom-checkbox:last-child
    label {
    margin-bottom: 0;
  }
  .sober-living-slider {
    column-gap: 15px;
    width: 100%;
    padding-bottom: 20px;
  }
  .sober-living-slider::-webkit-scrollbar {
    height: 5px;
  }
  .sober-living-slider::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }
  .sober-living-slider::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  }
  .sober-living-slider .sober-living-slider_item .card-img .subscription-msg {
    border-radius: 4px 0px 0px 4px;
    left: auto;
    right: 0;
    font-size: 12px;
    line-height: 14px;
    padding: 6px 12px;
    height: 26px;
  }
  .sober-living-slider .sober-living-slider_item .card {
    padding: 0;
    margin: 0;
    /* overflow: hidden; */
    min-width: 250px;
  }
  .sober-living-slider .sober-living-slider_item .card-img {
    height: 132px;
    min-height: 100%;
    width: 120px;
    border-radius: 12px 0 0 12px;
  }
  .sober-living-slider .sober-living-slider_item .card-img::before {
    content: "";
    width: 100%;
    height: 132px;
    display: block;
    background: #00000036;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .sober-living-slider .sober-living-slider_item .card-actions {
    right: calc(100% - 112px);
    top: 92px;
    position: absolute;
    column-gap: 18px;
  }
  .sober-living-slider .sober-living-slider_item .card-heading .card-actions {
    position: relative;
    top: unset;
    right: unset;
    z-index: 99;
    column-gap: 8px;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-actions
    .owner-card-action-button {
    background-color: transparent;
    width: unset;
    height: unset;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-actions
    .owner-card-action-button
    img {
    height: 16px;
  }
  .sober-living-slider .sober-living-slider_item .card-body {
    padding: 14px 12px;
    width: calc(100% - 120px);
  }
  .sober-living-slider .sober-living-slider_item .card-heading {
    font-size: 12px;
    line-height: 14px;
    height: 14px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: unset;
  }
  .sober-living-slider .sober-living-slider_item .card-heading span {
    width: calc(100% - 28px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-heading
    span.card-actions {
    overflow: unset;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-heading
    .mobile-dropdown-btn {
    border: 0px;
    background-color: transparent;
    padding: 0;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-heading
    .mobile-dropdown {
    display: none;
    position: absolute;
    right: -12px;
    top: 20px;
    background: #fff;
    border-radius: 14px;
    box-shadow: 0px 4px 6px #00000045;
    padding: 23px;
    flex-direction: column;
    row-gap: 15px;
    z-index: 99999;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-heading
    .mobile-dropdown-overlay {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #00000085;
    display: none;
    z-index: 9999;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-heading
    .mobile-dropdown.show {
    display: flex;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-heading
    .mobile-dropdown.show
    + .mobile-dropdown-overlay {
    display: block;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-heading
    .mobile-dropdown
    .btn {
    text-transform: capitalize;
    font-size: 14px;
    min-width: 168px;
    border: 0px;
    border-radius: 0;
    padding: 0;
    height: auto;
  }
  .sober-living-slider .sober-living-slider_item .card-btn-container {
    margin-top: 0;
    column-gap: 14px;
    position: relative;
    right: unset;
    left: unset;
    bottom: unset;
    width: 100%;
    justify-content: center;
    border-top: 1px solid #70707029;
    padding: 15px;
  }
  .sober-living-slider .sober-living-slider_item .card-btn-container .btn {
    border-radius: 6px;
    border: 0;
    background: #ff5f5f1f;
    color: #ff5f5f !important;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    padding: 4px 12px;
    min-width: none;
    width: calc(50% - 7px);
  }
  .sober-living-slider .sober-living-slider_item .card-btn-container .btn img {
    height: 15px;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-btn-container
    .btn:hover {
    background: #ff5f5f;
    color: #fff !important;
  }
  .sober-living-slider .sober-living-slider_item .card-btn-container .btn span {
    display: none;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-btn-container
    .btn.disabled
    img,
  .sober-living-slider
    .sober-living-slider_item
    .card-btn-container
    .btn:hover
    img {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
  }
  .sober-living-listing-wrapper .new-owner_content .dropdown .btn {
    background: #ff5f5f1f;
    color: #ff5f5f !important;
    height: 32px;
    border: 0;
    font-size: 14px;
    padding: 4px 16px;
    font-weight: unset;
  }
  .sober-living-listing-wrapper .new-owner_content .dropdown .btn:hover {
    background: #ff5f5f;
    color: #fff !important;
  }
  .sober-living-listing-wrapper .new-owner_content .dropdown .btn:after {
    font-size: 20px;
    line-height: 10px;
  }
  .upload-documents .modal-dialog {
    max-width: 100%;
    width: 100%;
    align-items: unset;
    min-height: 100%;
  }
  .upload-documents.treatment-facility .modal-dialog {
    width: 90%;
    align-items: center;
  }
  .upload-documents .modal-content {
    min-height: 100%;
    border: 0;
    border-radius: 0;
    justify-content: space-between;
  }
  .upgrade-plan.treatment-facility .modal-content {
    border-radius: 12px;
  }
  .upload-documents-content {
    padding: 24px;
  }
  .upload-documents-content .modal-header img {
    position: relative;
    top: unset;
    right: unset;
  }
  .modal-header-top {
    margin-bottom: 24px;
    column-gap: 20px;
  }
  .modal-header-top a.cancel-btn {
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    text-decoration: none;
    color: #ff5f5f;
  }
  .upload-documents-content h3 {
    font-size: 21px;
    line-height: 25px;
    color: #575b60;
    text-transform: uppercase;
  }
  .upgrade-plan .modal-footer {
    padding: 0px 24px 40px;
    border: 0;
  }
  .upgrade-plan .modal-footer .modal-btn.confirm-btn {
    background-color: #f74a4a;
    display: inline-flex;
    color: #fff;
    height: 54px;
    align-items: center;
    justify-content: center;
    border-radius: 27px;
    box-shadow: 0px 12px 16px rgb(57 58 63 / 32%);
  }
  .sobar-living-upgrade-plan .pro-plan-content .modal-header .cancel-btn {
    position: relative;
    top: unset;
    right: unset;
  }
  .pro-plan-modal.sobar-living-upgrade-plan .modal-dialog {
    max-width: 100%;
  }
  .sobar-living-upgrade-plan .pro-plan-content .modal-header {
    padding: 24px;
  }
  .sobar-living-upgrade-plan .pro-plan-content .plan-content {
    padding: 6px 24px 30px;
  }
  .sobar-living-upgrade-plan .pro-plan-content .plan-info p {
    margin-bottom: 0;
  }
  .pro-plan-modal.upgrade-plan.sobar-living-upgrade-plan .modal-footer {
    padding-top: 28px;
  }
  .pro-plan-modal.upgrade-plan.sobar-living-upgrade-plan .modal-content {
    justify-content: space-between;
  }
  .top-bar {
    padding: 10px 0;
    border-radius: 0 0 20px 20px;
  }
  .top-bar-content {
    row-gap: 10px;
  }
  .top-bar-content h2 {
    line-height: 18px;
    text-align: center;
  }
  .top-bar-content .btn {
    font-size: 16px;
    height: 32px;
  }
  .top-bar-content .btn:hover {
    color: #fff;
  }
  .home-banner {
    background: transparent;
    min-height: unset;
    padding-top: 28px;
  }
  .home-banner .banner-content h2,
  .facility-list-banner .banner-content h2 {
    font-size: 22px;
    line-height: 25px;
    font-weight: unset;
    color: #575b60;
    margin-bottom: 0;
    font-family: "Proxima Nova Extrabold", sans-serif;
    order: 0;
    text-align: left;
  }
  .search-wrapper .form-group {
    order: 2;
  }
  .banner-content .banner-menu {
    column-gap: 30px;
    width: 100%;
    overflow-x: auto;
    flex-flow: nowrap;
    margin: 2rem 0 0.5rem !important;
    order: 1;
  }
  .banner-content .banner-menu::-webkit-scrollbar {
    height: 5px;
  }
  .banner-content .banner-menu::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  }
  .banner-content .banner-menu::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }
  .banner-content .banner-menu .banner-menu-item {
    min-width: max-content;
  }
  .banner-content .banner-menu .banner-menu-item .banner-menu-link {
    font-size: 16px;
    line-height: 18px;
    color: #747679;
    font-family: "Proxima Nova Semibold", sans-serif;
  }
  .banner-content .search-wrapper .form-control {
    height: 52px;
    box-shadow: 0px 6px 10px #00000029;
    border: 0px !important;
    border-radius: 6px;
    padding: 12px 20px;
    padding-left: 40px;
  }
  .banner-content .search-wrapper .search-icon {
    top: unset;
    bottom: 34px;
    width: 18px;
    left: 12px;
  }
  .facility-list-banner .banner-content .search-wrapper .search-icon {
    top: unset;
    bottom: 16px;
    width: 18px;
  }
  .top-city-sec {
    padding: 32px 0 40px;
  }
  .section-title h2 {
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    color: #575b60;
    margin-bottom: 18px;
  }
  .top-city-sec .slider-wrapper {
    column-gap: 12px;
  }
  .top-city-sec .card {
    border-radius: 3px;
    min-width: 180px;
  }
  .facility-list-banner .banner-content .btn-filter {
    background: rgb(255 95 95 / 10%);
    height: 32px;
    padding: 8px 18px;
    grid-column-gap: 12px;
    column-gap: 12px;
    font-size: 14px;
    color: #ff5f5f;
    min-width: unset;
    border-radius: 16px;
    font-family: "Proxima Nova Semibold", sans-serif;
  }
  .facility-list-banner .banner-content .btn-filter img {
    width: 15px;
    height: 15px;
    object-fit: contain;
  }
  .facility-list-banner
    .banner-content
    .banner-menu
    .banner-menu-item
    .banner-menu-link {
    font-family: "Proxima Nova Semibold", sans-serif;
    font-size: 16px;
  }
  .tab-slider {
    width: 100%;
  }
  .tab-slider .slick-arrow img {
    min-width: 28px;
    height: 28px;
  }
  .facility-list-banner {
    padding-bottom: 28px;
  }
  .facility-list-wrapper {
    padding-top: 5px;
    padding-bottom: 60px;
  }
  /* .facility-list-item .img-slider .slick-arrow {
    display: none;
  } */
  .facility-list-item {
    padding: 18px 0;
  }
  .facility-list-item .img-slider .subscription-msg {
    left: auto;
    right: 0;
    border-radius: 4px 0 0 4px;
    padding: 6px 10px;
    height: 26px;
    font-size: 12px;
    line-height: 14px;
  }
  .promote-ad-msg {
    font-size: 12px;
    line-height: 14px;
    width: 45px;
    height: 26px;
  }
  .recommend-facilities-slider .slick-dots li {
    height: 9px;
    width: 9px;
  }
  .recommend-facilities-slider > .slick-dots li.small,
  .recommend-facilities-slider > .slick-dots li.small button {
    height: 7px;
    width: 7px;
  }
  .recommend-facilities-slider .slick-dots li button {
    width: 9px;
    height: 9px;
  }
  .facility-list-item .img-slider .slick-dots li button,
  .facility-list-item .img-slider .slick-dots li button::before,
  .recommend-facilities-slider .slick-dots li button::before {
    width: 9px;
    height: 9px;
  }
  .facility-list-item .img-slider .slick-dots li button::before,
  .recommend-facilities-slider .slick-dots li button::before {
    font-size: 9px !important;
    line-height: 9px !important;
  }
  .facility-list-item .img-slider .slick-dots li.small button::before,
  .recommend-facilities-slider > .slick-dots li.small button::before {
    line-height: 7px !important;
    font-size: 7px !important;
    width: 7px;
    height: 7px;
  }
  .filter-modal {
    padding: 0;
  }
  .filter-modal .modal-dialog {
    width: 100%;
    min-height: 100%;
    align-items: unset;
  }
  .filter-modal .modal-dialog .modal-content {
    min-height: 100%;
    border-radius: 0;
  }
  .filter-modal .modal-header img {
    position: relative;
    top: unset;
    right: unset;
  }
  .filter-right {
    column-gap: 32px;
  }
  .modal-header-top a.cancel-btn {
    color: #6c6c6c;
  }
  .modal-header-top a.apply-btn {
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    text-decoration: none;
    color: #ff5f5f;
  }
  .filter-modal .modal-header {
    border-bottom: 0px;
    padding: 30px 24px 0;
  }
  .filter-modal .modal-header h3 {
    font-size: 24px;
    line-height: 25px;
    text-transform: uppercase;
    color: #575b60;
  }
  .filter-modal .filter-content {
    padding: 32px 9px 0;
    height: calc(100vh - 168px);
    overflow-x: clip;
  }
  .filter-modal .filter-content .row {
    margin: 0;
  }
  .add-sobar-living .custom-radio .custom-control-label::after {
    left: 5px !important;
  }
  .filter-content .form-mobile-tab-content {
    position: relative;
    visibility: visible;
    opacity: 1;
    height: unset;
    padding: 0 15px;
    z-index: 9;
  }
  .filter-modal .filter-content .taste-recovery-form .dflex-form {
    padding-bottom: 30px;
    margin-bottom: 0;
    border-bottom: 0px;
  }
  .add-sobar-living
    .dflex-form
    .custom-checkbox:last-child
    label.custom-control-label {
    margin-bottom: 0;
  }
  .upgrade-plan.cancel-subscription-modal .modal-dialog {
    max-width: 314px;
    width: 100%;
    padding: 0 12px;
  }
  .upgrade-plan.cancel-subscription-modal .modal-footer {
    border-top: 1px solid #70707029;
    padding: 0px;
  }
  .upgrade-plan.cancel-subscription-modal .modal-footer .modal-btn {
    text-transform: capitalize;
  }
  .upgrade-plan.cancel-subscription-modal .modal-footer .modal-btn.confirm-btn {
    background: transparent;
    color: #f74a4a;
    box-shadow: unset;
    height: unset;
    border-radius: unset;
  }
  .photo-gallery-modal.show {
    z-index: 999999;
  }
  .photo-gallery-modal .modal-dialog {
    height: 100%;
  }
  .photo-gallery-modal .modal-dialog .modal-content {
    padding: 0px 0px;
  }
  .photo-gallery-modal .modal-header .cancel-btn {
    position: fixed;
    left: auto;
    top: 20px;
    right: 20px;
  }
  .photo-gallery-modal .modal-header .cancel-btn span {
    display: none;
  }
  .photo-gallery-modal .gallery-slider img {
    max-width: 100%;
    max-height: unset;
    width: 100%;
  }
  .photo-gallery-modal .modal-header {
    margin-bottom: 0px;
  }
  /* .modal-header-top {
    margin-bottom: 0;
  } */
  .photo-gallery-modal .modal-header .pagination {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 20px;
  }
  .photo-gallery-modal .gallery-slider {
    padding: 0px;
  }
  .photo-gallery-modal .gallery-slider .slick-track {
    padding-right: 0;
  }
  .photo-gallery-modal .gallery-slider .slider-item {
    padding-left: 0px;
    outline: none;
  }
  .photo-gallery-modal .gallery-slider h3 {
    margin-top: 20px;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
  }
  .info-menu {
    column-gap: 20px;
  }
  .info-menu img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  .form-view-banner .form-view-back {
    width: 36px;
    height: 36px;
  }
  .form-view-banner .form-view-back img {
    width: 20px;
  }
  .form-view-banner {
    margin-top: 0;
  }
  .form-view-banner .banner-image img {
    z-index: -1;
  }
  .facility-content .facility-info {
    width: 100%;
  }
  .facility-content .sidebar {
    max-width: 100%;
    padding: 0px 10px;
    display: none;
  }
  .form-view-detail .facility-content .sidebar {
    display: block;
  }
  .form-view-detail .facility-content .sidebar .card {
    padding-left: 0;
    padding-right: 0;
  }
  .facility-content .sidebar.show {
    display: flex;
  }
  .facility-content .sidebar .card {
    max-width: 100%;
    width: 100%;
    padding: 50px 24px 30px;
    border: 0px;
  }
  .facility-content .sidebar .card h3 {
    font-family: "Proxima Nova Extrabold", sans-serif;
    font-size: 18px;
    line-height: 21px;
  }
  .facility-content .sidebar .card h3 br {
    display: none;
  }
  .facility-content .sidebar .card .card-header-action {
    position: absolute;
    top: 16px;
    right: 18px;
    border: 0;
    background: transparent;
  }
  .facility-content .sidebar .card .card-header-action img {
    width: 14px;
  }
  .facility-content .sidebar .card p {
    font-size: 14px;
    line-height: 17px;
  }
  .facility-content .sidebar .card .card-actions {
    margin-top: 28px;
  }
  .form-view-tabs-sec {
    border-radius: 37px 37px 0 0;
    background: #fff;
    margin-top: -37px;
    position: relative;
    padding-top: 36px !important;
    padding-bottom: 50px;
  }
  .form-view-top .about-content::before {
    display: none;
  }
  .facility-content .facility-info h2 {
    text-align: center;
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 12px;
    color: #575b60;
  }
  .facility-content .location {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    height: auto;
  }
  .form-view-top .facility-content .location {
    justify-content: center;
  }
  .facility-content .about-content {
    padding-top: 0;
    padding-bottom: 20px;
  }
  #about .facility-content .about-content.border-left-transform {
    display: none;
  }
  .form-view-detail .facility-content .about-content.border-left-transform {
    display: block;
  }
  #about .facility-content h3,
  .form-view-top .facility-content h3 {
    text-align: center;
  }
  .facility-content h3 {
    margin-top: 36px;
    margin-bottom: 14px;
    font-size: 18px;
    line-height: 21px;
    color: #575b60;
  }
  .facility-content h3 i {
    width: 18px;
    height: 18px;
  }
  .facility-content .about-content p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .facility-content .details-map {
    margin-top: 0;
  }
  .facility-content .map-view {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .form-view-detail .facility-content h3 {
    margin-bottom: 22px;
  }
  .facility-content .sidebar .card .card-actions .btn,
  .facility-content .btn {
    height: 40px;
    font-size: 16px;
    line-height: 20px;
  }
  .facility-content ul {
    row-gap: 16px;
  }
  .form-view-detail .facility-content:last-child {
    border-bottom: 0px;
    margin-bottom: 0;
  }
  .form-view-reviews {
    padding-top: 70px;
    position: relative;
    padding-bottom: 40px;
  }
  .form-view-reviews::before {
    content: "";
    width: 100%;
    height: 35px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    background-color: #f5f5f5;
  }
  .form-view-reviews .facility-content h3 {
    margin-bottom: 30px;
  }
  .review-block {
    column-gap: 12px;
  }
  .review-block .review-detail .bottom-info .btn-reply {
    margin-top: 20px;
  }
  .review-block .review-detail .star-rating img,
  .reply-block .star-rating img {
    width: 16px;
    height: 16px;
  }
  .review-block .review-detail .star-rating,
  .reply-block .star-rating {
    column-gap: 2px;
  }
  .show-all-photos {
    padding-bottom: 32px;
  }
}
@media (max-width: 575.98px) {
  .tab-slider {
    overflow-x: auto;
  }
  .tab-slider .tab-item {
    padding: 0 7px;
    min-width: max-content;
    border-bottom: 1px solid #70707038;
  }
  .tab-slider::-webkit-scrollbar {
    height: 1px;
  }
  .tab-slider::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .tab-slider::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .new-owner h2 {
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }
  .new-owner p {
    font-size: 14px;
    line-height: 19px;
  }
  .listing-sub-header h4 {
    font-size: 16px;
    line-height: 20px;
  }
  span.step-badge {
    font-size: 12px;
    line-height: 14px;
  }
  .ad-activity-main-wrapper .views {
    flex-wrap: wrap;
  }
  .ad-activity-main-wrapper .activity-title {
    font-size: 14px;
  }
  .top-bar-content .btn {
    height: auto;
  }
  .top-city-sec .card .card-body {
    padding: 12px;
  }
  .top-city-sec .col-md-12 {
    padding-right: 0;
  }
  .mobile-px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .btn-min-width {
    min-width: unset !important;
  }
  .sober-living-plan-list .plan-card {
    min-width: unset;
  }
  .top-city-sec .card {
    min-width: 144px;
  }
  .top-city-sec .card .card-img {
    height: 160px;
  }
  .top-city-sec .slider-wrapper .slick-slide {
    padding: 15px 6px;
  }
  .filter-wrapper {
    margin: 0 -12px 0px;
    padding-top: 0px;
  }
  .tab-slider .tab-item .tab-link {
    padding: 12px 0px;
  }
  .tab-slider .slick-arrow {
    display: none;
  }
  .facility-list-item p {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin-bottom: 8px;
  }
  .facility-list-item .card-heading {
    font-size: 14px;
    line-height: 16px;
  }
  .facility-list-item .card-heading img {
    width: 16px;
    height: 16px;
  }
  .facility-list-item .location {
    height: auto;
  }
  .form-view-banner .form-view-back {
    width: 32px;
    height: 32px;
  }
  .form-view-banner .form-view-back img {
    width: 16px;
  }
  .info-menu img {
    width: 18px;
    height: 18px;
  }
  .facility-content .map-view {
    margin-left: -15px;
    margin-right: -15px;
  }
  .form-view-detail .facility-content h3 {
    margin-bottom: 15px;
  }
}
@media (max-width: 499.98px) {
  .top-city-sec .card {
    min-width: unset;
  }
  .top-city-sec .card .card-img {
    height: 130px;
  }
  .home-banner .banner-content h2,
  .facility-list-banner .banner-content h2 {
    font-size: 18px;
    line-height: 21px;
    column-gap: 12px;
  }
  .facility-list-item p,
  .facility-list-item .location {
    font-size: 12px !important;
    line-height: 16px !important;
  }
}
@media screen and (max-width: 400.99px) {
  .top-bar ~ .home-page-wrapper {
    padding-top: 156px;
  }
}
@media (max-width: 378.98px) {
  .top-bar ~ div > .facility-list-banner,
  .home-banner {
    padding-top: 28px;
  }
}
@media (max-width: 375.98px) {
  .sober-living-slider .sober-living-slider_item .card-btn-container {
    row-gap: 8px;
  }
  .sober-living-slider .sober-living-slider_item .card-btn-container .btn {
    width: 100%;
  }
  .top-city-sec .card .card-img {
    height: 112px;
  }
}

/* US0020 and US0021 */
.chat-section {
  padding-top: 25px;
}
.chat-section .chat-section_header h2 {
  font-family: "Proxima Nova Extrabold", sans-serif;
  font-size: 32px;
  line-height: 36px;
  font-weight: unset;
  color: #232330;
  text-transform: uppercase;
  margin-bottom: 18px;
}
.chat-box_wrapper {
  border-top: 1px solid #d7d7db;
  border-bottom: 1px solid #d7d7db;
  height: calc(100vh - 220px);
}
.chat-box_left {
  max-width: 397px;
  width: 100%;
  border-right: 1px solid #d7d7db;
  padding: 20px 0;
  padding-right: 54px;
}
.chat-box_wrapper .search-wrapper input {
  font-family: "Proxima Nova Regular", sans-serif !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 10px;
  height: 53px;
  padding-left: 51px;
}
.chat-box_wrapper .search-wrapper input::placeholder {
  color: #b1b1b1;
}
.chat-box_wrapper .search-wrapper .search-icon {
  top: 18px;
  left: 20px;
  display: flex;
}
.chat-box_wrapper .search-wrapper .search-icon img {
  width: 17px;
}
.chat-box_user-list {
  margin-right: -54px;
  padding-right: 54px;
  height: calc(100vh - 332px);
  overflow-y: auto;
}
.chat-box_user-list::-webkit-scrollbar {
  width: 5px;
}
.chat-box_user-list::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.chat-box_user-list::-webkit-scrollbar-thumb {
  background: #c7c7ce;
  border-radius: 20px;
}
.chat-box_user-list .chat-box_user-info,
.chat-box_user-list .chat-box_user-info .user-short_info {
  column-gap: 15px;
}
.chat-box_user-list .chat-box_user-info .user-short_info {
  width: calc(100% - 75px);
}
.chat-box_user-list
  .chat-box_user-info
  .user-short_info
  .user-short_info-right {
  width: calc(100% - 80px);
}
.chat-box_user-list .chat-box_user-info img {
  min-width: 40px;
  height: 40px;
  border-radius: 3px;
  object-fit: cover;
}
.chat-box_user-list .chat-box_user-info h3 {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #575b60;
}
.chat-box_user-list .chat-box_user-info p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #999da2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-box_user-list .messgae-short_info {
  min-width: 50px;
}
.chat-box_user-list .messgae-short_info .messgae-time {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #b9bec4;
}
.chat-box_user-list .messgae-short_info .messgae-count {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 12px;
  line-height: 15px;
  min-width: 19px;
  height: 19px;
  color: #fff;
  background-color: #ff5f5f;
  border-radius: 10px;
  padding: 2px 5.25px;
}
.chat-box_header {
  padding: 12.5px;
  column-gap: 16px;
  border-bottom: 1px solid #d7d7db;
}
.chat-box_header img {
  width: 31px;
  height: 31px;
  object-fit: cover;
  border-radius: 50%;
}
.chat-box_header h3 {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 17px;
  line-height: 19px;
  color: #575b60;
  text-transform: uppercase;
}
.chat-box_body {
  padding: 12px 0;
}
.chat-box_body-inner {
  padding: 8px 16px 8px 44px;
  row-gap: 12px;
  overflow-y: auto;
  height: calc(100vh - 381px);
}
.chat-box_body-inner::-webkit-scrollbar {
  width: 5px;
}
.chat-box_body-inner::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.chat-box_body-inner::-webkit-scrollbar-thumb {
  background: #c7c7ce;
  border-radius: 20px;
}
.chat-box_body-inner .msg-date {
  padding: 5px 12px;
  display: inline-flex;
  margin: 0 auto;
  height: 20px;
  background: #ff5f5f;
  border-radius: 10px;
  color: #fff;
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 11px;
  line-height: 11px;
}
.msg-block_main-wrapper .msg-block_wrapper {
  display: inline-flex;
  flex-direction: column;
  width: max-content;
  margin-bottom: 26px;
}
.msg-block_main-wrapper .msg-block_right {
  align-items: end;
  margin-left: auto;
}
.msg-block_main-wrapper .msg-block_left {
  align-items: start;
  margin-left: 0;
}
.msg-block_main-wrapper .msg-block {
  display: flex;
  column-gap: 10px;
}
.msg-block_main-wrapper .msg-block_content {
  background: #ffffff;
  border-radius: 17px;
  min-height: 28px;
  padding: 16px 18px;
  border: 1px solid #f2f2f2;
  margin-bottom: 12px;
  width: max-content;
}
.msg-block_main-wrapper .msg-block_right .msg-block_content {
  background: #f1f2f3;
  border-color: #f1f2f3;
}
.msg-block_main-wrapper .msg-block_content p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #575b60;
  margin-bottom: 0;
}
.msg-block_main-wrapper .msg-block_content p span {
  font-family: "Proxima Nova Bold", sans-serif;
  color: #ff5f5f;
  padding-left: 8px;
  display: inline-block;
}
.msg-block_main-wrapper .msg-time {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 11px;
  line-height: 11px;
  color: #999da2;
  letter-spacing: 0.39px;
}
.msg-block_main-wrapper .msg-block_left .msg-time {
  display: block;
  text-align: right;
  width: 100%;
  padding-right: 20px;
}
.msg-block_main-wrapper .msg-block_image img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.chat-box_footer {
  padding: 20px 20px 20px 44px;
  border-top: 1px solid #d7d7db;
}
.chat-box_footer input {
  font-family: "Proxima Nova Regular", sans-serif !important;
  padding: 0;
  border: 0;
  box-shadow: unset !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
}
.chat-box_footer input::placeholder {
  color: #999da2;
}
.chat-section .chat-section_footer p {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: unset;
  color: #575b60;
  margin-bottom: 0px;
  margin-top: 36px;
  text-align: center;
}
.survey-modal {
  height: auto;
  bottom: 0;
  top: auto;
}
.survey-modal.show {
  display: block;
}
.survey-modal .modal-dialog {
  max-width: 1197px;
  margin-bottom: 0;
  padding: 0 15px;
}
.survey-modal .modal-content {
  width: 360px;
  margin-left: auto;
  background: #ffffff;
  box-shadow: 0px 4px 8px #00000033;
  border: 1px solid #dedede;
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
}
.survey-modal .survey-modal_header {
  background: #f5f5f5;
  padding: 12px;
  border-bottom: 1px solid #70707029;
  column-gap: 10px;
}
.survey-modal .survey-modal_header img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.survey-modal .survey-modal_header h2 {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: #575b60;
}
.survey-modal .survey-modal_body {
  padding: 18px 14px;
}
.participate-survey {
  background: #fff;
  padding: 20px;
  text-align: center;
  max-width: 300px;
  margin: 0px auto;
}
.participate-survey p {
  margin-bottom: 30px;
}
.participate-survey .survey-action {
  column-gap: 15px;
}
.participate-survey .btn {
  font-family: "Proxima Nova Semibold", sans-serif;
  text-transform: capitalize;
  min-width: 110px;
}
.survey-modal_content .msg-block_main-wrapper {
  margin-right: -14px;
  padding-right: 14px;
  max-height: 300px;
  overflow: auto;
}
.survey-modal_content .msg-block_main-wrapper::-webkit-scrollbar {
  width: 5px;
}
.survey-modal_content .msg-block_main-wrapper::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.survey-modal_content .msg-block_main-wrapper::-webkit-scrollbar-thumb {
  background: #c7c7ce;
  border-radius: 20px;
}
.survey-modal_content .msg-block_main-wrapper .msg-block_left {
  margin-bottom: 16px;
}
.survey-modal_content .msg-block_main-wrapper .msg-block_left .msg-time {
  margin-left: 10px;
}
.survey-modal_content .msg-block_footer {
  padding: 16px 0px;
  margin-top: 30px;
}
.survey-modal_content .msg-block_footer .servey-slider {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.servey-slider .slick-arrow {
  min-width: 27px;
  height: 27px;
  cursor: pointer;
}
.servey-slider .slick-slide {
  padding: 0 6px;
}
.servey-slider .slick-slide .btn {
  height: 32px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  min-width: unset;
}
.survey-modal_content .facility-content {
  padding: 16px 10px;
  border-top: 1px solid #70707029;
  margin: 30px -14px -18px;
}
.survey-modal_content .facility-content .sidebar {
  padding: 14px 4px;
}
.survey-modal_content .facility-content .card {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}
.survey-modal_content .facility-content .card p {
  line-height: 22px;
  color: #575b60;
}
.survey-modal_content .sidebar .card .card-actions {
  margin-top: 20px;
  row-gap: 18px;
}
.survey-modal_content .sidebar .card .card-actions .btn {
  height: 42px;
  font-size: 16px;
  line-height: 18px;
  padding: 8px 18px;
}
.survey-modal_content .sidebar .card .card-actions .btn span img {
  width: 18px;
  height: 18px;
}

.manage-survey_section {
  padding-top: 24px;
}
.manage-survey_header .manage-survey_button .btn {
  font-family: "Proxima Nova Semibold", sans-serif;
  min-width: 120px;
  text-transform: capitalize;
}
.manage-survey_tabs {
  border-bottom: 1px solid #d7d7db;
  grid-column-gap: 20px;
  column-gap: 20px;
  margin-bottom: 36px;
}
.manage-survey_tabs .tab-link {
  padding: 0px 10px 17px;
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: #575b60;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.manage-survey_tabs .active .tab-link {
  font-family: "Proxima Nova Semibold", sans-serif;
  color: #ff5f5f;
}
.manage-survey_tabs .tab-link::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.manage-survey_tabs .active .tab-link::after {
  background-color: #ff5f5f;
}
.manage-survey_question-wrapper {
  row-gap: 25px;
}
.manage-survey_question-container {
  column-gap: 14px;
}
.manage-survey_question-block {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000014;
  border: 1px solid #d7d7db;
  border-radius: 12px;
  padding: 22px 24px 24px;
}
.manage-survey_question h4 {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 24px;
  line-height: 27px;
  color: #232330;
  margin-bottom: 20px;
}
.manage-survey_question p {
  background: #ffffff;
  border: 1px solid #d7d7db;
  border-radius: 8px;
  padding: 16px 20px;
  min-height: 52px;
  display: flex;
  align-items: center;
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #232330;
  margin-bottom: 32px;
}
.manage-survey_question-option .dflex-form .custom-radio label {
  color: #000000;
}
.multiple-choice_answer {
  column-gap: 20px;
}
.multiple-choice_answer .form-group {
  column-gap: 10px;
}
.multiple-choice_answer label {
  width: 40px;
  height: 48px;
  background: #f5f5f5;
  border: 1px solid #d7d7db;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 14px;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: #232330;
}
.multiple-choice_answer input {
  height: 48px;
  background: #ffffff;
  border: 1px solid #d7d7db;
  border-radius: 8px;
  padding: 12px 16px;
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #232330;
}
.multiple-choice_answer input::placeholder {
  color: #23233080;
}
.multiple-choice_answer .btn {
  height: 42px;
  text-transform: capitalize;
  padding: 11px 25px;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 16px;
  line-height: 18px;
  min-width: auto;
}
.manage-survey_question-action .btn-icon-only {
  border: 0;
  background: transparent;
  width: 30px;
  height: 30px;
}
.manage-survey_question-action .btn-icon-only:hover img {
  filter: unset;
}
.updated-successfully-modal .modal-dialog {
  max-width: 270px;
}
.updated-successfully-modal .upgrade-plan-content,
.already-updated-modal .upgrade-plan-content {
  padding: 28px;
}
.updated-successfully-modal .upgrade-plan-content img,
.already-updated-modal .upgrade-plan-content img {
  width: 40px;
  margin-bottom: 22px;
}
.updated-successfully-modal .upgrade-plan-content .loading-msg,
.updated-successfully-modal .modal-footer .confirm-btn,
.already-updated-modal .upgrade-plan-content .loading-msg,
.already-updated-modal .modal-footer .confirm-btn {
  line-height: 19px;
}
.already-updated-modal .modal-dialog {
  max-width: 454px;
}
.choose-facility-modal .upgrade-plan-content {
  padding: 32px 30px;
}
.choose-facility-modal .upgrade-plan-content h3 {
  color: #212121;
}
.selected-facility-wrapper {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  padding: 8px 0;
  padding-right: 28px;
  position: relative;
  margin-bottom: 24px;
  gap: 8px;
}
.selected-facility-wrapper::after {
  content: "";
  display: block;
  border-top: 5px solid #575b60;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  right: 8px;
  transform: rotate(0deg);
}
.selected-facility-wrapper.active::after {
  transform: rotate(180deg);
}
.selected-facility-wrapper .selected-facility-item {
  column-gap: 16px;
  padding: 7px 12px;
  background: #f5f5f5;
  border-radius: 6px;
  height: 32px;
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #575b60;
}
.selected-facility-wrapper .selected-facility-item img {
  height: 10px;
  width: 10px;
  object-fit: contain;
}
.choose-facility-modal-form {
  display: none;
}
.selected-facility-wrapper.active + .choose-facility-modal-form {
  display: block;
}
.choose-facility-modal-form .add-sobar-living .dflex-form {
  row-gap: 16px;
}
.prequalification-modal.show {
  display: block;
}
.prequalification-modal .modal-dialog {
  max-width: 400px;
}
.prequalification-modal .participate-survey {
  padding: 30px;
  max-width: 365px;
  background: transparent;
}
.prequalification-modal .participate-survey p {
  margin-bottom: 24px;
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 16px;
  line-height: 22px;
}
.prequalification-modal .participate-question {
  padding: 30px 28px;
}
.prequalification-modal .cancel-btn {
  position: absolute;
  top: 19px;
  right: 16px;
  width: 13px;
  height: 13px;
  object-fit: contain;
  z-index: 9;
}
.prequalification-modal .back-btn {
  column-gap: 8px;
  text-decoration: none;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #ff5f5f;
}
.prequalification-modal .back-btn img {
  width: 15px;
}
.question-block h2 {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 24px;
  line-height: 25px;
  color: #232330;
  margin-top: 22px;
  margin-bottom: 25px;
}
.question-block p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #575b60;
  margin-bottom: 15px;
}
.question-block .add-sobar-living .dflex-form {
  margin-top: 15px;
  row-gap: 12px;
}
.question-block .add-sobar-living .dflex-form input {
  display: none;
}
.question-block .add-sobar-living .dflex-form label::before {
  display: none;
}
.question-block .add-sobar-living .dflex-form label {
  padding: 0;
  opacity: 1;
}
.question-block .add-sobar-living .dflex-form label .option {
  column-gap: 10px;
}
.question-block .add-sobar-living .dflex-form label .option label {
  min-width: 40px;
  min-height: 48px;
  background: #fff;
  border: 1px solid #ff5f5f;
  border-radius: 8px;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: #ff5f5f;
}
.question-block .add-sobar-living .dflex-form label .option span {
  width: auto;
  min-height: 48px;
  background: #ff5f5f0f;
  border: 1px solid #ff5f5f;
  border-radius: 8px;
  padding: 15px;
  padding-right: 48px;
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #ff5f5f;
}
.question-block .add-sobar-living .dflex-form label::after {
  background: transparent !important;
  width: 16px !important;
  height: 8px !important;
  border: 0;
  border-radius: 0;
  border-left: 2px solid #ff5f5f;
  border-bottom: 2px solid #ff5f5f;
  transform: rotate(-45deg) translateY(-100%);
  right: 17px;
  left: auto !important;
  top: 50%;
  opacity: 0;
  visibility: hidden;
}
.question-block
  .add-sobar-living
  .dflex-form
  input:checked
  ~ label
  .option
  label {
  color: #fff;
  background: #ff5f5f;
}
.question-block .add-sobar-living .dflex-form input:checked ~ label::after {
  opacity: 1;
  visibility: visible;
}
.question-block .btn {
  height: 42px;
  text-transform: capitalize;
  font-size: 15px;
  line-height: 17px;
  margin-top: 30px;
}
.prequalification-modal .sidebar {
  background-color: transparent;
}
.prequalification-modal .sidebar .card {
  padding: 20px 0 10px;
}
.prequalification-modal .sidebar .card p {
  font-size: 18px;
  line-height: 23px;
  color: #575b60;
}

.promote-ad-modal.cancel-subscription-modal .modal-dialog {
  max-width: 464px;
}
.promote-ad-content .dflex-form {
  column-gap: 20px;
}
.promote-ad-content .custom-radio {
  padding: 0;
}
.promote-ad-content .custom-radio input {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.promote-ad-content .custom-radio .custom-control-label {
  font-size: 22px !important;
  line-height: 25px;
  font-weight: unset !important;
  color: #575b60;
  font-family: "Proxima Nova Regular", sans-serif;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  width: 120px;
  height: 60px;
  box-shadow: 0px 3px 10px transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  column-gap: 5px;
}
.promote-ad-content .custom-radio .custom-control-label span {
  font-size: 14px;
  line-height: 16px;
  font-family: "Proxima Nova Regular", sans-serif;
}
.promote-ad-content .custom-radio input:checked + .custom-control-label {
  font-family: "Proxima Nova Bold", sans-serif;
  box-shadow: 0px 3px 10px #ff5f5f33;
  border: 1px solid #ff5f5f;
  background-color: #ff5f5f;
  color: #fff;
}
.promote-ad-content .custom-radio .custom-control-label::after,
.promote-ad-content .custom-radio .custom-control-label::before {
  display: none;
}
.promote-ad-content p,
.promote-ad-content .slidecontainer p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #000000bf;
}
.promote-ad-content .slidecontainer p {
  color: #575b60db;
  margin-bottom: 8px;
}
.promote-ad-content .slidecontainer h5 {
  font-size: 16px;
  line-height: 18px;
  color: #575b60;
  font-family: "Proxima Nova Semibold", sans-serif;
  margin: 20px 0;
}
.promote-ad-content .slidecontainer p span {
  font-size: 14px;
  line-height: 18px;
}
.promote-ad-content .slidecontainer.active p {
  color: #ff5f5f;
}
.slidecontainer {
  width: 100%;
}
.slidecontainer .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 20px;
  background: #d7d7db;
  outline: none;
  margin: 18px 0px;
  padding: 0;
}
.slidecontainer .slider:hover {
  opacity: 1;
}
.slidecontainer .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #ff5f5f;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
}
.slidecontainer .slider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #ff5f5f;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
}
.slidecontainer.active .slider::-webkit-slider-thumb {
  background: #ff5f5f;
}
.slidecontainer.active .slider::-moz-slider-thumb {
  background: #ff5f5f;
}
.promote-ad-modal .cancel-btn {
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
}
.promote-ad-content h4 {
  font-size: 18px;
  line-height: 21px;
  color: #575b60;
  font-family: "Proxima Nova Semibold", sans-serif;
}
.promote-ad-content h4 span {
  font-size: 14px;
  line-height: 16px;
  font-family: "Proxima Nova Regular", sans-serif;
}
.promote-ad-msg {
  font-size: 14px;
  line-height: 16px;
  padding: 6px 16px;
  width: 50px;
  height: 28px;
  background-color: #ff5f5f;
  border-radius: 14px;
  display: inline-block;
  position: absolute;
  right: 16px;
  top: 21px;
  color: #fff;
  z-index: 8;
}
.cancel-subscription-modal.budget-modal .modal-dialog {
  max-width: 547px;
}
.cancel-subscription-modal.budget-modal .upgrade-plan-content .loading-msg {
  font-size: 18px;
  line-height: 25px;
  font-family: "Proxima Nova Semibold", sans-serif;
  color: #000000bf;
}
.updated-successfully-modal.canceled-successfully .modal-dialog {
  max-width: 380px;
}

@media (max-width: 1199.98px) {
  .manage-survey_question-block {
    padding: 20px 22px 22px;
  }
  .multiple-choice_answer {
    column-gap: 16px;
  }
  .multiple-choice_answer label {
    width: 36px;
    padding: 12px;
  }
  .manage-survey_question h4 {
    font-size: 20px;
    line-height: 24px;
  }
  .manage-survey_question p {
    margin-bottom: 25px;
  }
}
@media (max-width: 991.98px) {
  .multiple-choice_answer {
    flex-wrap: wrap;
    row-gap: 16px;
  }
  .multiple-choice_answer .form-group {
    width: calc(50% - 8px);
  }
  .prequalification-modal .sidebar .card p {
    font-size: 16px;
    line-height: 21px;
  }
}
@media (max-width: 767.98px) {
  .manage-survey_header .manage-survey_button .btn {
    position: relative;
    z-index: 999;
    background: transparent !important;
    border: 0px;
    border-radius: 0px;
    color: #ff5f5f !important;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    padding: 0;
    margin: 19px 0;
    min-width: auto;
  }
  .manage-survey_header
    .manage-survey_button
    .btn:not(:disabled):not(.disabled):active {
    color: #ff5f5f !important;
  }
  .manage-survey_tabs {
    margin-top: 50px;
  }
  .choose-facility-modal .upgrade-plan-content {
    padding: 22px 20px;
  }
  .choose-facility-modal .upgrade-plan-content h3 {
    margin-bottom: 22px;
  }
  .choose-facility-modal-form .add-sobar-living .dflex-form {
    row-gap: 15px;
  }
  .choose-facility-modal .add-sobar-living .dflex-form .custom-checkbox {
    border-top: 1px solid #7070701f;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px) !important;
    padding: 15px 22px 0;
  }
  .choose-facility-modal .add-sobar-living .dflex-form .custom-checkbox label {
    margin-bottom: 0;
    font-size: 14px;
  }
  .selected-facility-wrapper .selected-facility-item {
    column-gap: 12px;
    padding: 5px 10px;
    height: 28px;
    font-size: 14px;
    line-height: 16px;
  }
  .selected-facility-wrapper .selected-facility-item img {
    height: 8px;
    width: 8px;
  }
  .upgrade-plan.cancel-subscription-modal .modal-footer .modal-btn {
    font-size: 14px;
  }
  .updated-successfully-modal .modal-footer .confirm-btn,
  .already-updated-modal .modal-footer .confirm-btn {
    height: 50px !important;
  }
  .updated-successfully-modal .upgrade-plan-content img,
  .already-updated-modal .upgrade-plan-content img {
    width: 30px;
    margin-bottom: 18px;
  }
  .already-updated-modal .modal-dialog br {
    display: none;
  }
  .multiple-choice_answer .form-group {
    width: 100%;
    row-gap: 10px;
  }
  .manage-survey_question-block {
    padding: 17px;
  }
  .manage-survey_question h4 {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 18px;
  }
  .manage-survey_question p {
    font-size: 14px;
    line-height: 17px;
    padding: 15px 16px;
    min-height: unset;
    margin-bottom: 17px;
  }
  .multiple-choice_answer label {
    width: 40px;
    height: 42px;
    padding: 14px;
  }
  .multiple-choice_answer input {
    height: 42px;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 17px;
  }
  .multiple-choice_answer,
  .manage-survey_question-wrapper .add-sobar-living .dflex-form {
    row-gap: 10px;
  }
  .multiple-choice_answer .btn {
    width: 100%;
    margin-top: 10px;
    background: transparent;
    color: #ff5f5f !important;
  }
  .multiple-choice_answer .btn:hover {
    background-color: #ff5f5f;
    color: #fff !important;
  }
  .manage-survey_question-action {
    display: none;
  }
  .manage-survey_question-wrapper {
    row-gap: 20px;
  }
  .manage-survey_section {
    padding-bottom: 50px;
    padding-top: 0 !important;
  }
  .manage-survey_question-wrapper .btn.btn-default {
    font-family: "Proxima Nova Semibold", sans-serif;
    width: 80px;
    border-color: #d7d7db;
    background: transparent;
    color: #575b60;
    border-radius: 20px;
    min-width: unset;
    font-size: 14px;
    line-height: 16px;
    height: 38px;
    column-gap: 7px;
  }
  .manage-survey_question-wrapper .btn.btn-default span {
    font-size: 20px;
  }
  .question-block .btn {
    height: 36px;
    font-size: 14px;
    line-height: 16px;
    margin-top: 20px;
  }
  .prequalification-modal .sidebar .card {
    padding: 10px 0 0px;
  }
  .question-block .add-sobar-living .dflex-form label .option label {
    min-width: 36px;
    min-height: 100%;
    font-size: 16px;
    line-height: 18px;
    color: #ff5f5f;
  }
  .question-block .add-sobar-living .dflex-form label .option span {
    min-height: 42px;
    border-radius: 6px;
    padding: 12px;
    padding-right: 48px;
    font-size: 14px;
    line-height: 16px;
  }
  .question-block .add-sobar-living .dflex-form label::after {
    right: 12px;
  }
  .prequalification-modal .participate-survey {
    padding: 24px 22px;
  }
  .prequalification-modal .modal-dialog {
    max-width: 364px !important;
  }
  .participate-survey .survey-action,
  .question-btns {
    column-gap: 0;
    border-top: 1px solid #70707029;
    margin-left: -22px;
    margin-right: -22px;
    margin-bottom: -22px;
    padding: 15px 22px;
  }
  .participate-survey .survey-action .btn,
  .question-btns .btn {
    width: 50%;
    background: transparent !important;
    border: 0;
    border-radius: 0;
    color: #6c6c6c !important;
    text-transform: uppercase;
    height: auto;
    padding: 1px 0;
    margin-top: 0;
  }
  .participate-survey .survey-action .btn-primary,
  .question-btns .btn-primary,
  .question-btns .btn-primary:not(:disabled):not(.disabled):active {
    border-left: 1px solid #70707029;
    color: #ff5f5f !important;
    background: transparent !important;
  }
  .question-btns .btn-primary:disabled {
    background-color: transparent !important;
    border-left: 1px solid #70707029;
    border-color: transparent !important;
    color: #ff5f5f !important;
  }
  .prequalification-modal .participate-survey p {
    margin: 18px 0 40px;
  }
  .prequalification-modal .participate-question {
    padding: 24px 22px;
  }
  .question-btns {
    margin-top: 28px;
  }
}
@media (max-width: 575.98px) {
  .prequalification-modal .sidebar .card p {
    font-size: 14px;
    line-height: 18px;
  }
  .promote-ad-content .custom-radio .custom-control-label {
    font-size: 18px !important;
    line-height: 22px;
    width: 80px;
    height: 50px;
  }
  .promote-ad-content .dflex-form {
    gap: 12px;
    flex-wrap: wrap;
  }
  .promote-ad-modal.cancel-subscription-modal .modal-dialog {
    max-width: 100%;
  }
  .promote-ad-modal .cancel-btn {
    position: absolute;
    right: 12px;
    top: 12px;
  }
  .promote-ad-modal .cancel-btn img {
    width: 12px;
  }
}

/* US0017 */
.unclaimed-facilities .disabled {
  cursor: not-allowed !important;
  opacity: 1;
}
.unclaimed-facilities .new-owner_content .dropdown .btn {
  background-color: #c6c6c6;
  border-color: #c6c6c6;
  color: #ffffff !important;
}
.unclaimed-facilities
  .sober-living-slider
  .sober-living-slider_item
  .card-actions
  button.disabled {
  background: rgb(198 198 198 / 10%);
}
.facility-content .claim-facility-btn .btn {
  min-width: 174px;
  width: max-content;
}
.already-updated-modal.updated-successfully-modal .upgrade-plan-content {
  padding: 28px 16px;
}
.facility-content h3 img {
  width: 28px;
  height: 28px;
  object-fit: contain;
}
@media (max-width: 767.98px) {
  .facility-content .claim-facility-btn {
    width: 100%;
    text-align: center;
  }
  .facility-content .claim-facility-btn .btn {
    margin: 8px auto 0px !important;
  }
  .property-top .property-top-left.detailpagetitle {
    width: auto;
  }
  .facility-content h3 img {
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 575.98px) {
  .facility-content h3 img {
    width: 20px;
    height: 20px;
  }
}

/* US0018 */
.setting-section h3.main-title {
  font-family: "Proxima Nova Extrabold", sans-serif;
  color: #232330;
  margin-bottom: 20px;
}
.setting-section .setting-main-wrapper {
  column-gap: 80px;
}
.setting-section .mobile-display-toggle {
  max-width: 286px;
  flex: 0 0 286px;
}
.setting-content {
  max-width: calc(100% - 366px);
  flex: 0 0 calc(100% - 366px);
}
.setting-section .sidebar-wrapper ul.navbar-nav li a {
  padding: 20px 0;
  font-family: "Proxima Nova Semibold", sans-serif;
  color: #575b60;
}
.setting-section .sidebar-wrapper ul.navbar-nav li a img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 16px;
}
.setting-section .sidebar-wrapper ul.navbar-nav li a img.right-arrow {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.setting-section .section-break-div {
  height: 15px;
  background: #f5f5f5;
}
.setting-section .setting-content .setting-back {
  display: block;
}
.setting-section .taste-recovery-form .form-group {
  max-width: 323px;
  width: 100%;
}
.setting-section .taste-recovery-form label {
  padding-right: 0;
  opacity: 1;
  font-family: "Proxima Nova Semibold", sans-serif;
  margin-bottom: 11px;
}
.setting-section .edit-profile-view.taste-recovery-form .form-control {
  padding: 0 0 16px;
}
.setting-section
  .edit-profile-view.taste-recovery-form
  .form-control::placeholder {
  color: #999da2;
}
.setting-section .change-password {
  padding: 0 0 16px;
  border-bottom: 1px solid #ededed;
}
.setting-section .settings-content .btn {
  margin-top: 34px;
  max-width: 323px;
  width: 100%;
  height: 54px;
  border-radius: 27px;
  box-shadow: 0px 12px 16px rgb(57 58 63 / 32%);
  font-size: 18px;
  line-height: 21px;
  column-gap: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-section .settings-content .btn img {
  width: 18px;
}
/* .settings-content .facility-list-item . .slick-arrow {
  display: none;
} */
.settings-content .step-wrapper .step-content .step-info {
  max-width: 75%;
}
.settings-content .step-wrapper .step-content.step1 .step-image::after {
  width: 150px;
  height: 171px;
  right: -18px;
  top: calc(100% - 30px);
}
.settings-content .step-wrapper .step-content.step2 .step-image::after {
  width: 60px;
  height: 105px;
  left: calc(50% - 38px);
}
.settings-content
  .questionnaire-content
  .step-wrapper
  .step-content.step1
  .step-image::after {
  right: 3px;
  top: calc(100% - 42px);
}
@media (max-width: 1269.98px) {
  .settings-content .step-wrapper .step-content.step2 .step-image::after {
    left: calc(50%);
  }
  .settings-content
    .questionnaire-content
    .step-wrapper
    .step-content.step1
    .step-image::after {
    right: 0px;
    top: calc(100% - 37px);
  }
  .setting-section .mobile-display-toggle {
    max-width: 250px;
    flex: 0 0 250px;
  }
}
@media (max-width: 1199.98px) {
  .settings-content .step-wrapper .step-content.step1 .step-image::after {
    width: 130px;
    height: 148px;
    top: calc(100% - 22px);
  }
  .settings-content .step-wrapper .step-content.step2 .step-image::after {
    left: calc(75% - 30px);
  }
  .settings-content
    .questionnaire-content
    .step-wrapper
    .step-content.step1
    .step-image::after {
    right: 0px;
    top: calc(100% - 27px);
    width: 110px;
    height: 125px;
  }
  .settings-content .help-support-section .manage-survey_tabs {
    column-gap: 16px;
  }
  .setting-section .mobile-display-toggle {
    max-width: 200px;
    flex: 0 0 200px;
  }
}
@media (max-width: 991.98px) {
  .settings-content .step-wrapper .step-content.step1 .step-image::after {
    display: none;
  }
  .settings-content .step-wrapper .step-content.step2 .step-image::after {
    display: none;
  }
  .settings-content .help-support-section .manage-survey_tabs {
    box-shadow: 0px 3px 6px #00000014;
    border-bottom: 0;
    column-gap: 0;
    overflow-x: auto;
  }
  .settings-content
    .help-support-section
    .manage-survey_tabs::-webkit-scrollbar {
    height: 1px;
  }
  .settings-content
    .help-support-section
    .manage-survey_tabs::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  .settings-content
    .help-support-section
    .manage-survey_tabs::-webkit-scrollbar-thumb {
    background: #00000014;
    border-radius: 20px;
  }
  .settings-content .help-support-section .manage-survey_tabs .tab-link {
    padding-left: 20px;
    padding-right: 20px;
    width: max-content;
    font-size: 16px;
  }
  .settings-content .manage-survey_tabs .tab-link::after {
    height: 0;
  }
  .settings-content .help-support-content .plan-pricing-tab {
    border-bottom: 1px solid hsl(0deg 0% 44% / 22%);
    column-gap: 0;
    overflow-x: auto;
    padding-bottom: 0 !important;
  }
  .settings-content .help-support-content .plan-pricing-tab::-webkit-scrollbar {
    height: 1px;
  }
  .settings-content
    .help-support-content
    .plan-pricing-tab::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  .settings-content
    .help-support-content
    .plan-pricing-tab::-webkit-scrollbar-thumb {
    background: hsl(0deg 0% 44% / 22%);
    border-radius: 20px;
  }
  .settings-content .help-support-content .plan-pricing-tab li a {
    border: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    padding: 12.5px 17.5px;
    font-size: 12px;
    line-height: 14px;
    color: #575b60;
    width: max-content;
  }
  .settings-content .help-support-content .plan-pricing-tab li.active a {
    background-color: transparent;
    color: #ff5f5f;
    border-color: #ff5f5f;
  }
}
@media (max-width: 767.98px) {
  .setting-section .sidebar-settings {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    padding-top: 40px;
    z-index: 99;
  }
  .setting-section .sidebar-settings h3 {
    padding: 0 20px;
  }
  .setting-section .sidebar-wrapper {
    position: relative;
    top: unset;
    height: calc(100vh - 135px);
    padding-bottom: 100px;
  }
  .setting-section .mobile-display-toggle,
  .setting-content {
    max-width: unset;
    flex: unset;
  }
  .setting-content {
    width: 100%;
  }
  .setting-section .setting-main-wrapper {
    column-gap: 0;
  }
  .setting-section .taste-recovery-form .form-group {
    max-width: 100%;
  }
  .setting-section .settings-content .btn img {
    display: block;
  }
  .chat-section .manage-survey_tabs {
    z-index: 99;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
  }
  section.back.chat-back {
    width: auto;
  }
  .manage-survey_tabs .tab-link {
    padding: 12px 0;
    font-size: 16px;
    line-height: 20px;
  }
  .settings-content .help-support-section .manage-survey_tabs {
    overflow-x: auto;
    justify-content: unset;
    padding: 0;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 36px;
  }
  .settings-content .help-support-content .plan-pricing-tab {
    margin-left: 0px;
    margin-right: 0px;
  }
  .help-support-content {
    margin-left: 0px;
    margin-right: 0px;
  }
}

/* US0024 */
.help-support-section .manage-survey_tabs {
  column-gap: 40px;
}
.help-support-section .manage-survey_tabs .tab-link {
  padding-left: 0;
  padding-right: 0;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
}
.help-support-content .plan-pricing-tab {
  column-gap: 10px;
}
.help-support-content .plan-pricing-tab li a {
  background: #fff;
  border: 1px solid #ff5f5f;
  border-radius: 19px;
  padding: 11px 22px;
  text-decoration: none;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #ff5f5f;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  cursor: pointer;
}
.help-support-content .plan-pricing-tab li.active a {
  background: #ff5f5f;
  color: #fff;
}
.step-wrapper .step-content {
  column-gap: 25px;
  position: relative;
  padding-bottom: 80px;
}
.step-wrapper .step-content:last-child {
  padding-bottom: 0px;
}
.step-wrapper .step-content::before {
  content: "";
  width: 1px;
  height: calc(100% - 80px);
  background: #ff5f5f;
  opacity: 0.3;
  position: absolute;
  left: 30px;
  top: 74px;
}
.step-wrapper .step-content:last-child:before {
  display: none;
}
.step-wrapper .step-content .step-no {
  min-width: 60px;
  width: 60px;
  height: 60px;
  background: rgb(255 95 95 / 10%);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 32px;
  line-height: 37px;
  color: #ff5f5f;
  margin-top: 4px;
}
.step-wrapper .step-content .step-name {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: #232330;
  margin-bottom: 12px;
}
.step-wrapper .step-content .step-info {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #232330;
  margin-bottom: 40px;
  max-width: 80%;
  text-align: justify;
}
.step-wrapper .step-content.step3 .step-info {
  max-width: 95%;
}
.step-wrapper .step-content .step-image {
  position: relative;
}
.step-wrapper .step-content.step1 .step-image::after {
  content: "";
  width: 195px;
  height: 222px;
  background-image: url(../assets/images/arrow1.png);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: -15px;
  top: calc(100% - 52px);
}
.step-wrapper .step-content.step2 .step-image::after {
  content: "";
  width: 70px;
  height: 122px;
  background-image: url(../assets/images/arrow2.png);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: calc(50% - 220px);
  top: calc(100% - 52px);
}
.get-in-touch-section {
  border-top: 1px solid rgb(112 112 112 / 22%);
  margin-top: 36px;
  padding: 90px 0 10px;
}
.get-in-touch-content p {
  font-family: "Proxima Nova Extrabold", sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: #232330;
  margin-bottom: 36px;
  width: 100%;
  text-align: center;
}
.get-in-touch-content .btn {
  font-family: "Proxima Nova Semibold", sans-serif;
  height: 48px;
  width: 142px;
  text-transform: unset;
  border-radius: 24px;
}
.questionnaire-content .step-wrapper .step-content.step1 {
  padding-bottom: 65px;
}
.questionnaire-content .step-wrapper .step-content.step1 .step-image::after {
  right: 14px;
  top: calc(100% - 65px);
}
.questionnaire-content .step-wrapper .step-content.step2 .step-image::after {
  width: 67px;
  height: 117px;
  background-image: url(../assets/images/questionnaire-arrow2.png);
  left: 155px;
  top: calc(100% - 72px);
}
.faq-content .faq-content-block {
  text-align: justify;
}
.facility-list-wrapper .faq-content .faq-content-block h1 {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 28px;
  line-height: 40px;
  color: #575b60;
  text-transform: unset;
}
.facility-list-wrapper .faq-content .faq-content-block h2 {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 22px;
  line-height: 30px;
  color: #575b60;
  text-transform: unset;
}
.facility-list-wrapper .faq-content .faq-content-block p {
  margin-bottom: 8px;
}
.faq-content .faq-content-block h2 {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 32px;
  line-height: 40px;
  color: #232330;
  text-transform: unset;
  text-align: left;
}
.faq-content .faq-content-block h3 {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: #232330;
  margin-bottom: 16px;
  text-transform: unset;
  text-align: left;
}
.faq-content .faq-content-block p {
  font-family: "Proxima Nova Regular", sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #232330;
  margin-bottom: 40px;
}
.faq-content .faq-content-block ul.list-style li {
  list-style: disc;
}
@media screen and (max-width: 1199px) {
  .step-wrapper .step-content.step1 .step-image::after {
    width: 150px;
    height: 171px;
    right: -10px;
    top: calc(100% - 39px);
  }
  .step-wrapper .step-content.step2 .step-image::after {
    width: 60px;
    height: 105px;
    left: calc(50% - 115px);
  }
  .questionnaire-content .step-wrapper .step-content.step1 .step-image::after {
    right: 14px;
    top: calc(100% - 52px);
  }
}
@media screen and (max-width: 991px) {
  .help-support-section .manage-survey_tabs {
    column-gap: 24px;
  }
  .help-support-section .manage-survey_tabs .tab-link {
    font-size: 14px;
  }
  .step-wrapper .step-content .step-info {
    max-width: 65%;
  }
  .step-wrapper .step-content .step-info br {
    display: none;
  }
  .get-in-touch-section {
    padding: 70px 0px 0px;
  }
  .step-wrapper .step-content.step1 .step-image::after {
    right: -17px;
    top: calc(100% - 28px);
  }
  .step-wrapper .step-content.step2 .step-image::after {
    left: calc(50% + 5px);
  }
  .questionnaire-content .step-wrapper .step-content.step1 .step-image::after {
    right: 0px;
    top: calc(100% - 37px);
  }
}
@media screen and (max-width: 767px) {
  .faq-content .faq-content-block p {
    text-align: justify;
  }
  .help-support-section .manage-survey_tabs {
    box-shadow: 0px 3px 6px #00000014;
    border-bottom: 0;
    column-gap: 0;
    overflow-x: auto;
  }
  .help-support-section .manage-survey_tabs::-webkit-scrollbar {
    height: 1px;
  }
  .help-support-section .manage-survey_tabs::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  .help-support-section .manage-survey_tabs::-webkit-scrollbar-thumb {
    background: #00000014;
    border-radius: 20px;
  }
  .help-support-section .manage-survey_tabs .tab-link {
    padding-left: 20px;
    padding-right: 20px;
    width: max-content;
    font-size: 16px;
  }
  .manage-survey_tabs .tab-link::after {
    height: 0;
  }
  .help-support-content .plan-pricing-tab {
    border-bottom: 1px solid hsl(0deg 0% 44% / 22%);
    column-gap: 0;
    overflow-x: auto;
    padding-bottom: 0 !important;
  }
  .help-support-content .plan-pricing-tab::-webkit-scrollbar {
    height: 1px;
  }
  .help-support-content .plan-pricing-tab::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  .help-support-content .plan-pricing-tab::-webkit-scrollbar-thumb {
    background: hsl(0deg 0% 44% / 22%);
    border-radius: 20px;
  }
  .help-support-content .plan-pricing-tab li a {
    border: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    padding: 12.5px 17.5px;
    font-size: 12px;
    line-height: 14px;
    color: #575b60;
    width: max-content;
  }
  .help-support-content .plan-pricing-tab li.active a {
    background-color: transparent;
    color: #ff5f5f;
    border-color: #ff5f5f;
  }
  .help-support-content .sober-living-plan-list .plan-card {
    margin: 0 10px;
  }
  .get-in-touch-section {
    padding: 0px;
    border-top: 0px;
    margin-top: 0;
  }
  .step-wrapper .step-content.step1 .step-image::after,
  .step-wrapper .step-content.step2 .step-image::after {
    display: none;
  }
  .step-wrapper .step-content .step-no {
    min-width: 40px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 22px;
  }
  .step-wrapper .step-content {
    column-gap: 14px;
    padding-bottom: 50px;
  }
  .step-wrapper .step-content .step-name {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 10px;
  }
  .step-wrapper .step-content .step-info {
    margin-bottom: 30px;
    max-width: 100% !important;
  }
  .step-wrapper .step-content::before {
    height: calc(100% - 46px);
    left: 20px;
    top: 46px;
  }
  .get-in-touch-content p {
    font-size: 16px;
    margin-bottom: 26px;
  }
  .faq-content .faq-content-block h3 {
    font-family: "Proxima Nova Bold", sans-serif;
    font-size: 18px;
  }
  .faq-content .faq-content-block p {
    margin-bottom: 32px;
    color: #7b7f84;
    line-height: 19px;
  }
}
@media screen and (max-width: 575px) {
  .help-support-section .manage-survey_tabs,
  .help-support-content .plan-pricing-tab {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* US0026 */
.owner-dashboard-wrapper {
  column-gap: 24px;
}
.owner-dashboard-wrapper .dashboard-filter {
  max-width: 364px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 6px #0000000f;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  padding: 26px 20px;
}
.dashboard-filter .taste-recovery-form label {
  font-weight: unset !important;
  line-height: 18px;
  opacity: 1;
  font-family: "Proxima Nova Semibold", sans-serif;
  margin-bottom: 0px !important;
}
.dashboard-filter .taste-recovery-form .form-control {
  font-size: 14px;
  line-height: 16px;
  padding: 11px 0 17px;
  color: #575b60;
  height: 44px;
}
.dashboard-filter .filter-action {
  border-top: 1px solid #ededed;
  padding: 30px 20px 0px;
  margin-left: -20px;
  margin-right: -20px;
}
.dashboard-filter .form-group-wrapper {
  height: calc(100vh - 225px);
  overflow-y: auto;
  padding-right: 20px;
  margin-right: -20px;
}
.dashboard-filter .form-group-wrapper::-webkit-scrollbar {
  width: 5px;
}
.dashboard-filter .form-group-wrapper::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.dashboard-filter .form-group-wrapper::-webkit-scrollbar-thumb {
  background: #c7c7ce;
  border-radius: 20px;
}
.dashboard-filter .taste-recovery-form .form-select input {
  margin: 0;
  box-shadow: unset;
}
.dashboard-filter .taste-recovery-form .form-select .dropdown-label {
  height: auto !important;
}
.dashboard-filter .selected-filters-wrapper h3 {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #232330;
  text-transform: none;
  margin-bottom: 18px;
}
.dashboard-filter .selected-filters-wrapper h4 {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: #232330;
  text-transform: none;
  margin-bottom: 12px;
}
.selected-filters-wrapper .selected-filters {
  column-gap: 10px;
  row-gap: 10px;
  margin-bottom: 18px;
}
.selected-filters-wrapper .selected-filters .btn {
  height: 28px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  min-width: unset;
  padding: 4px 14px;
}
.dashboard-filter .taste-recovery-form .form-select > div {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0;
  border-color: #ededed;
}
.dashboard-filter .taste-recovery-form .form-select > div > div {
  padding-left: 0;
}
.selected-filters-wrapper .selected-filters .chip {
  background: #ff5f5f1a;
  border-radius: 4px;
  padding: 4px 8px;
  min-height: 28px;
  font-size: 14px;
  line-height: 19px;
  color: #ff5f5f;
  column-gap: 11px;
}
.selected-filters-wrapper .selected-filters .chip span {
  word-break: break-word;
}
.selected-filters-wrapper .selected-filters .chip img {
  width: 7px;
  height: 7px;
  object-fit: contain;
  display: block;
}
.dashboard-content-wrapper {
  max-width: calc(100% - 388px);
}
.dashboard-content {
  row-gap: 22px;
  height: calc(100vh - 94px);
  overflow-y: auto;
  padding-right: 20px;
  margin-right: -20px;
}
.dashboard-content::-webkit-scrollbar {
  width: 5px;
}
.dashboard-content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.dashboard-content::-webkit-scrollbar-thumb {
  background: #c7c7ce;
  border-radius: 20px;
}
.dashboard-content .dashboard-widget-wrapper {
  column-gap: 20px;
  row-gap: 20px;
}
.dashboard-widget-wrapper .dashboard-widget {
  background: #ffffff;
  box-shadow: 0px 3px 6px #0000000f;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  padding: 18px 16px;
  flex: 0 0 calc(33% - 12.06px);
  column-gap: 15px;
  height: 94px;
}
.dashboard-widget .widget-icon {
  width: 58px;
  height: 58px;
  background: #ff5f5f1a;
  border-radius: 29px;
}
.dashboard-widget .widget-icon img {
  max-width: 28px;
  max-height: 28px;
  object-fit: contain;
}
.dashboard-widget h3 {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 24px;
  line-height: 27px;
  color: #232330;
  margin-bottom: 4px;
}
.dashboard-widget p {
  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #575b60c7;
  margin-bottom: 0px;
}
.dashboard-card {
  background: #ffffff;
  box-shadow: 0px 3px 6px #0000000f;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  padding: 21px 21px 15px;
}
.dashboard-card .card-header {
  background-color: transparent;
  border-bottom: 0px;
  padding: 0 4px;
  margin-bottom: 30px;
}
.dashboard-card .card-header h3 {
  font-family: "Proxima Nova Extrabold", sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: #232330;
  margin-bottom: 0;
  text-transform: capitalize;
}
.dashboard-card img {
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 1269px) {
  .dashboard-widget-wrapper .dashboard-widget {
    flex: 0 0 calc(33% - 12.317px);
  }
}
@media screen and (max-width: 1199px) {
  .owner-dashboard-wrapper {
    column-gap: 20px;
  }
  .owner-dashboard-wrapper .dashboard-filter {
    max-width: 320px;
  }
  .dashboard-widget-wrapper .dashboard-widget {
    flex: 0 0 calc(50% - 9px);
    height: 90px;
  }
  .dashboard-content .dashboard-widget-wrapper {
    row-gap: 18px;
    column-gap: 18px;
  }
  .dashboard-content-wrapper {
    max-width: calc(100% - 340px);
  }
  .dashboard-content {
    row-gap: 18px;
  }
  .dashboard-widget .widget-icon {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
  .dashboard-widget .widget-icon img {
    max-width: 24px;
    max-height: 24px;
  }
  .dashboard-widget h3 {
    font-size: 22px;
    line-height: 25px;
  }
  .dashboard-card .card-header h3 {
    font-size: 18px;
    list-style: 20px;
  }
}
@media screen and (max-width: 991px) {
  .owner-dashboard-wrapper {
    column-gap: 16px;
  }
  .owner-dashboard-wrapper .dashboard-filter {
    max-width: 276px;
    border-radius: 8px;
    padding: 22px 16px;
  }
  .dashboard-widget-wrapper .dashboard-widget {
    flex: 0 0 calc(50% - 7px);
    padding: 14px 12px;
    border-radius: 8px;
    height: 74px;
  }
  .dashboard-content .dashboard-widget-wrapper {
    row-gap: 14px;
    column-gap: 14px;
  }
  .dashboard-content-wrapper {
    max-width: calc(100% - 292px);
  }
  .dashboard-content {
    row-gap: 14px;
  }
  .dashboard-widget .widget-icon {
    width: 42px;
    height: 42px;
    border-radius: 25px;
  }
  .dashboard-widget .widget-icon img {
    max-width: 20px;
    max-height: 20px;
  }
  .dashboard-widget h3 {
    font-size: 18px;
    line-height: 21px;
  }
  .dashboard-widget p {
    font-size: 12px;
    line-height: 14px;
  }
  .dashboard-card {
    border-radius: 8px;
    padding: 17px 17px 15px;
  }
  .dashboard-card .card-header h3 {
    font-size: 16px;
    line-height: 19px;
  }
  .dashboard-card .card-content {
    margin-left: -17px;
    margin-right: -17px;
    width: calc(100% + 34px);
  }
}
@media screen and (max-width: 767px) {
  .selected-filters-wrapper .selected-filters .chip img {
    width: 9px;
    height: 9px;
  }
  .owner-dashboard-wrapper .dashboard-filter {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 100%;
    border-radius: 0;
    border: 0;
    box-shadow: unset;
    padding: 0px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
  .owner-dashboard-wrapper .dashboard-filter.show {
    opacity: 1;
    visibility: visible;
    z-index: 9;
  }
  .owner-dashboard-wrapper .filter-modal {
    background: transparent;
  }
  .dashboard-filter .form-group-wrapper {
    height: calc(100vh - 104px);
    padding: 26px 26px 120px;
    margin-right: 0;
  }
  .dashboard-content-wrapper {
    max-width: 100%;
  }
  .dashboard-content {
    row-gap: 20px;
    height: unset;
    padding-bottom: 120px;
    padding-right: 22px;
    margin-right: -22px;
    overflow-y: unset;
    padding-bottom: 70px;
  }
  .dashboard-card .card-content {
    margin-left: -22px;
    margin-right: -22px;
    width: calc(100% + 44px);
  }
  .dashboard-content .dashboard-widget-wrapper {
    row-gap: 15px;
  }
  .dashboard-widget-wrapper .dashboard-widget {
    column-gap: 14px;
    padding: 12px 16px;
    border-radius: 12px;
    height: 71px;
  }
  .dashboard-widget .widget-icon {
    width: 45px;
    height: 45px;
  }
  .dashboard-widget .widget-icon img {
    max-width: 21px;
    max-height: 21px;
  }
  .dashboard-widget h3 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 3px;
  }
  .dashboard-widget p {
    font-size: 13px;
    line-height: 15px;
  }
  .dashboard-card {
    border-radius: 12px;
    padding: 18px 22px;
  }
  .dashboard-card .card-header h3 {
    font-size: 20px;
    line-height: 22px;
  }
  .dashboard-content-wrapper .filter-modal .modal-header {
    padding: 0px 0px 30px;
  }
  .owner-dashboard-wrapper {
    padding: 0 7px;
  }
}
@media screen and (max-width: 575px) {
  .dashboard-widget-wrapper .dashboard-widget {
    flex-basis: 100%;
  }
}

.settings-content .facility-list-item .card-body {
  height: auto;
}
.setting-section .change-password {
  font-weight: unset;
  font-size: 45px;
  line-height: 45px;
}
.settings-content .help-support-section.manage-survey_section {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.settings-content .help-support-section.manage-survey_section .listing-header {
  padding-top: 0 !important;
}

/* Pro Plus */
.taste-recovery-form.add-sobar-living label .or-text {
  color: #575b608f;
  position: absolute;
  right: 0;
  top: 0;
}
.taste-recovery-form.add-sobar-living .form-group .check-mark {
  position: absolute;
  right: 0;
  bottom: 12px;
}
.video-upload-info {
  column-gap: 20px;
}
.video-upload-info .btn {
  height: 38px;
  padding: 8px 26px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 18px;
}
.video-upload-info span {
  font-size: 14px;
  line-height: 16px;
  color: #999da2;
}
.taste-recovery-form.add-sobar-living .form-control {
  padding-right: 32px;
}
.add-team-profile-list {
  max-width: 736px;
  width: 100%;
}
.add-team-profile-list .add-team-profile-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
  column-gap: 30px;
}
.add-team-profile-list .add-team-profile-header label {
  font-size: 16px !important;
  line-height: 18px;
  color: #232330;
  margin-bottom: 0px;
  padding-right: 0;
  max-width: 200px;
  width: 100%;
}
.add-team-profile-block {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 15px;
}
.add-team-profile-block .add-team-profile {
  display: flex;
  align-items: center;
  column-gap: 30px;
  padding: 16px;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  flex: 1 1 auto;
}
.add-team-profile-block .form-group {
  margin-bottom: 0 !important;
}
.add-team-profile-block .add-team-profile input {
  width: 200px;
  position: relative;
  padding-top: 0 !important;
}
.add-team-profile-block .add-team-profile .upload-photo {
  position: absolute;
  right: 0;
  bottom: 12px;
  cursor: pointer;
}
.add-team-profile-block .form-group.action-icon {
  cursor: pointer;
}

.upload-documents-content form {
  padding-right: 12px;
  margin-right: -12px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
.upload-documents-content form::-webkit-scrollbar {
  width: 5px;
}
.upload-documents-content form::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.upload-documents-content form::-webkit-scrollbar-thumb {
  background: #c7c7ce;
  border-radius: 20px;
}
.upload-documents-content form .row {
  margin-left: 0;
  margin-right: 0;
}
.upload-documents-content form .row .col-md-12 {
  padding-left: 0;
  padding-right: 0;
}
.pro-plus-tab-sec .form-view-top::before {
  top: 200px;
}
.slidecontainer .form-control {
  width: 66px;
  box-shadow: unset;
  margin: 0 12px;
}
.facility-content ul.staff-info {
  column-gap: 45px;
  row-gap: 25px;
}
.facility-content ul.staff-info li {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
}
.facility-content ul.staff-info li h3 {
  margin: 20px 0px;
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: #232330;
}
.staff-info-block {
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  max-width: 145px;
  width: 100%;
}
.staff-info-block img {
  height: 145px;
  object-fit: cover;
}
.staff-info-block h4 {
  display: block;
  font-family: "Proxima Nova Semibold", sans-serif;
  padding: 12px;
  font-size: 18px;
  line-height: 21px;
  color: #575b60;
  margin-bottom: 0;
}
.staff-info-block h4 span {
  font-size: 14px;
  line-height: 16px;
  color: #a6a6a6;
}

.desktop-img {
  display: block;
}
.mobile-img {
  display: none;
}
@media screen and (max-width: 767px) {
  .desktop-img {
    display: none;
  }
  .mobile-img {
    display: block;
  }
  .pro-plan-content .plan-content {
    height: calc(100vh - 270px);
  }
  .pro-plan-modal.upgrade-plan.sobar-living-upgrade-plan .modal-footer {
    padding-bottom: 100px;
  }
  /* .upgrade-plan .modal-footer {
    padding: 0px 24px 100px;
  } */
}
.saved-home-wrappper,
.settings-content {
  min-height: unset;
}
.days {
  position: absolute;
  left: 32px;
  top: 60px;
}
.dollor {
  position: absolute;
  left: 0px;
  top: 60px;
}
.taste-recovery-form.add-sobar-living .form-control.price {
  padding-left: 12px;
}

@media screen and (max-width: 767px) {
  .form-view-content .info-icon {
    width: 100%;
  }
  .dollor {
    top: 37px;
  }
  .sober-living-slider .sober-living-slider_item .location {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
  }
  .days {
    top: 37px;
    left: 100px;
  }
  .convesation-wrapper .conversation-list .conversation-info {
    padding: 0;
  }
  .help-support-section.manage-survey_section .main-title {
    margin-bottom: 0 !important;
  }
  .facility-list-wrapper .faq-content .faq-content-block h1 {
    font-size: 24px;
    line-height: 32px;
  }
  .facility-list-wrapper .faq-content .faq-content-block h2 {
    font-size: 20px;
    line-height: 38px;
  }
  .faq-content .faq-content-block h2 {
    font-size: 20px;
    line-height: 38px;
  }
  .taste-recovery-form.add-sobar-living {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding-bottom: 32px;
    margin: 0 -15px;
  }
  .taste-recovery-form.add-sobar-living.back-not-scroll {
    overflow-y: hidden;
  }
  .taste-recovery-form.add-sobar-living > .row {
    margin: 0px;
  }
}
@media screen and (max-width: 575px) {
  .upgrade-plan.cancel-subscription-modal .modal-dialog {
    max-width: 100%;
  }
  .settings-content
    .help-support-section.manage-survey_section
    .listing-header {
    margin-left: -24px;
    margin-right: -24px;
  }
  .settings-content
    .help-support-section.manage-survey_section
    .help-support-content
    .listing-header {
    margin-left: 0;
    margin-right: 0;
  }
  .settings-content .help-support-content .varify-badge-content,
  .settings-content .help-support-content .questionnaire-content,
  .settings-content .help-support-content .faq-content {
    padding-left: 12px;
    padding-right: 12px;
  }
  .settings-content .help-support-section .manage-survey_tabs {
    margin-left: -44px;
    margin-right: -44px;
  }
  .settings-content .help-support-content {
    margin-left: -36px;
    margin-right: -36px;
  }
  .settings-content .help-support-content .plan-pricing-tab {
    margin-left: -8px;
    margin-right: -8px;
  }
  .settings-content .edit-profile,
  .settings-content .edit-profile-view {
    padding-bottom: 80px;
  }
  .help-support-section .manage-survey_tabs {
    margin-top: 0;
  }
  .get-in-touch-section {
    padding-bottom: 70px;
  }
}

.custom-tooltip-block {
  background: #ffffff;
  box-shadow: 0px 3px 6px #0000001a;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  padding: 14px;
  z-index: 999;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 3px;
  min-width: 200px;
}
.verified-badge-btn .custom-tooltip-block {
  min-width: 250px;
}
.custom-tooltip-block:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-left: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(-45deg);
}
.custom-tooltip-block p {
  font-size: 13px;
  line-height: 16px;
  color: #575b60;
  font-family: "Proxima Nova Regular", sans-serif;
  margin-bottom: 0px;
  text-transform: initial;
}
.tooltip-container-btn {
  display: block;
}
.fade.terms-of-condition.modal {
  z-index: 999999;
}
.sober-living-slider
  .sober-living-slider_item
  .card-actions
  .owner-card-action-button
  .tooltip-container-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .tooltip-container-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
  }
  .custom-tooltip-block:after {
    left: auto;
    right: 15px;
  }
  .custom-tooltip-block p {
    white-space: normal;
  }
  .custom-tooltip-block {
    left: unset;
    transform: unset;
    margin-bottom: 15px;
    min-width: 200px;
    right: -15px;
  }
  .saved-home-wrappper.cards-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    margin-bottom: 100px;
  }
  .saved-home-wrappper.cards-wrapper .row {
    width: 100%;
  }
  .setting-section .settings-content .saved-home-wrappper.cards-wrapper .btn {
    max-width: unset;
    width: unset;
  }
  .cuppon-code-modal {
    background: #232330bf !important;
  }
}

/* OCR */
.ocr-modal {
  background: #232330bf;
}
.ocr-modal .modal-dialog {
  max-width: 370px;
}

/* Hippa */
.custom-alert-message {
  background: #ff5f5f1a;
  border-radius: 4px;
  padding: 8px 12px;
}
.custom-alert-message p {
  font-family: "Proxima Nova Semibold";
  font-size: 14px;
  line-height: 16px;
  color: #ff5f5f;
}
.custom-alert-message a img {
  width: 9px;
  height: 9px;
}
.conversation-content .custom-alert-message {
  border-radius: 0;
  position: relative;
  margin: -20px -20px 0;
  margin-bottom: 24px;
}
@media screen and (max-width: 767px) {
  .conversation-content .custom-alert-message {
    margin: 0;
  }
}
.updated-successfully-modal.sent-successfully .upgrade-plan-content {
  padding: 23px;
}
.subscription-msg.listed {
  background: #000 !important;
}
@media screen and (max-width: 349px) {
  .chat-wrapper {
    margin-top: 95px;
  }
}

.login-session-modal {
  z-index: 9999999;
}

.conversation-info h3,
.conversation-info h3 a {
  text-transform: capitalize;
  text-decoration: none;
}
.heplandsupportconter {
  font-size: 16px;
  text-align: justify;
}

.search-wrapper .new-owner_content {
  width: max-content;
  position: absolute;
  top: 5px;
  right: 0;
}

.search-wrapper .new-owner_content .dropdown .btn {
  background: transparent !important;
  border: 0px;
  border-radius: 0px;
  color: #495057 !important;
  border-left: 1px solid #ff5f5f33 !important;
  padding: 16px 20px;
  height: auto;
}

@media (max-width: 767.98px) {
  .search-wrapper .new-owner_content {
    top: 20px;
  }

  .search-wrapper .new-owner_content .dropdown .btn {
    padding: 8px 12px;
    font-size: 14px;
    column-gap: 12px;
  }

  .search-wrapper .new-owner_content .dropdown .btn::after {
    font-size: 16px;
  }
}

/* Search popup */
.cancel-subscription-modal.search-modal .modal-dialog {
  max-width: 600px;
}
.cancel-subscription-modal.search-modal .modal-content {
  overflow: hidden;
}
.cancel-subscription-modal.search-modal .upgrade-plan-content {
  padding: 0px;
  text-align: left;
}
.cancel-subscription-modal.search-modal .upgrade-plan-content .form-control {
  margin: 0;
  border: 0px;
  border-bottom: 1px solid #70707038 !important;
  border-radius: 0;
  box-shadow: none;
  padding-top: 14px;
  padding-bottom: 14px;
  height: 56px;
}
.cancel-subscription-modal.search-modal .upgrade-plan-content .search-icon {
  top: 14px;
}
.search-modal {
  box-shadow: 0px 0px 20px #66666633;
  margin-top: 1px;
  border-radius: 0px 0px 12px 12px;
  background: #fff;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9;
  max-height: 500px;
  overflow: hidden;
}
.search-modal .search-result-wrapper {
  padding: 0px 23px 23px;
  /* max-height: 400px; */
  overflow-y: auto;
}
.search-modal .search-result-wrapper::-webkit-scrollbar {
  width: 5px;
}
.search-modal .search-result-wrapper::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.search-modal .search-result-wrapper::-webkit-scrollbar-thumb {
  background: #c7c7ce;
  border-radius: 20px;
}
.search-modal .search-result-wrapper .search-result {
  padding: 22.5px 0px;
  border-bottom: 1px solid #70707033;
}
.search-modal .search-result-wrapper .search-result:last-child {
  border-bottom: 0px;
}
.search-modal .search-result-wrapper .search-result h3 {
  font-family: "Proxima Nova Semibold";
  font-size: 16px;
  line-height: 18px;
  color: #575b60ad;
  padding: 0 14px 17px;
  margin-bottom: 0px;
  text-transform: capitalize;
}
.search-modal .search-result-wrapper .search-result-list li {
  column-gap: 21px;
  padding: 13px 13px 11px;
  background: transparent;
  border-radius: 12px;
}
.search-modal .search-result-wrapper .search-result-list li.active {
  background: #ff5f5f14;
}
.search-modal .search-result-wrapper .search-result-list li img {
  width: 20px;
  height: 24px;
  object-fit: contain;
}
.search-modal .search-result-wrapper .search-result-list li span {
  display: block;
  font-family: "Proxima Nova Semibold";
  font-size: 16px;
  line-height: 18px;
  color: #232330d1;
}
.search-modal .search-result-wrapper .search-result-list li.active span {
  color: #ff5f5f;
}
.search-modal .no-result-found {
  padding: 30px;
  text-align: center;
}
.search-modal .no-result-found img {
  margin-bottom: 15px;
}
.search-modal .no-result-found h4 {
  font-family: "Proxima Nova Semibold";
  font-size: 16px;
  line-height: 18px;
  color: #ff5f5f;
  margin-bottom: 9px;
}
.search-modal .no-result-found p {
  font-family: "Proxima Nova Regular";
  font-size: 14px;
  line-height: 16px;
  color: #000000bf;
  margin-bottom: 0px;
}
@media screen and (max-width: 767px) {
  .cancel-subscription-modal.search-modal {
    z-index: 999999;
  }
  .cancel-subscription-modal.search-modal .modal-dialog {
    max-width: 100%;
    padding: 0;
  }
  .cancel-subscription-modal.search-modal .modal-content {
    height: 100vh;
    border-radius: 0;
    border: 0px;
  }
  .cancel-subscription-modal.search-modal .modal-content .back {
    padding: 21px 26px;
  }
  .cancel-subscription-modal.search-modal .modal-content .back img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  .cancel-subscription-modal.search-modal .upgrade-plan-content .form-control {
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 0;
    height: 48px;
  }
  .cancel-subscription-modal.search-modal .upgrade-plan-content .search-icon {
    display: none;
  }
  .search-modal {
    box-shadow: 0px 0px 10px #66666633;
    max-height: 450px;
  }
  .search-modal .search-result-wrapper {
    padding: 0px;
    /* height: 350px; */
  }
  .search-modal .search-result-wrapper .search-result {
    padding: 22.5px 12px;
  }
  .search-modal .no-result-found {
    padding: 26px;
  }
}

.facility-list-item .img-slider .facility-img {
  object-fit: cover;
}

.photo-gallery-modal .gallery-slider img {
  object-fit: contain;
}
.recommend-facilities-slider > .slick-prev,
.recommend-facilities-slider > .slick-next {
  width: 14px;
  height: 14px;
  top: 110px;
  transform: unset;
}
.recommend-facilities-slider > .slick-prev::before,
.recommend-facilities-slider > .slick-next::before {
  width: 14px;
  height: 14px;
  background-size: contain;
}
.recommend-facilities-slider > .slick-prev {
  left: -8px;
}
.recommend-facilities-slider > .slick-next {
  right: -8px;
}
.recommend-facilities-slider.margin-bottom .facility-list-item .img-slider {
  margin-bottom: 30px;
}
.recommend-facilities-slider.margin-bottom
  .facility-list-item
  .img-slider
  .facility-img {
  border-radius: 12px;
  overflow: hidden;
}
.recommend-facilities-slider .facility-list-item .img-slider .facility-img {
  height: 220px;
}
.recommend-facilities-slider > .slick-dots {
  bottom: unset;
  top: 240px;
  position: absolute !important;
  left: 0;
  right: 0;
  margin: 0 auto !important;
}
.facility-list-item .img-slider .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 4px;
  padding: 0px;
}
.facility-list.promote-ad-content {
  margin-right: -30px;
  margin-bottom: -32px;
  padding-right: 30px;
  padding-bottom: 32px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.facility-list.promote-ad-content::-webkit-scrollbar {
  width: 5px;
}
.facility-list.promote-ad-content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.facility-list.promote-ad-content::-webkit-scrollbar-thumb {
  background: #c7c7ce;
  border-radius: 20px;
}

@media (max-width: 767.98px) {
  .facility-list.promote-ad-content {
    margin-right: -20px;
    margin-bottom: -22px;
    padding-right: 20px;
    padding-bottom: 22px;
    max-height: calc(100vh - 220px);
  }
}

.card-heading_wraper {
  column-gap: 20px;
}
.facility-list-item .card-heading_wraper .location img {
  margin-top: 0px !important;
}

.location-filter {
  column-gap: 10px;
}
.location-filter .btn-location {
  display: none;
}
@media (max-width: 767.98px) {
  .location-filter .btn-location {
    display: block;
    width: 32px;
    height: 32px;
    object-fit: contain;
    padding: 0;
    width: auto;
    min-width: auto;
  }
  .location-filter .btn-location img {
    width: 32px;
    height: 32px;
  }
  .main-wrapper {
    min-height: unset;
    height: calc(100vh - 4px);
  }
}

.modal-image {
  border: 1px solid #dcdcdc;
  margin-top: 24px;
  border-radius: 12px;
  width: 250px;
}

@media (max-width: 767.98px) {
  .modal-image {
    width: 200px;
  }
  .lc_text-widget--bubble {
    bottom: 48px !important;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-actions
    .owner-card-action-button.edit-btn
    img,
  .sober-living-slider
    .sober-living-slider_item
    .card-actions
    .owner-card-action-button.calendar-btn
    img {
    width: 28px !important;
    height: 28px !important;
  }
  .sober-living-slider
    .sober-living-slider_item
    .card-actions
    .owner-card-action-button.calendar-btn
    svg {
    display: none;
  }
  .alert-wrp.setting-list {
    padding-bottom: 100px;
  }
}

.change-subscription {
  z-index: 9999999 !important;
}

.faq-content-block.accordion-block {
  position: relative;
  border-bottom: 1px solid rgb(229, 229, 229);
}
.faq-content-block.accordion-block .accordion-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 32px;
  padding: 1em 0;
  cursor: pointer;
}
.faq-content-block.accordion-block .accordion-header.expanded {
  border-bottom: 1px solid rgb(229, 229, 229);
}
.faq-content-block.accordion-block .accordion-header h3 {
  font-size: 18px;
  line-height: 24px;
  color: #575b60;
  margin: 0px;
}
.faq-content-block.accordion-block .accordion-header .icon {
  display: inline-block;
  min-width: 22px;
  width: 22px;
  height: 22px;
  border: 1px solid #575b60;
  border-radius: 22px;
  position: relative;
}
.faq-content-block.accordion-block .accordion-header .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: #575b60;
}
.faq-content-block.accordion-block .accordion-header .icon:after {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: #575b60;
}
.faq-content-block.accordion-block .accordion-header.expanded .icon:before {
  width: 0;
}
.faq-content-block.accordion-block .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}
.faq-content-block.accordion-block .accordion-content .inner-content {
  margin: 2em 0;
}
.faq-content-block.accordion-block
  .accordion-header.expanded
  + .accordion-content {
  opacity: 1;
  max-height: max-content;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.faq-content-block.accordion-block .accordion-content p {
  margin-bottom: 0px;
}
@media screen and (max-width: 767px) {
  .faq-content-block.accordion-block .accordion-header h3 {
    font-size: 16px;
    line-height: 22px;
    font-family: "Proxima Nova Semibold", sans-serif;
  }
  .facility-list-item .card-heading_wraper .card-heading {
    margin-bottom: 0px;
    text-overflow: unset;
    overflow: unset;
    white-space: unset;
    width: calc(100% - 60px);
  }
}
.property-btns .btn {
  text-transform: none;
}

#chat-widget-container {
  position: absolute;
}

.login-modal-header {
  position: absolute;
  top: 28px;
  right: 28px;
  display: flex;
}

.btn-primary:disabled {
  color: #fff;
  /* background-color: #007bff; */
  border-color: #007bff;
  background-color: #ff8686 !important;
}
