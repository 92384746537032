@font-face {
    font-family: 'Proxima Nova Cn Rg';
    src: url('ProximaNovaCond-Bold.woff2') format('woff2'),
    url('ProximaNovaCond-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Alt Cn Lt';
    src: url('ProximaNovaACond-Light.woff2') format('woff2'),
    url('ProximaNovaACond-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Alt ExCn Rg';
    src: url('ProximaNovaAExCn-Bold.woff2') format('woff2'),
    url('ProximaNovaAExCn-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Proxima Nova Cn Lt';
    src: url('ProximaNovaCond-Light.woff2') format('woff2'),
    url('ProximaNovaCond-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('ProximaNova-Bold.woff2') format('woff2'),
    url('ProximaNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Proxima Nova ScOsf Cn Rg';
    src: url('ProximaNovaSCond-Bold.woff2') format('woff2'),
    url('ProximaNovaSCond-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova ScOsf Cn Lt';
    src: url('ProximaNovaSCond-Light.woff2') format('woff2'),
    url('ProximaNovaSCond-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova ScOsf Rg';
    src: url('ProximaNovaS-Bold.woff2') format('woff2'),
    url('ProximaNovaS-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova ExCn Rg';
    src: url('ProximaNovaExCn-Bold.woff2') format('woff2'),
    url('ProximaNovaExCn-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('ProximaNova-Regular.woff2') format('woff2'),
    url('ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova ExCn Lt';
    src: url('ProximaNovaExCn-Light.woff2') format('woff2'),
    url('ProximaNovaExCn-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova ScOsf ExCn Lt';
    src: url('ProximaNovaSExCn-Light.woff2') format('woff2'),
    url('ProximaNovaSExCn-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova ScOsf ExCn Rg';
    src: url('ProximaNovaSExCn-Bold.woff2') format('woff2'),
    url('ProximaNovaSExCn-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova ScOsf Lt';
    src: url('ProximaNovaT-Thin.ttf') format('woff2'),
    url('ProximaNovaS-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* Prachi 29-02-2024 */
@font-face {
    font-family: 'Proxima Nova Thin';
    src: url('ProximaNovaT-Thin.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Proxima Nova Light';
    src: url('ProximaNova-Light.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Proxima Nova Regular';
    src: url('ProximaNova-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Proxima Nova Semibold';
    src: url('ProximaNova-Semibold.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Proxima Nova Bold';
    src: url('ProximaNova-Bold.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Proxima Nova Extrabold';
    src: url('ProximaNova-Extrabld.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Proxima Nova Black';
    src: url('ProximaNova-Black.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}