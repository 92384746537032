.dropdown.btn-group>button {
  border-radius: 20px;
  max-width: 300px;
  margin: 0 auto;
}

.react-rater-star {
  font-size: 25px;
}

.react-rater-star.is-active,
.react-rater-star.will-be-active {
  color: #ff5f5f;
}

.initial-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #ff5f5f;
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  margin: 20px 0;
}

.time-slot {
  border: 1px solid black;
  cursor: pointer;
}

.review-wrapper.reply-user {
  padding-left: 60px;
}

.-highlight-avialable-date .nice-dates-day_date:after {
  content: '';
  position: absolute;
  background: green;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  right: 15px;
  top: 15px;
}

.rc-slider-handle {
  border: solid 2px #ff5f5f;
  background-color: #ff5f5f;
}

.rc-slider-track {
  background-color: #ff5f5f;
}

.common-selection .btn-primary:not(:disabled):not(.disabled).active,
.common-selection .btn-primary:not(:disabled):not(.disabled):active,
.common-selection .show>.btn-primary.dropdown-toggle {
  background-color: #ff5f5f;
  border-color: #ff5f5f;
}

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #ff5f5f;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #ff5f5f;
}

@media (min-width: 992px) {
  .reduce-space {
    margin-top: -200px;
  }
}

@media (max-width: 767.98px) {

  /* calendar */
  .calendar-modal .modal-dialog {
    height: 100%;
    margin: 0;
  }

  .calendar-modal .modal-dialog .modal-content {
    height: 100%;
  }

  .contact-home-btn {
    bottom: 1% !important;
  }

  .property-btns {
    margin: 0 !important;
  }

  .card-heading {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }
}

.details-map {
  height: 200px;
  width: 100%;
}

.list-map {
  height: 70vh;
  width: 100%;
}

.alert-message {
  top: 0;
  right: 0;
  position: fixed;
  z-index: 9999;
  min-width: 400px;
  width: max-content;
  left: 18.2%;
  margin: auto;
  transform: unset;
}

@media screen and (max-width: 767px) {
  .alert-message {
    left: 0;
    top: 10px !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.setting-pages .navbar {
  padding: 0 !important;
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: none;
    max-width: none;
  }
}

.sidebar-wrapper ul li a.active {
  color: #ff5f5f !important;
}

.terms-of-condition .savehome-wrp {
  margin: 10px;
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.promoted {
  background-color: #ff5f5f;
  padding: 5px 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

p.address-text {
  letter-spacing: 0;
  text-align: left;
  color: #999da2;
  opacity: 1;
}

p.bio {
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  color: #999da2;
  opacity: 1;
}

span.edit-profile-btn {
  cursor: pointer;
}

span.activity-title {
  letter-spacing: 0;
  text-align: left;
  color: #575b60;
  opacity: 1;
  font-size: 16px;
  font-weight: 'bold';
}

span.activity-value {
  font-size: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #575b60;
  opacity: 1;
  padding-left: 7px;
}

div.views {
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin: 0 10px 40px;
}

span.chart-header {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0px;
  color: #575b60;
  opacity: 1;
}

div.chart-footer-wrapper {
  margin-top: 1.5rem;
  text-align: center;
}

span.organic-view-box {
  display: inline-block;
  background-color: #ff5f5f23;
  border: 1px solid #ff5f5f8f;
  width: 16px;
  height: 16px;
  margin-right: 18px;
}

span.sponsored-view-box {
  display: inline-block;
  background-color: #1898d021;
  border: 1px solid #88c6e0;
  width: 16px;
  height: 16px;
  margin-right: 18px;
}

div.chart-legend {
  margin: 10px 20px;
  display: block;
}

div.chart-legend-wrapper {
  margin: 10px 30px;
}

div.no-records {
  text-align: center;
  font-weight: bold;
  padding: 200px 0;
  /* margin-top:260px */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 473px);
}

.error-message {
  color: red;
}

div.chart {
  width: 50%;
  margin: 50px auto 30px;
}

@media (max-width: 1020px) {

  /* CHART */
  div.chart {
    width: 100%;
  }
}

.add-property-from {
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: block;
  justify-content: center;
  align-items: center;
}

.value-recommended {
  font-size: 14px;
  font-weight: 900;
  color: #575b60;
}

@media (max-width: 767.98px) {
  .card-heading {
    display: flex;
  }
}

.slick-slide img.starImg {
  width: auto;
}

@media (max-width: 991.98px) {
  .chat-rightbar {
    display: block;
  }
}

@media (max-width: 767.98px) {
  .profile-listing-header {
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .responsive-back {
    display: none;
  }
}

.clear-filter-disabled:hover {
  color: #999da2;
}

.chat-rightbar {
  max-height: 60vh;
  overflow: scroll;
}

.text-tranform-none {
  text-transform: none;
}

.selected-card {
  float: right;
}

.city-dropdown {
  position: absolute;
  z-index: 100;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.savehome-wrp .modal-header .close {
  margin: 0 15px;
  padding: 0 !important;
}

.taste-recovery-form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  margin-right: 2rem;
}

.rent-range-text {
  font-size: 14px;
  color: #aaa;
}

.mandatory {
  color: red;
}

.no-border {
  border: 0;
}

.border-btm {
  border-bottom: 1px solid #ddd;
}

.house-tours-section-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 15px 0px;
}

.align-center {
  text-align: center;
}

.owner-card-action-button {
  background: transparent;
  border: none;
}

.signin-btn {
  color: black !important;
}

.availability-back-btn {
  margin-left: 15px;
  margin-top: 15px;
}

.subscriptionOpt {
  display: flex;
}

.subscriptionOpt .optLabel {
  margin-right: 9px;
  margin-top: 3px;
}

.upgradetext {
  position: absolute;
  right: 83px;
  top: 65px;
  border: 1px solid #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 3px;
  padding: 5px 5px 0px 8px;
  height: 55px;
}

p.subscriptionText {
  color: #ff5f5f;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

 /* newly add Css for country drop down list-start */
 .form-group {
  position: relative;
 }
.dropdown-options {
  position: absolute;
  top:110%;
  /* bottom:100%; */
  left: 0;
  max-height: 200px; /* Set the maximum height for the dropdown */
  overflow-y: auto;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-options option {
  padding: 8px 12px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-options option:hover {
  background-color: #f2f2f2;
}
/* ============================================ */

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.faqs{
  font-weight: 600;
}

.slick-arrow.next-arrow.disabled {
  opacity: 0.5; 
  cursor: not-allowed;
}